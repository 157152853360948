
import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue'
import JitsiMeetComposition from '@/views/jitsi/JitsiMeetComposition.vue'
import { useStore } from 'vuex'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { MenuComponent } from '@/assets/ts/components'
import { StatisticDataInterface } from '@/store/modules/ActiveUserModule'
import { ElNotification } from 'element-plus/es'
import { Section } from '@/core/helpers/template/TemplateInterface'
import { useI18n } from 'vue-i18n'
export default defineComponent({
  name: 'builder-widget-menu',
  components: {
    JitsiMeetComposition
  },
  setup () {
    const searchInput = ref('')
    const { t } = useI18n()
    const store = useStore()
    const areAllParticipantsConnected = computed(() => store.getters.areAllParticipantsConnected)
    const startRequestedBy = computed(() => store.getters.startRequestedBy)
    const endRequestedBy = computed(() => store.getters.endRequestedBy)
    const startDeclinedBy = computed(() => store.getters.startDeclinedBy)
    const endDeclinedBy = computed(() => store.getters.endDeclinedBy)
    const teamStarted = computed(() => store.getters.getTeamStarted)
    const teamEnded = computed(() => store.getters.getTeamEnded)
    const totalParticipantsCount = computed(() => store.getters.totalParticipantsCount)
    const totalConnectedNotObserversCount = computed(() => store.getters.totalConnectedNotObserversCount)
    const totalConnectedParticipantsCount = computed(() => store.getters.totalConnectedParticipantsCount)
    const startActivityAcceptedBy = computed(() => store.getters.startActivityAcceptedBy)
    const endActivityAcceptedBy = computed(() => store.getters.endActivityAcceptedBy)
    const startActivityCountdown = computed(() => store.getters.getStartActivityCountdown)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const mobileViewMode = computed(() => store.getters.getMobileViewMode)
    // const activitySession = computed(() => store.getters.getActivitySession)
    const sections = computed(() => { return store.getters.getSections })
    const emitter = inject('emitter') as any
    const unlockAnswer = ref('')
    const wrongAnswer = ref(false)
    const correctAnswer = ref(false)
    const team = computed(() => store.getters.getActivityTeam)

    function checkAnswer () {
      const payload = {} as any
      payload.templateUuid = activityTemplate.value.activityTemplateUuid
      payload.teamUuid = team.value.sessionTeamUuid
      payload.data = {} as any
      payload.data.sectionUuid = sectionToUnlock.value.sectionUuid
      payload.data.unlockAnswer = unlockAnswer.value
      store.dispatch(Actions.API_UNLOCK_SECTION, payload).then((response) => {
        sections.value.forEach((section) => {
          store.dispatch(Actions.UNLOCK_RESOURCE, 'section-' + section.sectionUuid + '-user-' + currentAccount.value.activeUserUuid)
        })

        store.dispatch(Actions.LOCK_RESOURCE, 'section-' + sectionToUnlock.value.sectionUuid + '-user-' + currentAccount.value.activeUserUuid)
        wrongAnswer.value = false
        correctAnswer.value = true
        store.commit(Mutations.SET_TEMPLATE_ACTIVE_SECTION_INDEX, sectionIndexToUnlock.value)
        // setTimeout(() => {
        correctAnswer.value = false
        unlockModalVisible.value = false
        unlockAnswer.value = ''
        // }, 1500)
      }).catch((error) => {
        console.log('error', error)
        wrongAnswer.value = true
        setTimeout(() => {
          wrongAnswer.value = false
          unlockAnswer.value = ''
        }, 1500)
      })
    }
    const isActivityEnded = () => {
      return (teamEnded.value && teamEnded.value.timestamp !== null)
    }
    const isActivityStarted = () => {
      return (teamStarted.value && teamStarted.value.timestamp !== null)
    }
    const pendingModalVisible = ref(false)
    const confirmModalVisible = ref(false)
    const confirmModalEndActivityVisible = ref(false)
    const pendingModalEndActivityVisible = ref(false)
    const confirmStartModalVisible = ref(false)
    const confirmEndModalVisible = ref(false)
    const startCountdown = ref(0) as any
    const socket = computed(() => store.getters.getSocket)
    const currentAccount = computed(() => store.getters.currentActiveUser)
    const activeSection = computed(() => { return store.getters.getActiveSection })

    const hasUnsolvedActionWidgets = ref(false)
    const unlockModalVisible = ref(false)
    const sectionToUnlock = ref({}) as any
    const sectionIndexToUnlock = ref() as any

    emitter.on('unlock-section', (details) => {
      unlockModalVisible.value = true
      sectionToUnlock.value = details.sectionToUnlock
      sectionIndexToUnlock.value = details.sectionIndexToUnlock
    })
    // in order to watch for widget changes "deep:true" needs to be passed to watch
    watch(() => activityTemplate, (currentValue, oldValue) => {
      if (currentValue.value !== 'undefined') {
        hasUnsolvedActionWidgets.value = false
        currentValue.value.templateJson.sections.every((section) => {
          return section.widgets.every((widget) => {
            if (widget.category === 'action') {
              switch (widget.type) {
                case 'drag-and-drop-group':
                case 'drag-and-drop-order':
                  return widget.specific.solution.every((solution) => {
                    if (solution.itemList.length === 0) {
                      hasUnsolvedActionWidgets.value = true
                      return false
                    }
                    return true
                  })
                case 'question-single-choice':
                  if ((typeof widget.specific.solution === 'string' && widget.specific.solution.length === 0) || widget.specific.solution === null) {
                    hasUnsolvedActionWidgets.value = true
                    return false
                  }
                  return true
                case 'question-multiple-choice':
                  if (widget.specific.solution.length === 0) {
                    hasUnsolvedActionWidgets.value = true
                    return false
                  }
                  return true
                case 'question-short-answer':
                  if ((typeof widget.specific.solution.answer === 'string' && widget.specific.solution.answer.length === 0) || widget.specific.solution.answer === null) {
                    hasUnsolvedActionWidgets.value = true
                    return false
                  }
                  return true
                default:
                  return true
              }
            }
            return true
          })
        })
      }
    },
    { deep: true }
    )

    watch(endRequestedBy, (n, o) => {
      if ((n.employee && n.employee.email) || (n.displayName)) {
        confirmEndModalVisible.value = true
      }
    })

    watch(startRequestedBy, (n, o) => {
      if ((n.employee && n.employee.email) || (n.displayName)) {
        confirmStartModalVisible.value = true
      }
    })
    const handleSectionClick = (clickedSection) => {
      const clickedSectionUuid = clickedSection === -1 ? -1 : clickedSection.sectionUuid
      // unlock the other sections
      sections.value.forEach((section) => {
        if (clickedSectionUuid !== section.sectionUuid) {
          store.dispatch(Actions.UNLOCK_RESOURCE, 'section-' + section.sectionUuid + '-user-' + currentAccount.value.activeUserUuid)
        }
      })
      store.dispatch(Actions.UNLOCK_RESOURCE, 'section-' + 'debriefing' + '-user-' + currentAccount.value.activeUserUuid) // this is also unlocked to cope with briefing not being a real section
      if (clickedSection === -1) {
        store.dispatch(Actions.LOCK_RESOURCE, 'section-' + 'debriefing' + '-user-' + currentAccount.value.activeUserUuid)
      } else {
        store.dispatch(Actions.LOCK_RESOURCE, 'section-' + clickedSection.sectionUuid + '-user-' + currentAccount.value.activeUserUuid)
      }
    }

    watch(teamStarted, (n, o) => {
      if (typeof o === 'object' && 'timestamp' in o && n.timestamp !== null && n.reason !== null && n.timestamp !== o.timestamp) {
        // setTimeout(() => {
        const templateSection = store.getters.getTemplateSectionByIndex(1) as unknown as Section
        const getSectionIsLockedByResource = computed(() => store.getters.getSectionIsLockedByResource(templateSection.sectionUuid)).value
        // if the first section is locked
        if (templateSection.settings.locked || getSectionIsLockedByResource) {
          return
        }
        store.commit(Mutations.SET_TEMPLATE_ACTIVE_SECTION_INDEX, 1) // first section after intro will be selected automatically
        // }, 200)
        handleSectionClick(templateSection)
        // setTimeout(() => { // reinitialize sections options menu
        MenuComponent.reinitialization()

        // const startDate = new Date(activitySession.value.startDate)
        // const startDate = new Date(n.timestamp * 1000)
        // const diffInSeconds = (startDate.getTime() / 1000) + activitySession.value.duration
        store.dispatch(Actions.SET_START_ACTIVITY_COUNTDOWN, (activityTemplate.value.challengeDuration))

        const newStatisticData = { type: 'sectionsOpened', meta: { sectionUuid: activeSection.value.sectionUuid } } as unknown as StatisticDataInterface

        socket.value.emit('ADD_STATISTIC_DATA', {
          eventType: 'ADD_STATISTIC_DATA',
          payload: newStatisticData
        })

        // // activity ended
        // if (response.payload.status === 3) {
        //     diffInSeconds = diffInSeconds + response.payload.duration
        //     store.dispatch(MutationsActions.SET_MUTATE_START_ACTIVITY_COUNTDOWN, diffInSeconds)
        // }
        // }, 100)
      }
    })
    watch(teamEnded, (n, o) => {
      if (typeof o === 'object' && 'timestamp' in o && n.timestamp !== null && n.reason !== null && n.timestamp !== o.timestamp) {
        store.commit(Mutations.SET_TEMPLATE_ACTIVE_SECTION_INDEX, -1) // debriefing will be selected automatically
        handleSectionClick(-1) // click on the debriefing

        MenuComponent.reinitialization()
      }
    })

    watch(startDeclinedBy, (n, o) => {
      console.log('startDeclinedBy', startDeclinedBy.value, n)
      if ((n.employee && n.employee.email) || (n.displayName)) {
        confirmStartModalVisible.value = false
        confirmModalVisible.value = false
        pendingModalVisible.value = false
        ElNotification.error({
          message: '"' + (n.employee.email ? n.employee.email : n.displayName) + '"' + t('start.activity.pending.declined'),
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
      }
    })

    watch(endDeclinedBy, (n, o) => {
      if ((n.employee && n.employee.email) || (n.displayName)) {
        confirmEndModalVisible.value = false
        confirmModalEndActivityVisible.value = false
        pendingModalEndActivityVisible.value = false
        ElNotification.error({
          message: '"' + (n.employee.email ? n.employee.email : n.displayName) + '" ' + t('end.activity.pending.declined'),
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
      }
    })

    watch(startActivityAcceptedBy, (n, o) => {
      const foundItself = n.some(account => {
        return account.activeUserUuid === currentAccount.value.activeUserUuid
      })

      if (foundItself) {
        confirmStartModalVisible.value = false
        pendingModalVisible.value = true
      }

      if (n.length === 0) {
        pendingModalVisible.value = false
      }
    })

    watch(endActivityAcceptedBy, (n, o) => {
      const foundItself = n.some(account => {
        return account.activeUserUuid === currentAccount.value.activeUserUuid
      })

      if (foundItself) {
        confirmEndModalVisible.value = false
        pendingModalEndActivityVisible.value = true
      }

      if (n.length === 0) {
        pendingModalEndActivityVisible.value = false
      }
    })
    let countDown = 0
    watch(startActivityCountdown, (n, o) => {
      if (o !== null) {
        clearTimeout(countDown)
      }
      startCountdown.value = parseInt(n)
      if (startCountdown.value <= 0) {
        startCountdown.value = 0
        return
      }
      countDownTimer(n)
    })

    const countDownTimer = (value) => {
      countDown = setTimeout(() => {
        startCountdown.value = parseInt(startCountdown.value) - 1
        countDownTimer(startCountdown.value)
      }, 1000)
    }

    const onStartActivityClicked = () => {
      confirmModalVisible.value = true
    }

    const onEndActivityClicked = () => {
      confirmModalEndActivityVisible.value = true
    }

    const onModalStartActivityClicked = () => {
      socket.value.emit('REQUEST_START_ACTIVITY', {
        eventType: 'REQUEST_START_ACTIVITY',
        payload: []
      })
      confirmModalVisible.value = false
    }

    const onModalEndActivityClicked = () => {
      socket.value.emit('REQUEST_END_ACTIVITY', {
        eventType: 'REQUEST_END_ACTIVITY',
        payload: []
      })
      confirmModalEndActivityVisible.value = false
    }

    const onModalAcceptStartActivityClicked = () => {
      socket.value.emit('ACCEPT_START_ACTIVITY', {
        eventType: 'ACCEPT_START_ACTIVITY',
        payload: []
      })
      confirmModalVisible.value = false
      pendingModalVisible.value = true
    }

    const onModalDeclineStartActivityClicked = () => {
      socket.value.emit('DECLINE_START_ACTIVITY', {
        eventType: 'DECLINE_START_ACTIVITY',
        payload: []
      })
      confirmStartModalVisible.value = false
      confirmModalVisible.value = false
      pendingModalVisible.value = false
    }

    const onModalAcceptEndActivityClicked = () => {
      socket.value.emit('ACCEPT_END_ACTIVITY', {
        eventType: 'ACCEPT_END_ACTIVITY',
        payload: []
      })
      confirmModalEndActivityVisible.value = false
      pendingModalEndActivityVisible.value = true
    }

    const onModalDeclineEndActivityClicked = () => {
      socket.value.emit('DECLINE_END_ACTIVITY', {
        eventType: 'DECLINE_END_ACTIVITY',
        payload: []
      })
      confirmEndModalVisible.value = false
      confirmModalEndActivityVisible.value = false
      pendingModalEndActivityVisible.value = false
    }
    const onExitActivityClicked = () => {
      emitter.emit('exit-activity', true)
    }

    emitter.on('confirm-start-modal', () => {
      confirmModalVisible.value = true
    })
    emitter.on('confirm-end-modal', () => {
      confirmModalEndActivityVisible.value = true
    })
    onMounted(() => {
    })

    const convertHMS = (value) => {
      const sec = parseInt(value, 10) // convert value to number if it's string
      let hours = Math.floor(sec / 3600) as any // get hours
      let minutes = Math.floor((sec - (hours * 3600)) / 60) as any// get minutes
      let seconds = sec - (hours * 3600) - (minutes * 60) as any //  get seconds
      // add 0 if value < 10; Example: 2 => 02
      if (hours < 10) { hours = '0' + hours }
      if (minutes < 10) { minutes = '0' + minutes }
      if (seconds < 10) { seconds = '0' + seconds }
      return hours + ':' + minutes + ':' + seconds // Return is HH : MM : SS
    }

    const jitsiVisible = ref(false)

    emitter.on('handle-jitsi-visible', (jv) => {
      jitsiVisible.value = jv
    })

    return {
      searchInput,
      currentAccount,
      areAllParticipantsConnected,
      startCountdown,
      pendingModalVisible,
      confirmModalVisible,
      startRequestedBy,
      endRequestedBy,
      confirmStartModalVisible,
      confirmEndModalVisible,
      startActivityAcceptedBy,
      totalParticipantsCount,
      totalConnectedNotObserversCount,
      onStartActivityClicked,
      hasUnsolvedActionWidgets,
      teamStarted,
      confirmModalEndActivityVisible,
      pendingModalEndActivityVisible,
      endDeclinedBy,
      endActivityAcceptedBy,
      totalConnectedParticipantsCount,
      activityTemplate,
      onEndActivityClicked,
      onModalStartActivityClicked,
      onModalEndActivityClicked,
      onModalAcceptStartActivityClicked,
      onModalDeclineStartActivityClicked,
      convertHMS,
      onModalDeclineEndActivityClicked,
      onModalAcceptEndActivityClicked,
      isActivityEnded,
      isActivityStarted,
      onExitActivityClicked,
      checkAnswer,
      wrongAnswer,
      mobileViewMode,
      sectionToUnlock,
      unlockModalVisible,
      unlockAnswer,
      jitsiVisible,
      correctAnswer
      // t
    }
  }
})
