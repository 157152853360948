

import { computed, ref } from 'vue'
import store from '@/store'
import { getParticipantMetadata } from '@/core/helpers/activity-team-module'
import { userColorMap } from '@/core/helpers/template/template'

export default {
  name: 'builder-left-menu',
  props: {
    template: { type: Object, required: true }
  },
  setup () {
    const showNoteTakingSpace = ref(false)
    const activitySession = computed(() => store.getters.getActivitySession)
    const currentAccount = computed(() => store.getters.currentActiveUser)

    const etherpadUrl = computed(() => {
      const colorLabel = getParticipantMetadata(currentAccount.value.activeUserUuid).userColorLabel
      const color = userColorMap[colorLabel]

      // url parameters
      const activitySessionUuid = activitySession.value.activitySessionUuid
      const showChat = false
      // const showControls = true
      const noColors = true
      const userColor = encodeURIComponent(color)
      const displayName = currentAccount.value.displayName
      return `https://pad.teamlearn.ai/p/${activitySessionUuid}?showChat=${showChat}&userName=${displayName}&userColor=${userColor}&noColors=${noColors}&showLineNumbers=true`
    })
    return {
      showNoteTakingSpace,
      etherpadUrl
    }
  }
}
