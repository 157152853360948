
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { getApiKey } from '@/core/helpers/tinymce'
import { DragAndDropGroup } from '@/core/helpers/template/WidgetInterface'
import { buildWidgetLocator } from '@/core/helpers/template/template.ts'
import { ElNotification } from 'element-plus/es'
import { uuid } from 'vue-uuid'
import Draggable from 'vuedraggable'
import Editor from '@tinymce/tinymce-vue'
import {
  updateDragAndDropGroupSolutionHistory,
  getDragAndDropGroupSolutionHistory,
  generatedRandomColor,
  getNameCredentials
} from '@/core/helpers/template/widgetSolutionHistory.ts'
import { Actions } from '@/store/enums/StoreEnums'
import { getParticipantMetadata } from '@/core/helpers/activity-team-module'

export default defineComponent({
  name: 'drag-and-drop-group',
  props: {
    widget: { type: Object as () => DragAndDropGroup, required: true },
    readonly: { type: Boolean, default: false }
  },
  components: {
    editor: Editor,
    draggable: Draggable
  },
  setup (props) {
    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    const solvingMode = computed(() => store.getters.getSolvingMode)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const currentWidget = ref(props.widget as DragAndDropGroup)
    const dragStarted = ref(false)
    const currentAccount = computed(() => store.getters.currentActiveUser)
    const connectedUsers = computed(() => store.getters.getConnectedParticipants)
    const getSolutionHistoryByColumn = (columnUuid) => {
      return getDragAndDropGroupSolutionHistory(currentWidget, columnUuid)
    }

    const removableLocks = [] as any
    /**
    * Checks if the unique resource id is locked or not
    * @param resourceUuid The unique resource string
    */
    const isResourceLocked = (resourceUuid): boolean => {
      return computed(() => store.getters.isResourceLocked(resourceUuid)).value
    }

    /**
    * Retrieves the locked resource only if its indeed locked, otherwise it returns undefined
    * @param resourceUuid The unique resource string
    */
    const getLockedResource = (resourceUuid): any => {
      return computed(() => store.getters.getLockedResource(resourceUuid)).value
    }

    /**
    * Get details about a connected active user
    * @param activeUserUuid The uuid of the active user
    */
    const getConnectedUser = (activeUserUuid): any => {
      return connectedUsers.value.find((user) => user.activeUserUuid === activeUserUuid)
    }

    const handleMouseDown = (event, resourceUuid) => {
      if (event.button !== 0) {
        // right click not allowed
        return
      }
      store.dispatch(Actions.LOCK_RESOURCE, resourceUuid).then(() => {
        removableLocks.push(resourceUuid)
        // fail-safe unlock mechanism after couple of seconds to prevent locking of a resource indefinitely
        setTimeout(() => {
          if (removableLocks.includes(resourceUuid)) {
            store.dispatch(Actions.UNLOCK_RESOURCE, resourceUuid)
          }
        }, 15000) // 15 seconds timeout
      })
    }

    const handleMouseUp = (resourceUuid) => {
      store.dispatch(Actions.UNLOCK_RESOURCE, resourceUuid)
    }
    // let persistTimeout = 0 as any
    const persistWidget = () => {
      if (props.readonly) {
        return
      }
      // clearTimeout(persistTimeout)
      // persistTimeout = setTimeout(() => {
      console.log('Persisting widget...')
      const locatorInstance = buildWidgetLocator(activityTemplate, currentWidget.value.widgetUuid)
      const currentWidgetInstance = currentWidget.value
      if (!locatorInstance) return
      const socketPayload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        teamUuid: currentAccount.value.teamUuid,
        locator: locatorInstance,
        widget: currentWidgetInstance
      }
      socket.value.emit('EDIT_WIDGET', {
        eventType: 'EDIT_WIDGET',
        payload: socketPayload
      })
      // clear locked items here
      removableLocks.forEach((itemUuid, i) => {
        store.dispatch(Actions.UNLOCK_RESOURCE, itemUuid)
      })
      // }, 1000)
    }

    const onColumnAdded = () => {
      console.log('Adding column...')
      if (currentWidget.value.specific.columnList.length >= 5) {
        ElNotification.warning({
          message: 'A maximum of <strong>5</strong> columns are supported for this widget.',
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
        return false
      }
      const columnUuid = uuid.v4()
      currentWidget.value.specific.columnList.push({
        columnUuid: columnUuid,
        title: ''
      })
      currentWidget.value.specific.solution.push({
        columnUuid: columnUuid,
        itemList: []
      })
      persistWidget()
    }

    const onColumnRemoved = (columnKey) => {
      console.log('Removing column...')
      if (currentWidget.value.specific.columnList.length <= 1) {
        ElNotification.warning({
          message: 'You may not remove the <strong>last</strong> column.',
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
        return false
      }
      const column = currentWidget.value.specific.columnList[columnKey]
      const solutionKey = currentWidget.value.specific.solution.findIndex(
        solution => solution.columnUuid === column.columnUuid
      )
      currentWidget.value.specific.solution.splice(solutionKey, 1)
      currentWidget.value.specific.columnList.splice(columnKey, 1)
      persistWidget()
    }

    const onListItemAdded = () => {
      console.log('Adding list item...')
      currentWidget.value.specific.itemList.unshift({
        itemUuid: uuid.v4(),
        content: ''
      })
      persistWidget()
    }

    const onListItemRemoved = (itemUuid) => {
      console.log('Removing item with UUID:', itemUuid)
      // remove from itemList
      const itemKey = currentWidget.value.specific.itemList.findIndex(i => i.itemUuid === itemUuid)
      currentWidget.value.specific.itemList.splice(itemKey, 1)
      // remove from solution in case it exists
      for (const solution of currentWidget.value.specific.solution) {
        for (const solItemUuid of solution.itemList) {
          if (solItemUuid === itemUuid) {
            solution.itemList = solution.itemList.filter(solItemUuid => solItemUuid !== itemUuid)
          }
        }
      }
      persistWidget()
    }

    const onListItemDragged = () => {
      console.log('Reordering available items...')
      dragStarted.value = false
      persistWidget()
    }

    const onItemDraggedToColumn = () => {
      console.log('Dragging item to column...')
      dragStarted.value = false
      updateDragAndDropGroupSolutionHistory(currentWidget, currentAccount)
      persistWidget()
    }

    const onItemClonedToColumn = (itemList) => {
      console.log('Cloning item to column...')
      return {
        solutionItemUuid: uuid.v4(),
        itemUuid: itemList.itemUuid,
        content: itemList.content
      }
    }

    const onSolutionItemRemoved = (columnUuid, solutionItemUuid) => {
      console.log('Removing solution item with solution item uuid ', solutionItemUuid)
      const solution = currentWidget.value.specific.solution
      const solutionColumnKey = solution.findIndex(s => s.columnUuid === columnUuid)
      const solutionItemKey = solution[solutionColumnKey].itemList.findIndex(si => si.solutionItemUuid === solutionItemUuid)
      updateDragAndDropGroupSolutionHistory(currentWidget, currentAccount)
      currentWidget.value.specific.solution[solutionColumnKey].itemList.splice(solutionItemKey, 1)
      persistWidget()
    }

    const getSolutionByColumnUuid = (columnUuid) => {
      const solutionIndex = currentWidget.value.specific.solution.findIndex(s => s.columnUuid === columnUuid)
      return currentWidget.value.specific.solution[solutionIndex].itemList
    }

    const editorSettings = {
      setup: (editor) => {
        editor.on('keydown', function (e) {
          if (e.keyCode === 13) e.preventDefault() // prevent enter
        })
      },
      inline: true,
      menubar: false,
      toolbar: false,
      plugins: ['paste'],
      paste_as_text: true,
      paste_block_drop: true, // prevent pasting when dropping -> part of bug that allowed dragging to other widgets
      invalid_elements: 'br',
      paste_preprocess: function (plugin, args) { // replace multiple spaces and trim on paste
        args.content = args.content.replace(/\s\s+/g, ' ').trim()
      }
    }

    const colorClassMap: { [key: string]: string } = {
      white: 'ghost-widget-white',
      orange: 'ghost-widget-orange',
      blue: 'ghost-widget-blue',
      red: 'ghost-widget-red',
      green: 'ghost-widget-green',
      purple: 'ghost-widget-purple',
      gray: 'ghost-widget-gray'
    }

    const getSolutionAvailableItems = () => {
      console.log('Calculating solution available items...')
      let solutionExistingItemsUuids = [] as any
      currentWidget.value.specific.solution.forEach(column => {
        solutionExistingItemsUuids = solutionExistingItemsUuids.concat(column.itemList)
      })

      return currentWidget.value.specific.itemList.filter(
        item => typeof solutionExistingItemsUuids.find(si => si.itemUuid === item.itemUuid) !== 'object'
      )
    }

    return {
      currentWidget,
      solvingMode,
      dragStarted,
      editorSettings,
      currentAccount,
      colorClassMap,
      getApiKey,
      persistWidget,
      onColumnAdded,
      onColumnRemoved,
      onListItemAdded,
      onListItemRemoved,
      onListItemDragged,
      onItemDraggedToColumn,
      onItemClonedToColumn,
      onSolutionItemRemoved,
      getSolutionByColumnUuid,
      generatedRandomColor,
      getNameCredentials,
      getDragAndDropGroupSolutionHistory,
      getSolutionHistoryByColumn,
      handleMouseDown,
      handleMouseUp,
      isResourceLocked,
      getLockedResource,
      getConnectedUser,
      getParticipantMetadata,
      getSolutionAvailableItems
    }
  }
})
