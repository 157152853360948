
import { computed, defineComponent, inject, ref, watch } from 'vue'
import store from '@/store'

export default defineComponent({
  name: 'builder-mobile-top-menu',
  components: {},
  setup () {
    const emitter = inject('emitter') as any

    const currentAccount = computed(() => store.getters.currentActiveUser)
    const teamStarted = computed(() => store.getters.getTeamStarted)
    const teamEnded = computed(() => store.getters.getTeamEnded)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)

    const startActivityCountdown = computed(() => store.getters.getStartActivityCountdown)
    const areAllParticipantsConnected = computed(() => store.getters.areAllParticipantsConnected)
    const hasUnsolvedActionWidgets = ref(false)

    const startCountdown = ref(0) as any
    let countDown = 0
    watch(startActivityCountdown, (n, o) => {
      if (o !== null) {
        clearTimeout(countDown)
      }
      startCountdown.value = parseInt(n)
      if (startCountdown.value <= 0) {
        startCountdown.value = 0
        return
      }
      countDownTimer(n)
    })
    // in order to watch for widget changes "deep:true" needs to be passed to watch
    watch(() => activityTemplate, (currentValue, oldValue) => {
      if (currentValue.value !== 'undefined') {
        hasUnsolvedActionWidgets.value = false
        currentValue.value.templateJson.sections.every((section) => {
          return section.widgets.every((widget) => {
            if (widget.category === 'action') {
              switch (widget.type) {
                case 'drag-and-drop-group':
                case 'drag-and-drop-order':
                  return widget.specific.solution.every((solution) => {
                    if (solution.itemList.length === 0) {
                      hasUnsolvedActionWidgets.value = true
                      return false
                    }
                    return true
                  })
                case 'question-single-choice':
                  if ((typeof widget.specific.solution === 'string' && widget.specific.solution.length === 0) || widget.specific.solution === null) {
                    hasUnsolvedActionWidgets.value = true
                    return false
                  }
                  return true
                case 'question-multiple-choice':
                  if (widget.specific.solution.length === 0) {
                    hasUnsolvedActionWidgets.value = true
                    return false
                  }
                  return true
                case 'question-short-answer':
                  if ((typeof widget.specific.solution.answer === 'string' && widget.specific.solution.answer.length === 0) || widget.specific.solution.answer === null) {
                    hasUnsolvedActionWidgets.value = true
                    return false
                  }
                  return true
                default:
                  return true
              }
            }
            return true
          })
        })
      }
    },
    { deep: true }
    )
    const countDownTimer = (value) => {
      countDown = setTimeout(() => {
        startCountdown.value = parseInt(startCountdown.value) - 1
        countDownTimer(startCountdown.value)
      }, 1000)
    }
    const isActivityStarted = () => {
      return (teamStarted.value && teamStarted.value.timestamp !== null)
    }

    const isActivityEnded = () => {
      return (teamEnded.value && teamEnded.value.timestamp !== null)
    }

    const onStartActivityClicked = () => {
      emitter.emit('confirm-start-modal', true)
    }

    const onEndActivityClicked = () => {
      emitter.emit('confirm-end-modal', true)
    }

    const onExitActivityClicked = () => {
      emitter.emit('exit-activity', true)
    }

    const convertHMS = (value) => {
      const sec = parseInt(value, 10) // convert value to number if it's string
      let hours = Math.floor(sec / 3600) as any // get hours
      let minutes = Math.floor((sec - (hours * 3600)) / 60) as any// get minutes
      let seconds = sec - (hours * 3600) - (minutes * 60) as any //  get seconds
      // add 0 if value < 10; Example: 2 => 02
      if (hours < 10) { hours = '0' + hours }
      if (minutes < 10) { minutes = '0' + minutes }
      if (seconds < 10) { seconds = '0' + seconds }
      return hours + ':' + minutes + ':' + seconds // Return is HH : MM : SS
    }
    return {
      currentAccount,
      startCountdown,
      areAllParticipantsConnected,
      hasUnsolvedActionWidgets,
      isActivityStarted,
      isActivityEnded,
      convertHMS,
      onStartActivityClicked,
      onEndActivityClicked,
      onExitActivityClicked
    }
  }
})
