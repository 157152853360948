
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { getApiKey, resizeFont } from '@/core/helpers/tinymce'
import { Flashcard } from '@/core/helpers/template/WidgetInterface'
import { buildWidgetLocator } from '@/core/helpers/template/template'
import Editor from '@tinymce/tinymce-vue'

export default defineComponent({
  name: 'flashcard',
  props: {
    widget: { type: Object as () => Flashcard, required: true },
    readonly: { type: Boolean, default: false }
  },
  components: {
    editor: Editor
  },
  setup (props) {
    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const currentWidget = ref(props.widget as Flashcard) as any

    const content = ref()

    const contentChanged = () => {
      console.log('Content changed...')
      resizeFont(content)
      persistWidget()
    }

    let persistTimeout = 0 as any
    const persistWidget = () => {
      clearTimeout(persistTimeout)
      persistTimeout = setTimeout(() => {
        console.log('Persisting widget...')
        const locatorInstance = buildWidgetLocator(activityTemplate, currentWidget.value.widgetUuid)
        const currentWidgetInstance = currentWidget.value
        if (!locatorInstance) return
        const socketPayload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          locator: locatorInstance,
          widget: currentWidgetInstance
        }
        socket.value.emit('EDIT_WIDGET', {
          eventType: 'EDIT_WIDGET',
          payload: socketPayload
        })
      }, 1000)
    }

    const editorAdvancedSettings = {
      setup: (editor) => {
        editor.on('ResizeWindow', function () {
          console.log('ResizeWindow')
          resizeFont(content)
        })
        editor.on('init', function () {
          resizeFont(content)
        })

        editor.on('FormatApply FormatRemove', function () { // change also for deleting all content
          contentChanged()
        })
      },
      plugins: ['lists', 'link', 'paste', 'hr'],
      inline: true,
      menubar: false,
      toolbar: false,
      paste_as_text: true,
      paste_block_drop: true // prevent pasting when dropping -> part of bug that allowed dragging to other widgets
    }

    const editorSettings = {
      setup: (editor) => {
        editor.on('keydown', function (e) {
          if (e.keyCode === 13) e.preventDefault() // prevent enter
        })
      },
      inline: true,
      menubar: false,
      toolbar: false,
      plugins: ['paste'],
      paste_as_text: true,
      paste_block_drop: true, // prevent pasting when dropping -> part of bug that allowed dragging to other widgets
      invalid_elements: 'br',
      paste_preprocess: function (plugin, args) { // replace multiple spaces and trim on paste
        args.content = args.content.replace(/\s\s+/g, ' ').trim()
      }
    }

    return {
      persistWidget,
      contentChanged,
      currentWidget,
      editorSettings,
      editorAdvancedSettings,
      content,
      getApiKey,
      resizeFont
    }
  }
})
