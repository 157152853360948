
import { computed, defineComponent, inject, onMounted, onUnmounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { ImageView360 } from '@/core/helpers/template/WidgetInterface.ts'
import { buildTemplateFileUri } from '@/core/helpers/activity-template'
import { View360, EquirectProjection, ProjectionChangeEvent, LoadStartEvent, EVENTS } from '@egjs/vue3-view360'
import '@egjs/vue3-view360/css/view360.min.css'
import { Mutations } from '@/store/enums/StoreEnums'
// import { vec3, mat4 } from 'gl-matrix'
import ContentEditable from '@/components/Builder/ContentEditable/ContentEditable.vue'

export default defineComponent({
  name: 'image-view-360',
  props: {
    widget: { type: Object as () => ImageView360, required: true },
    readonly: { type: Boolean, default: false },
    isLandscape: { type: Boolean, default: false }
  },
  components: {
    View360,
    ContentEditable
  },
  setup (props) {
    const store = useStore()
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const currentAccount = computed(() => store.getters.currentActiveUser) as any
    const scenesFor360Image = computed(() => store.getters.getScenesFor360ImageWidget(props.widget.widgetUuid)).value
    const hotspotsFor360ImageScene = (widgetUuid, sceneUuid) => computed(() => store.getters.getHotspotsFor360ImageScene(widgetUuid, sceneUuid)).value
    const selectedWidgetScene = computed(() => store.getters.getSelectedSceneForWidget(props.widget.widgetUuid))
    const viewer = ref(null) as any
    const emitter = inject('emitter') as any
    // const socket = computed(() => store.getters.getSocket)

    const isDragging = ref(false)
    const projectionLoading = ref(false)
    const onProjectionChange = (evt: ProjectionChangeEvent) => {
      projectionLoading.value = false
    }
    const onLoadStart = (evt: LoadStartEvent) => {
      projectionLoading.value = true
    }

    watch(viewer, () => {
      if (viewer.value) {
        viewer.value._.data.view360.on(EVENTS.PROJECTION_CHANGE, onProjectionChange)
        viewer.value._.data.view360.on(EVENTS.LOAD_START, onLoadStart)
      }
    })
    const uploadFileProgress = (widgetUuid) => computed(() => store.getters.getFileUploadProgressForWidget(widgetUuid))
    const fileInput = ref()
    const elements = ref([])

    // const hotspotCounter = ref(0)
    const projection = ref(null) as any

    // needed for adding hotspots

    const handleHotspotClick = (sceneUuid, hotspot) => {
      if (hotspot.linkTo && hotspot.linkTo.image) {
        const originalSrc = hotspot.linkTo.image!.selfLinks?.original
        const stringSrc = buildTemplateFileUri(
                      hotspot.linkTo.image!.attachmentUuid,
                      currentAccount.value.employee?.company ? currentAccount.value.employee?.company : currentAccount.value.company,
                      originalSrc || null
        )
        if (projection.value) {
          projection.value.src = stringSrc

          if (viewer.value) {
            // console.log(viewer.value._.data.view360)
            setTimeout(() => {
              viewer.value._.data.view360.load(projection.value)
              viewer.value._.data.view360._hotspot.refresh()
              viewer.value._.data.view360._hotspot.render(viewer.value._.data.view360._camera)
            }, 0)
          }
        } else {
          projection.value = new EquirectProjection({
            src: stringSrc || '', // Provide an empty string as the default value
            video: false
          })

          if (viewer.value) {
            viewer.value._.data.view360.load(projection.value)
          }
        }
        const payload = {} as any
        payload.widgetUuid = props.widget.widgetUuid
        payload.scene = hotspot.linkTo
        store.commit(Mutations.SET_SELECTED_SCENE_FOR_WIDGET, payload)
      }
      if (hotspot.modalWidget && hotspot.modalWidget.widgetUuid) {
        emitter.emit('show-link-to-widget-modal', { widgetUuid: props.widget.widgetUuid, hotspot: hotspot })
      }
      const payload = {
        sceneUuid: sceneUuid,
        hotspot: hotspot
      }
      store.commit(Mutations.SET_SELECTED_HOTSPOT_IN_SCENE, payload)
    }
    onMounted(() => {
      if (selectedWidgetScene.value) {
        const originalSrc = selectedWidgetScene.value.image!.selfLinks?.original
        const stringSrc = buildTemplateFileUri(
                selectedWidgetScene.value.image!.attachmentUuid,
                currentAccount.value.employee?.company ? currentAccount.value.employee?.company : currentAccount.value.company,
                originalSrc || null
        )
        projection.value = new EquirectProjection({
          src: stringSrc || '', // Provide an empty string as the default value
          video: false
        })

        if (viewer.value) {
          setTimeout(() => {
            viewer.value._.data.view360.load(projection.value)
          }, 0)
        }
      } else {
        if (
          props.widget.specific &&
                props.widget.specific.scenes[0] &&
                props.widget.specific.scenes[0].image &&
                props.widget.specific.scenes[0].image.selfLinks &&
                props.widget.specific.scenes[0].image.selfLinks.original &&
                typeof props.widget.specific.scenes[0].image.selfLinks.original !== 'undefined'
        ) {
          const originalSrc = props.widget.specific.scenes[0].image.selfLinks?.original

          const stringSrc = buildTemplateFileUri(
            props.widget.specific.scenes[0].image.attachmentUuid,
            currentAccount.value.employee?.company ? currentAccount.value.employee?.company : currentAccount.value.company,
            originalSrc || null
          )
          projection.value = new EquirectProjection({
            src: stringSrc || '', // Provide an empty string as the default value
            video: false
          })

          viewer.value._.data.view360.load(projection.value)
          const payload = {} as any
          payload.widgetUuid = props.widget.widgetUuid
          payload.scene = props.widget.specific.scenes[0]
          store.commit(Mutations.SET_SELECTED_SCENE_FOR_WIDGET, payload)
        }
      }

      // document.addEventListener('dblclick', handleDoubleClick)
    })

    onUnmounted(() => {
      elements.value = []
      // document.removeEventListener('dblclick', handleDoubleClick)
      if (viewer.value) {
        viewer.value.destroy()
      }
    })

    return {
      activityTemplate,
      currentAccount,
      elements,
      fileInput,
      viewer,
      projection,
      buildTemplateFileUri,
      uploadFileProgress,
      scenesFor360Image,
      hotspotsFor360ImageScene,
      selectedWidgetScene,
      isDragging,
      projectionLoading,
      handleHotspotClick
    }
  }
})
