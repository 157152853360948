import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ea0c44c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["tabindex"]
const _hoisted_2 = ["tabindex"]
const _hoisted_3 = {
  key: 2,
  class: "solution-history-wrapper",
  style: {"place-items":"center"}
}
const _hoisted_4 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WidgetFlip = _resolveComponent("WidgetFlip")!
  const _component_BuilderWidgetHotspot = _resolveComponent("BuilderWidgetHotspot")!
  const _component_user_bubble = _resolveComponent("user-bubble")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.widget.discovery === true && !_ctx.ignoreDiscovery)
      ? (_openBlock(), _createBlock(_component_WidgetFlip, {
          key: 0,
          widget: _ctx.widget,
          widgetPosition: _ctx.widgetPosition
        }, {
          front: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["widget-wrapper", [
              { 'widget-rounded': _ctx.widget.rounded },
              { 'widget-bordered': _ctx.widget.border },
              { 'widget-bottom-left-rounded': (!_ctx.mobileViewMode && _ctx.widget.position.x === 0 && _ctx.widget.position.y + _ctx.widget.position.h >= 270)},
              { 'widget-bottom-right-rounded': (!_ctx.mobileViewMode && _ctx.widget.position.x + _ctx.widget.position.w >= 480 && _ctx.widget.position.y + _ctx.widget.position.h >= 270)},
              { 'widget-mobile': _ctx.mobileViewMode },
              { 'widget-mobile-hidden': _ctx.mobileViewMode && _ctx.widget.mobileVisible === false },
              ('color' in _ctx.widget.specific) > 0 ? _ctx.colorClassMap[_ctx.widget.specific.color] : 'widget-white'
          ]]),
              tabindex: _ctx.widgetIndex,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.focusWidget && _ctx.focusWidget(...args))),
              onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.focusWidget && _ctx.focusWidget(...args))),
              onFocusout: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.focusOutWidget && _ctx.focusOutWidget(...args)))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["widget", [
             _ctx.widget.type,
             _ctx.widget.type === 'shape' ? _ctx.widget.specific.type : '',
             _ctx.widget.category,
             _ctx.solvingMode && !_ctx.readonly ? 'solving-mode' : '',
             _ctx.mobileViewMode ? 'widget-mobile' : '',
             _ctx.widget.specific.color === 'dark' ? 'is-dark' : '',
             ('color' in _ctx.widget.specific) > 0 ? _ctx.colorClassMap[_ctx.widget.specific.color] : 'widget-white'
             ]])
              }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentComponent), {
                  widget: _ctx.widget,
                  readonly: _ctx.readonly,
                  onPersistWidget: _ctx.handlePersistWidget
                }, null, 40, ["widget", "readonly", "onPersistWidget"]))
              ], 2)
            ], 42, _hoisted_1)
          ]),
          _: 1
        }, 8, ["widget", "widgetPosition"]))
      : _createCommentVNode("", true),
    _createVNode(_component_BuilderWidgetHotspot, {
      widget: _ctx.widget,
      "widget-index": _ctx.widgetIndex,
      class: _normalizeClass([
          _ctx.widget.specific.hotspot ? _ctx.hotspotPositionClassMap[_ctx.widget.specific.hotspotPosition] : '',
          ('color' in _ctx.widget.specific) > 0 ? _ctx.colorClassMap[_ctx.widget.specific.color] : 'widget-white'
      ]),
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onHotspotExpanded(_ctx.widget.widgetUuid)))
    }, null, 8, ["widget", "widget-index", "class"]),
    (!_ctx.widget.discovery || _ctx.ignoreDiscovery)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["widget-wrapper", [
                {'no-drag no-resize' : _ctx.isResourceLocked(_ctx.widget.widgetUuid)},
              { 'widget-rounded': _ctx.widget.rounded },
              { 'widget-bordered': _ctx.widget.border },
              { 'widget-bottom-left-rounded': (!_ctx.mobileViewMode && _ctx.widget.position.x === 0 && _ctx.widget.position.y + _ctx.widget.position.h >= 270)},
              { 'widget-bottom-right-rounded': (!_ctx.mobileViewMode && _ctx.widget.position.x + _ctx.widget.position.w >= 480 && _ctx.widget.position.y + _ctx.widget.position.h >= 270)},
              { 'widget-mobile': _ctx.mobileViewMode },
              { 'widget-mobile-hidden': _ctx.mobileViewMode && _ctx.widget.mobileVisible === false },
              { 'widget-debriefing': _ctx.activeSectionIndex === -1 },
              ('color' in _ctx.widget.specific) > 0 ? _ctx.colorClassMap[_ctx.widget.specific.color] : 'widget-white'
          ]]),
          tabindex: _ctx.widgetIndex,
          style: _normalizeStyle({ outline: _ctx.borderedStyle(_ctx.widget.widgetUuid)}),
          onMousedown: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onMouseDownHandler($event,_ctx.widget.widgetUuid))),
          onMouseup: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onMouseUpHandler($event, _ctx.widget.widgetUuid))),
          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.focusWidget && _ctx.focusWidget(...args))),
          onFocus: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.focusWidget && _ctx.focusWidget(...args))),
          onFocusout: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.focusOutWidget && _ctx.focusOutWidget(...args)))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["widget", [
             _ctx.widget.type === 'icon-card' ? 'widget-transparent' : '',
             _ctx.widget.type,
             _ctx.widget.type === 'shape' ? _ctx.widget.specific.type : '',
             _ctx.widget.category,
             _ctx.solvingMode && !_ctx.readonly ? 'solving-mode' : '',
             _ctx.mobileViewMode ? 'widget-mobile' : '',
             _ctx.widget.specific.color === 'dark' ? 'is-dark' : '',
             ('color' in _ctx.widget.specific) > 0 ? _ctx.colorClassMap[_ctx.widget.specific.color] : 'widget-white'
         ]])
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentComponent), {
              widget: _ctx.widget,
              readonly: _ctx.readonly || _ctx.isResourceLocked(_ctx.widget.widgetUuid),
              onPersistWidget: _ctx.handlePersistWidget
            }, null, 40, ["widget", "readonly", "onPersistWidget"]))
          ], 2)
        ], 46, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.solutionHistory.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('last.modified.label')) + ": ", 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.solutionHistory, (history) => {
            return (_openBlock(), _createBlock(_component_user_bubble, {
              key: history,
              color: _ctx.getUserColor(history.userUuid),
              "has-avatar": _ctx.getParticipantMetadata(history.userUuid).preIntro !== 'undefined',
              image: _ctx.getParticipantMetadata(history.userUuid).preIntro ? _ctx.getParticipantMetadata(history.userUuid).preIntro.avatar: ''
            }, _createSlots({
              name: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getFullName(history.userUuid)), 1)
              ]),
              email: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getEmail(history.userUuid)), 1)
              ]),
              _: 2
            }, [
              (_ctx.getParticipantMetadata(history.userUuid).preIntro)
                ? {
                    name: "nickname",
                    fn: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getParticipantMetadata(history.userUuid).preIntro.nickname), 1)
                    ])
                  }
                : undefined
            ]), 1032, ["color", "has-avatar", "image"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}