
import { computed, defineComponent, ref } from 'vue'
import { getApiKey } from '@/core/helpers/tinymce'
import { QuestionShortAnswer } from '@/core/helpers/template/WidgetInterface'
import { buildWidgetLocator } from '@/core/helpers/template/template.ts'
import Editor from '@tinymce/tinymce-vue'
import { useStore } from 'vuex'
import {
  getNameCredentials,
  updateQuestionShortAnswerSolutionHistory,
  generatedRandomColor
} from '@/core/helpers/template/widgetSolutionHistory.ts'
import { getParticipantMetadata } from '@/core/helpers/activity-team-module'

export default defineComponent({
  name: 'question-short-answer',
  emits: ['open-widget-settings', 'persist-widget'],
  props: {
    widget: { type: Object as () => QuestionShortAnswer, required: true },
    readonly: { type: Boolean, default: false }

  },
  components: {
    editor: Editor
  },
  setup (props) {
    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const solvingMode = computed(() => store.getters.getSolvingMode)
    const currentWidget = ref(props.widget as QuestionShortAnswer) as any
    const currentAccount = computed(() => store.getters.currentActiveUser)

    let persistTimeout = 0 as any
    const persistWidget = () => {
      if (props.readonly) {
        return
      }
      // const currentWidgetInstance = currentWidget.value
      clearTimeout(persistTimeout)
      persistTimeout = setTimeout(() => {
        console.log('Persisting widget...')
        const locatorInstance = buildWidgetLocator(activityTemplate, currentWidget.value.widgetUuid)
        if (!locatorInstance) return
        const solution = currentWidget.value.specific.solution.answer
        updateQuestionShortAnswerSolutionHistory(currentWidget, currentAccount, solution)
        const currentWidgetInstance = currentWidget.value

        const socketPayload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          teamUuid: currentAccount.value.teamUuid,
          locator: locatorInstance,
          widget: currentWidgetInstance
        }
        socket.value.emit('EDIT_WIDGET', {
          eventType: 'EDIT_WIDGET',
          payload: socketPayload
        })
      }, 1000)
    }

    const editorSettings = {
      setup: (editor) => {
        editor.on('keydown', function (e) {
          if (e.keyCode === 13) e.preventDefault() // prevent enter
        })
      },
      inline: true,
      menubar: false,
      toolbar: false,
      plugins: ['paste'],
      paste_as_text: true,
      paste_block_drop: true, // prevent pasting when dropping -> part of bug that allowed dragging to other widgets
      invalid_elements: 'br',
      paste_preprocess: function (plugin, args) { // replace multiple spaces and trim on paste
        args.content = args.content.replace(/\s\s+/g, ' ').trim()
      }
    }

    return {
      currentWidget,
      editorSettings,
      solvingMode,
      currentAccount,
      getApiKey,
      persistWidget,
      getNameCredentials,
      generatedRandomColor,
      getParticipantMetadata
    }
  }
})
