import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-249fb2e3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vue-grid-item" }
const _hoisted_2 = { class: "widget-wrapper widget-grey-light" }
const _hoisted_3 = { class: "builder" }
const _hoisted_4 = { class: "builder-center" }
const _hoisted_5 = {
  key: 0,
  class: "spacing-top"
}
const _hoisted_6 = {
  key: 3,
  class: "spacing-bottom"
}
const _hoisted_7 = { class: "card survey-card" }
const _hoisted_8 = { class: "card-body" }
const _hoisted_9 = { class: "survey-question" }
const _hoisted_10 = { class: "survey-options" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["id", "checked"]
const _hoisted_13 = ["for"]
const _hoisted_14 = { class: "survey-submit" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = {
  key: 3,
  class: "page houston-builder-wrapper",
  style: {"height":"100vh","width":"100vw","justify-content":"center","align-items":"center"}
}
const _hoisted_17 = { style: {"font-weight":"600","color":"#191C30","padding":"40px","box-shadow":"0px 0px 20px 0px rgb(76 87 125 / 10%)","border-radius":"12px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")!
  const _component_StyledTextCard = _resolveComponent("StyledTextCard")!
  const _component_QuestionTeamReadiness = _resolveComponent("QuestionTeamReadiness")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_BuilderMobileTopMenu = _resolveComponent("BuilderMobileTopMenu")!
  const _component_BuilderWorkspace = _resolveComponent("BuilderWorkspace")!
  const _component_BuilderMobileBottomMenu = _resolveComponent("BuilderMobileBottomMenu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaderEnabled)
      ? (_openBlock(), _createBlock(_component_KTLoader, {
          key: 0,
          logo: _ctx.loaderLogo
        }, null, 8, ["logo"]))
      : _createCommentVNode("", true),
    (_ctx.currentAccount && (_ctx.hasParticipantAnsweredConfidence(_ctx.currentAccount.activeUserUuid) || !_ctx.isActivityEnded()) && !_ctx.wasExitPressed)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["page builder-wrapper", [
          { 'mobile': _ctx.mobileViewMode },
          _ctx.activeSection && ('color' in _ctx.activeSection) > 0 ? _ctx.sectionColorClassMap[_ctx.activeSection.color] : 'section-white',
          _ctx.getParticipantMetadata(_ctx.currentAccount.activeUserUuid).preIntro ? _ctx.cursorColorMap.find(item => item.color === _ctx.getParticipantMetadata(_ctx.currentAccount.activeUserUuid).preIntro.color)?.class : _ctx.getParticipantMetadata(_ctx.currentAccount.activeUserUuid).userColorLabel
          ]]),
          style: _normalizeStyle(_ctx.sectionBackgroundStyle)
        }, [
          _createVNode(_component_el_dialog, {
            "lock-scroll": false,
            modelValue: _ctx.showLinkToWidgetModal,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showLinkToWidgetModal) = $event)),
            width: _ctx.linkToWidget && _ctx.linkToWidget.type ? _ctx.modalDefaultWidth[_ctx.linkToWidget.type] : '400px',
            "show-close": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "custom-class": `image360-widget-modal ${ _ctx.linkToWidget && _ctx.linkToWidget.rounded ? 'round-corners' : '' }`
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["widget info widget-grey-light no-transparency", `${_ctx.linkToWidget.type} ${_ctx.linkToWidget && _ctx.linkToWidget.rounded ? 'round-corners' : '' } ${ _ctx.linkToWidget && _ctx.linkToWidget.specific.textColor ? 'widget-' + _ctx.linkToWidget.specific.textColor : '' }`]),
                    style: _normalizeStyle({ background: _ctx.linkToWidget.specific.color})
                  }, [
                    (_ctx.linkToWidget && _ctx.linkToWidget.type === 'text-card-styled')
                      ? (_openBlock(), _createBlock(_component_StyledTextCard, {
                          key: 0,
                          widget: _ctx.linkToWidget
                        }, null, 8, ["widget"]))
                      : _createCommentVNode("", true),
                    (_ctx.linkToWidget && _ctx.linkToWidget.type === 'question-team-readiness')
                      ? (_openBlock(), _createBlock(_component_QuestionTeamReadiness, {
                          key: 1,
                          widget: _ctx.linkToWidget,
                          "parent-widget-uuid": _ctx.usedWidgetUuid,
                          onPersistWidget: _ctx.handlePersistWidgetQuestionTeamReadiness
                        }, null, 8, ["widget", "parent-widget-uuid", "onPersistWidget"]))
                      : _createCommentVNode("", true)
                  ], 6)
                ])
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "width", "custom-class"]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (!_ctx.mobileViewMode)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5))
                : _createCommentVNode("", true),
              (_ctx.mobileViewMode)
                ? (_openBlock(), _createBlock(_component_BuilderMobileTopMenu, { key: 1 }))
                : _createCommentVNode("", true),
              _createVNode(_component_BuilderWorkspace, {
                ref: "builderWorkspace",
                "active-widget-index": _ctx.activeWidgetIndex,
                section: _ctx.activeSection,
                onMousemove: _ctx.onMouseMoveEvent,
                onOpenWidgetSettings: _ctx.openWidgetSettings
              }, null, 8, ["active-widget-index", "section", "onMousemove", "onOpenWidgetSettings"]),
              (_ctx.mobileViewMode)
                ? (_openBlock(), _createBlock(_component_BuilderMobileBottomMenu, { key: 2 }))
                : _createCommentVNode("", true),
              (!_ctx.mobileViewMode)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                : _createCommentVNode("", true)
            ])
          ])
        ], 6))
      : _createCommentVNode("", true),
    (_ctx.isActivityEnded() && !_ctx.hasParticipantAnsweredConfidence(_ctx.currentAccount.activeUserUuid) && !_ctx.wasExitPressed)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["page builder-wrapper survey", {mobile: _ctx.mobileViewMode}])
        }, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('confidence.survey.question')), 1),
              _createElementVNode("div", _hoisted_10, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.confidenceLevels, (level) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: level,
                    class: "form-check form-check-custom form-check-solid survey-answer",
                    onClick: ($event: any) => (_ctx.onSolutionChanged(level))
                  }, [
                    _createElementVNode("input", {
                      class: "form-check-input form-check-lg",
                      type: "radio",
                      id: level.uuid,
                      checked: _ctx.selectedConfidenceLevel === level.uuid
                    }, null, 8, _hoisted_12),
                    _createElementVNode("label", {
                      for: level.uuid,
                      class: "form-check-label"
                    }, _toDisplayString(level.label), 9, _hoisted_13)
                  ], 8, _hoisted_11))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("button", {
                  disabled: typeof(_ctx.selectedConfidenceLevel) ===  'undefined',
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSolutionSubmitted && _ctx.onSolutionSubmitted(...args))),
                  class: "btn btn-primary btn-dark"
                }, _toDisplayString(_ctx.$t('confidence.survey.button')), 9, _hoisted_15)
              ])
            ])
          ])
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.wasExitPressed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('exit.see.you')), 1)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}