import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "widget-body" }
const _hoisted_2 = { class: "widget-body-content tiny-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_editor = _resolveComponent("editor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_editor, {
        modelValue: _ctx.currentWidget.specific.content,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentWidget.specific.content) = $event)),
        "api-key": _ctx.getApiKey(),
        disabled: true,
        init: _ctx.editorAdvancedSettings
      }, null, 8, ["modelValue", "api-key", "init"])
    ])
  ]))
}