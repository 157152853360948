

export default {
  name: 'user-bubble',
  props: {
    color: {
      type: String,
      required: true,
      validator: (value) => {
        const colorCodeRegex = /^#([0-9A-Fa-f]{3}){1,2}$/i
        return colorCodeRegex.test(value)
      }
    },
    hasAvatar: {
      type: Boolean,
      required: true,
      default: false
    },
    image: {
      type: String,
      required: false
    }
  }
}
