
import { computed, defineComponent } from 'vue'
import { Widget, WidgetPosition } from '@/core/helpers/template/TemplateInterface'
import store from '@/store'
import { Mutations } from '@/store/enums/StoreEnums'
import { colorClassMap } from '@/core/helpers/template/widget'

export default defineComponent({
  name: 'card-flip',
  props: {
    widget: { type: Object as () => Widget, required: true },
    widgetPosition: { type: Object as () => WidgetPosition, required: false }
  },
  setup (props) {
    const mobileViewMode = computed(() => store.getters.getMobileViewMode)
    let discoveredWidgets = computed(() => store.getters.getDiscoveredWidgets)

    const cardIsFlipped = computed(() => discoveredWidgets.value.indexOf(props.widget.widgetUuid) !== -1) as any
    const onCardFlipped = (widgetUuid) => {
      store.commit(Mutations.SET_TEMPLATE_WIDGET_DISCOVERED, widgetUuid)
      discoveredWidgets = computed(() => store.getters.getDiscoveredWidgets)
    }

    return {
      colorClassMap,
      onCardFlipped,
      cardIsFlipped,
      mobileViewMode
    }
  }
})

