<template>
  <div class="control-button-radar">
    <select class="control-button" v-model="selectedLocale" @change="changeLocale">
      <option v-for="locale in locales" :value="locale" :key="locale">{{ locale.toUpperCase() }}</option>
    </select>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { onMounted } from 'vue'

export default {
  setup () {
    const { locale, availableLocales } = useI18n({ useScope: 'global' })

    const locales = availableLocales
    const selectedLocale = locale.value

    const changeLocale = (event) => {
      locale.value = event.target.value
    }
    onMounted(() => {
      console.log('available locales', availableLocales, locale)
    })

    return {
      locales,
      selectedLocale,
      changeLocale
    }
  }
}
</script>
<style lang="scss" scoped>
.control-button-radar {
  height: 42px;
  width: 42px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 100%;
  }

  .control-button {
    background: #ffc67f;
    border-radius: 50%;
    position: relative;
    outline: 2px solid transparent !important;
    cursor: unset;
    transition: all 0.2s;
    transition-delay: 0.1s;
    width: 35px;
    height: 35px;
    appearance: none;
    text-align: center;
    font-weight: 600;

    svg {
      height: 22px;
      width: 22px;
    }
  }

  &:hover {
    .control-button {
      transform: scale(1.1);
    }
  }

  &.active {
    .control-button {
      background: #ffc67f;
      //outline: 2px solid $houston-brand-dark !important;
    }

    &::before {
      //animation: spin 5000ms linear infinite;
      //background: conic-gradient($houston-brand-warning 10%, transparent 30%, transparent 97%, #ffc67f 98%);
    }

    @keyframes spin {
      0% {
        transform: rotate(360deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }
}
</style>
