
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import store from '@/store'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { getApiKey } from '@/core/helpers/tinymce.ts'
import BuilderWidget from '@/views/session/BuilderWidget.vue'
import Editor from '@tinymce/tinymce-vue'
import { buildTemplateFileUriForDownload } from '@/core/helpers/activity-template'

export default defineComponent({
  name: 'user-debriefing-v2',
  props: {},
  components: {
    BuilderWidget,
    editor: Editor
  },
  setup () {
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const insights = computed(() => store.getters.getActivitySessionsInsights)
    const currentAccount = computed(() => store.getters.currentActiveUser)
    const chatMessagesList = computed(() => store.getters.getChatMessagesList)
    const solvePercentageUpdated = ref(0) as any
    const message = ref('')
    const teamScore = ref(0) as any
    const scoreDescStyle = ref({})
    const isTrainerTyping = ref(false)
    const showCorrectAnswers = ref(false)
    const vtChatMessages = ref(null) as any

    const questionScoreStyle = (number) => { return { background: number < 50 ? '#ff8686' : (number < 75 ? '#ffc764' : '#69BC92') } }

    watch(() => chatMessagesList, (cv, ov) => {
      setTimeout(() => {
        vtChatMessages.value.scrollTop = vtChatMessages.value.scrollHeight
      }, 100)
    }, { deep: true })

    const formatTimeAgo = (date) => {
      return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
    }

    const submitMessage = () => {
      if (message.value.trim().length < 2) {
        return
      }

      isTrainerTyping.value = true
      const newDate = new Date().toISOString()

      const content = message.value

      const newMessage = {
        content: content,
        createdAt: newDate,
        generatedByAi: false,
        updatedAt: newDate
      }

      // clear the message input
      message.value = ''

      store.commit(Mutations.ADD_CHAT_MESSAGE, newMessage)

      store.dispatch(Actions.API_CREATE_CHAT_MESSAGE, { search: content }).then((response) => {
        // store.commit(Mutations.ADD_CHAT_MESSAGE, response.payload)
        isTrainerTyping.value = false
      }).catch((error) => {
        isTrainerTyping.value = false
        console.log(error)
      })
    }

    const submitMessageFromSuggestion = (suggestionMessage) => {
      if (isTrainerTyping.value === true) return
      isTrainerTyping.value = true
      const newDate = new Date().toISOString()

      const newMessage = {
        content: suggestionMessage,
        createdAt: newDate,
        generatedByAi: false,
        updatedAt: newDate
      }

      store.commit(Mutations.ADD_CHAT_MESSAGE, newMessage)
      store.dispatch(Actions.API_CREATE_CHAT_MESSAGE, { search: suggestionMessage }).then((response) => {
        // store.commit(Mutations.ADD_CHAT_MESSAGE, response.payload)
        isTrainerTyping.value = false
      }).catch((error) => {
        isTrainerTyping.value = false
        console.log(error)
      })
    }

    const getAveragePercentage = (insights) => {
      const { length } = insights
      return parseInt(insights.reduce((insight, val) => {
        return insight + (parseInt(val.solvePercentage) / length)
      }, 0))
    }

    onMounted(() => {
      const insightsPayload = {
        sessionUuid: currentAccount.value.sessionUuid,
        teamUuid: currentAccount.value.teamUuid
      } as any
      store.dispatch(Actions.API_GET_ACTIVITY_SESSION_INSIGHTS, insightsPayload).then((response) => {
        teamScore.value = getAveragePercentage(response.payload.insights)
        solvePercentageUpdated.value = ((getAveragePercentage(response.payload.insights) / activityTemplate.value.targetScore) * 100).toFixed(0)
        scoreDescStyle.value = { color: solvePercentageUpdated.value < 60 ? '#ffb394' : (solvePercentageUpdated.value < 80 ? '#ffca95' : '#c9d0b3') }
      })

      store.dispatch(Actions.API_GET_CHAT_MESSAGES_LIST).then((response) => {
        console.log('CHAT MESSAGES LIST', response)
        if (response.payload.length === 0) {
          isTrainerTyping.value = true
          store.dispatch(Actions.API_CREATE_CHAT_MESSAGE).then((response) => {
            isTrainerTyping.value = false
          }).catch((error) => {
            console.log('Error while initializing the chat: ', error)
            isTrainerTyping.value = false
          })
        }
      }).catch((error) => {
        console.log('Error while retrieving the chat: ', error)
        isTrainerTyping.value = false
      })
    })
    const onFileDownload = (attachmentUuid, companyLabel) => {
      window.open(buildTemplateFileUriForDownload(attachmentUuid, currentAccount.value.employee.company ? currentAccount.value.employee.company : currentAccount.value.company), '_blank')
      window.focus()
    }

    const editorSettings = {
      inline: true,
      menubar: false,
      toolbar: false
    }

    return {
      submitMessage,
      formatTimeAgo,
      submitMessageFromSuggestion,
      activityTemplate,
      message,
      teamScore,
      solvePercentageUpdated,
      scoreDescStyle,
      chatMessagesList,
      isTrainerTyping,
      vtChatMessages,
      insights,
      questionScoreStyle,
      getApiKey,
      editorSettings,
      showCorrectAnswers,
      onFileDownload
    }
  }
})
