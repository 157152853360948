
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { getApiKey } from '@/core/helpers/tinymce'
import { QuestionMultipleChoice } from '@/core/helpers/template/WidgetInterface'
import { buildWidgetLocator } from '@/core/helpers/template/template.ts'
import Editor from '@tinymce/tinymce-vue'
import { uuid } from 'vue-uuid'
import { ElNotification } from 'element-plus/es'
import {
  getNameCredentials,
  updateQuestionMultipleChoiceSolutionHistory,
  generatedRandomColor
} from '@/core/helpers/template/widgetSolutionHistory.ts'
import { getParticipantMetadata } from '@/core/helpers/activity-team-module'

export default defineComponent({
  name: 'question-multiple-choice',
  emits: ['open-widget-settings', 'persist-widget'],
  props: {
    widget: { type: Object as () => QuestionMultipleChoice, required: true },
    readonly: { type: Boolean, default: false }

  },
  components: {
    editor: Editor
  },
  setup (props) {
    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const solvingMode = computed(() => store.getters.getSolvingMode)
    const currentWidget = ref(props.widget as QuestionMultipleChoice) as any
    const currentAccount = computed(() => store.getters.currentActiveUser)

    // let persistTimeout = 0 as any
    const persistWidget = () => {
      if (props.readonly) {
        return
      }
      // clearTimeout(persistTimeout)
      // persistTimeout = setTimeout(() => {
      console.log('Persisting widget...')
      const locatorInstance = buildWidgetLocator(activityTemplate, currentWidget.value.widgetUuid)
      if (!locatorInstance) return
      const currentWidgetInstance = currentWidget.value
      const socketPayload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        teamUuid: currentAccount.value.teamUuid,
        locator: locatorInstance,
        widget: currentWidgetInstance
      }
      socket.value.emit('EDIT_WIDGET', {
        eventType: 'EDIT_WIDGET',
        payload: socketPayload
      })
      // }, 1000)
    }

    const onSolutionChanged = (answerUuid) => {
      if (props.readonly) {
        return
      }
      console.log('Solution changed :', answerUuid)
      if (currentWidget.value.specific.solution.includes(answerUuid)) {
        currentWidget.value.specific.solution = currentWidget.value.specific.solution.filter(a => a !== answerUuid)
      } else {
        currentWidget.value.specific.solution.push(answerUuid)
      }
      const solution = currentWidget.value.specific.solution
      updateQuestionMultipleChoiceSolutionHistory(currentWidget, currentAccount, solution)
      persistWidget()
    }

    const onAnswerAdd = () => {
      console.log('Answer option added...')
      if (currentWidget.value.specific.answersList.length > 6) {
        ElNotification.warning({
          message: 'The widget <b>may not</b> have more than 7 answer options.',
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
        return false
      }
      currentWidget.value.specific.answersList.unshift({
        answerUuid: uuid.v4(),
        answer: ''
      })
      persistWidget()
    }

    const onAnswerRemoved = (answerIndex) => {
      console.log('Answer option removed...')
      if (currentWidget.value.specific.answersList.length <= 2) {
        ElNotification.warning({
          message: 'The widget <b>may not</b> have less than 2 answer options.',
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
        return false
      }
      const removedAnswerUuid = currentWidget.value.specific.answersList[answerIndex].answerUuid
      currentWidget.value.specific.answersList.splice(answerIndex, 1)
      currentWidget.value.specific.solution = currentWidget.value.specific.solution.filter(s => s !== removedAnswerUuid)
      persistWidget()
    }

    const editorSettings = {
      setup: (editor) => {
        editor.on('keydown', function (e) {
          if (e.keyCode === 13) e.preventDefault() // prevent enter
        })
      },
      inline: true,
      menubar: false,
      toolbar: false,
      plugins: ['paste'],
      paste_as_text: true,
      paste_block_drop: true, // prevent pasting when dropping -> part of bug that allowed dragging to other widgets
      invalid_elements: 'br',
      paste_preprocess: function (plugin, args) { // replace multiple spaces and trim on paste
        args.content = args.content.replace(/\s\s+/g, ' ').trim()
      }
    }

    return {
      currentWidget,
      solvingMode,
      editorSettings,
      currentAccount,
      getApiKey,
      persistWidget,
      onSolutionChanged,
      onAnswerAdd,
      onAnswerRemoved,
      getNameCredentials,
      generatedRandomColor,
      getParticipantMetadata
    }
  }
})
