import {
  QuestionSingleChoiceSolutionHistory,
  QuestionMultipleChoiceSolutionHistory,
  QuestionShortAnswerSolutionHistory,
  DragAndDropGroupSolutionHistory,
  DragAndDropOrderSolutionHistory,
  ReflectionPostItHistory
} from '@/core/helpers/template/WidgetSolutionHistoryInterface'

export const getNameCredentials = (name) => {
  return name.split(' ').map(str => str[0]).join('')
}

export const generatedRandomColor = (characters = '') => {
  // TODO: adjust base colour values below based on theme
  const baseRed = 300
  const baseGreen = 300
  const baseBlue = 300

  // lazy seeded random hack to get values from 0 - 256
  // for seed just take bitwise XOR of first two chars
  let seed = characters.charCodeAt(0) ^ characters.charCodeAt(1)
  const rand1 = Math.abs((Math.sin(seed++) * 10000)) % 256
  const rand2 = Math.abs((Math.sin(seed++) * 10000)) % 256
  const rand3 = Math.abs((Math.sin(seed++) * 10000)) % 256

  // build colour
  const red = Math.round((rand1 + baseRed) / 2)
  const green = Math.round((rand2 + baseGreen) / 2)
  const blue = Math.round((rand3 + baseBlue) / 2)
  return 'rgb(' + red + ',' + green + ',' + blue + ')'
}

export const updateReflectionPostItHistory = (widget, account, content) => {
  if (widget.value.specific.solutionHistory === undefined) {
    widget.value.specific.solutionHistory = []
  }

  const solutionHistory: ReflectionPostItHistory = {
    timestamp: new Date().getTime(),
    content: content,
    userUuid: account.value.employee.userUuid ? account.value.employee.userUuid : account.value.activeUserUuid,
    fullName: account.value.employee.fullName ? account.value.employee.fullName : account.value.displayName
  }
  addHistory(widget, solutionHistory)
}

export const updateQuestionShortAnswerSolutionHistory = (widget, account, solution) => {
  if (widget.value.specific.solutionHistory === undefined) {
    widget.value.specific.solutionHistory = []
  }
  const solutionHistory: QuestionShortAnswerSolutionHistory = {
    timestamp: new Date().getTime(),
    solution: solution,
    userUuid: account.value.employee.userUuid ? account.value.employee.userUuid : account.value.activeUserUuid,
    fullName: account.value.employee.fullName ? account.value.employee.fullName : account.value.displayName
  }

  addHistory(widget, solutionHistory)
}

export const getQuestionShortAnswerSolutionHistory = (widget) => {
  //
  // let solutionHistory = []
  // if (typeof widget.value !== 'undefined') {
  //   if (Array.isArray(widget.value.specific.solutionHistory)) {
  //     solutionHistory = widget.value.specific.solutionHistory
  //   }
  // } else {
  //   if (Array.isArray(widget.specific.solutionHistory)) {
  //     solutionHistory = widget.specific.solutionHistory
  //   }
  // }

  if (typeof widget.value !== 'undefined' && !Array.isArray(widget.value.specific.solutionHistory)) return []
  if (typeof widget.specific !== 'undefined' && !Array.isArray(widget.specific.solutionHistory)) return []

  // if (!Array.isArray(widget.value) || !Array.isArray(widget.specific.solutionHistory)) return []

  const reversedSolutionHistory = widget.specific.solutionHistory.slice().reverse()
  const solutionHistory: Array<QuestionSingleChoiceSolutionHistory> = []

  for (let l = 0; l < reversedSolutionHistory.length; l++) {
    if (solutionHistory.length >= 1) {
      if (solutionHistory.slice(-1)[0].userUuid !== reversedSolutionHistory[l].userUuid) {
        solutionHistory.push(reversedSolutionHistory[l])
        if (solutionHistory.length >= 5) {
          break
        }
      }
    } else {
      solutionHistory.push(reversedSolutionHistory[l])
    }
  }

  return solutionHistory
}

export const updateQuestionSingleChoiceSolutionHistory = (widget, account, solution) => {
  if (widget.value.specific.solutionHistory === undefined) {
    widget.value.specific.solutionHistory = [] as Array<QuestionSingleChoiceSolutionHistory>
    console.log('After Normalization: ', widget.value.specific.solutionHistory)
  }
  const solutionHistory: QuestionSingleChoiceSolutionHistory = {
    timestamp: new Date().getTime(),
    solution: solution,
    userUuid: account.value.employee.userUuid ? account.value.employee.userUuid : account.value.activeUserUuid,
    fullName: account.value.employee.fullName ? account.value.employee.fullName : account.value.displayName
  }
  addHistory(widget, solutionHistory)
}

export const getQuestionSingleChoiceSolutionHistory = (widget) => {
  if (!Array.isArray(widget.value.specific.solutionHistory)) return []

  const reversedSolutionHistory = widget.value.specific.solutionHistory.slice().reverse()
  const solutionHistory: Array<QuestionSingleChoiceSolutionHistory> = []

  for (let l = 0; l < reversedSolutionHistory.length; l++) {
    if (solutionHistory.length >= 1) {
      if (solutionHistory.slice(-1)[0].userUuid !== reversedSolutionHistory[l].userUuid) {
        solutionHistory.push(reversedSolutionHistory[l])
        if (solutionHistory.length >= 5) {
          break
        }
      }
    } else {
      solutionHistory.push(reversedSolutionHistory[l])
    }
  }

  return solutionHistory
}

export const updateQuestionMultipleChoiceSolutionHistory = (widget, account, solution) => {
  if (widget.value.specific.solutionHistory === undefined) {
    widget.value.specific.solutionHistory = []
  }
  const solutionHistory: QuestionMultipleChoiceSolutionHistory = {
    timestamp: new Date().getTime(),
    solution: solution,
    userUuid: account.value.employee.userUuid ? account.value.employee.userUuid : account.value.activeUserUuid,
    fullName: account.value.employee.fullName ? account.value.employee.fullName : account.value.displayName
  }
  addHistory(widget, solutionHistory)
}

export const getQuestionMultipleChoiceSolutionHistory = (widget) => {
  if (!Array.isArray(widget.value.specific.solutionHistory)) return []

  const reversedSolutionHistory = widget.value.specific.solutionHistory.slice().reverse()
  const solutionHistory: Array<QuestionSingleChoiceSolutionHistory> = []

  for (let l = 0; l < reversedSolutionHistory.length; l++) {
    if (solutionHistory.length >= 1) {
      if (solutionHistory.slice(-1)[0].userUuid !== reversedSolutionHistory[l].userUuid) {
        solutionHistory.push(reversedSolutionHistory[l])
        if (solutionHistory.length >= 5) {
          break
        }
      }
    } else {
      solutionHistory.push(reversedSolutionHistory[l])
    }
  }

  return solutionHistory
}

export const updateDragAndDropGroupSolutionHistory = (widget, account) => {
  if (widget.value.specific.solutionHistory === undefined) {
    widget.value.specific.solutionHistory = []
  }
  const solutionHistory: DragAndDropGroupSolutionHistory = {
    timestamp: new Date().getTime(),
    solution: widget.value.specific.solution,
    userUuid: account.value.employee.userUuid ? account.value.employee.userUuid : account.value.activeUserUuid,
    fullName: account.value.employee.fullName ? account.value.employee.fullName : account.value.displayName
  }
  widget.value.specific.solutionHistory.push(solutionHistory)
}

export const getDragAndDropGroupSolutionHistory = (widget, columnUuid) => {
  if (!Array.isArray(widget.value.specific.solutionHistory)) return []

  const solutionHistory = widget.value.specific.solutionHistory.slice() // slice to break any reference
  const normalizedSolutionHistory: Array<any> = []

  // make the normalized array
  for (let l = 0; l < solutionHistory.length; l++) {
    const currentColumnIndex = solutionHistory[l].solution.findIndex(s => s.columnUuid === columnUuid)
    const currentSolution = solutionHistory[l].solution[currentColumnIndex]
    if (normalizedSolutionHistory.length === 0) { // push the first item
      if (currentSolution.itemList.length > 0) { // check length to avoid marking the column as modified for the first solution history that did not affect this column
        const normalizedItem = {
          timestamp: solutionHistory[l].timestamp,
          userUuid: solutionHistory[l].userUuid,
          fullName: solutionHistory[l].fullName
        }
        normalizedSolutionHistory.push(normalizedItem)
      }
    } else { // push the rest of the items only if they differ from previous
      const previousColumnIndex = solutionHistory[l - 1].solution.findIndex(s => s.columnUuid === columnUuid)
      const previousSolution = solutionHistory[l - 1].solution[previousColumnIndex]
      if (previousSolution.itemList.join(',') !== currentSolution.itemList.join(',')) { // compare as concatenated strings to make sure the 2 consecutive solutions differ
        const normalizedItem = {
          timestamp: solutionHistory[l].timestamp,
          userUuid: solutionHistory[l].userUuid,
          fullName: solutionHistory[l].fullName
        }
        normalizedSolutionHistory.push(normalizedItem)
      }
    }
  }

  // reverse the array to keep the latest changes first then filter to keep only one consecutive change made by the same user
  normalizedSolutionHistory.reverse()
  const reversedSolutionHistory: Array<any> = []
  for (let l = 0; l < normalizedSolutionHistory.length; l++) {
    if (reversedSolutionHistory.length === 0) {
      reversedSolutionHistory.push(normalizedSolutionHistory[l])
    } else {
      if (normalizedSolutionHistory[l].userUuid !== normalizedSolutionHistory[l - 1].userUuid) {
        reversedSolutionHistory.push(normalizedSolutionHistory[l])
        if (reversedSolutionHistory.length > 5) { // only last 5 history changes
          break
        }
      }
    }
  }

  return reversedSolutionHistory
}

export const updateDragAndDropOrderSolutionHistory = (widget, account) => {
  if (widget.value.specific.solutionHistory === undefined) {
    widget.value.specific.solutionHistory = []
  }
  const solutionHistory: DragAndDropOrderSolutionHistory = {
    timestamp: new Date().getTime(),
    solution: widget.value.specific.solution,
    userUuid: account.value.employee.userUuid ? account.value.employee.userUuid : account.value.activeUserUuid,
    fullName: account.value.employee.fullName ? account.value.employee.fullName : account.value.displayName
  }
  widget.value.specific.solutionHistory.push(solutionHistory)
}

export const getDragAndDropOrderSolutionHistory = (widget) => {
  if (!Array.isArray(widget.value.specific.solutionHistory)) return []

  const solutionHistory = widget.value.specific.solutionHistory.slice() // slice to break any reference
  const normalizedSolutionHistory: Array<any> = []

  // make the normalized array
  for (let l = 0; l < solutionHistory.length; l++) {
    const currentSolution = solutionHistory[l].solution[0]
    if (normalizedSolutionHistory.length === 0) { // push the first item
      if (currentSolution.itemList.length > 0) { // check length to avoid marking the column as modified for the first solution history that did not affect this column
        const normalizedItem = {
          timestamp: solutionHistory[l].timestamp,
          userUuid: solutionHistory[l].userUuid,
          fullName: solutionHistory[l].fullName
        }
        normalizedSolutionHistory.push(normalizedItem)
      }
    } else { // push the rest of the items only if they differ from previous
      const previousSolution = solutionHistory[l - 1].solution[0]
      if (previousSolution.itemList.join(',') !== currentSolution.itemList.join(',')) { // compare as concatenated strings to make sure the 2 consecutive solutions differ
        const normalizedItem = {
          timestamp: solutionHistory[l].timestamp,
          userUuid: solutionHistory[l].userUuid,
          fullName: solutionHistory[l].fullName
        }
        normalizedSolutionHistory.push(normalizedItem)
      }
    }
  }

  // reverse the array to keep the latest changes first then filter to keep only one consecutive change made by the same user
  normalizedSolutionHistory.reverse()
  const reversedSolutionHistory: Array<any> = []
  for (let l = 0; l < normalizedSolutionHistory.length; l++) {
    if (reversedSolutionHistory.length === 0) {
      reversedSolutionHistory.push(normalizedSolutionHistory[l])
    } else {
      if (normalizedSolutionHistory[l].userUuid !== normalizedSolutionHistory[l - 1].userUuid) {
        reversedSolutionHistory.push(normalizedSolutionHistory[l])
        if (reversedSolutionHistory.length > 5) { // only last 5 history changes
          break
        }
      }
    }
  }

  return reversedSolutionHistory
}

const addHistory = (widget, solutionHistory) => {
  if (widget.value.type === 'question-single-choice') { // there are some widgets for which we push history directly
    widget.value.specific.solutionHistory.push(solutionHistory)
  } else { // for the rest of widgets we replace the last history as long as it is done by the same user
    if (widget.value.specific.solutionHistory.length === 0 || widget.value.specific.solutionHistory.slice(-1)[0].userUuid !== solutionHistory.userUuid) {
      widget.value.specific.solutionHistory.push(solutionHistory)
    } else {
      const lastIndex = widget.value.specific.solutionHistory.length - 1
      widget.value.specific.solutionHistory[lastIndex] = solutionHistory
    }
  }
}
