
import { computed, defineComponent, inject, onMounted, onUnmounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
// import { ActiveUser } from '@/store/modules/ActiveUserModule'
// import { ActiveUser } from '@/store/modules/ActiveUserModule'

export default defineComponent({
  name: 'jitsi-meet-composition',
  components: {
  },
  setup () {
    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    const meetContainer = ref<HTMLElement>()
    const showVideo = ref(true)
    const room = ref('test room')
    const userName = ref('test name')
    const api = ref(null) as any
    const mobileViewMode = computed(() => store.getters.getMobileViewMode)

    const details = reactive({ account: computed(() => store.getters.currentActiveUser) })
    const emitter = inject('emitter') as any

    const currentUser = computed(() => store.getters.currentActiveUser)
    const jitsiIframeLoading = ref(true)
    // const domain = '127.0.0.1:8081'
    // const domain = 'meet.houstonteam.app'
    const domain = 'meet.teamlearn.ai'
    // watch(totalConnectedNotObserversCount, () => {
    //   console.log('totalConnectedNotObserversCount', totalConnectedNotObserversCount)
    // })
    const jitsiBackground = mobileViewMode.value ? 'transparent' : '#ffffff'
    const options = {
      onload: function () {
        setTimeout(() => {
          jitsiIframeLoading.value = false
        }, 1500)
      },
      roomName: room.value,
      // height: 650,
      width: 150,
      // parentNode: meetContainer.value,
      interfaceConfigOverwrite: {
        // filmStripOnly: false,
        SHOW_JITSI_WATERMARK: false,
        VERTICAL_FILMSTRIP: false,
        RECENT_LIST_ENABLED: false,
        HIDE_DEEP_LINKING_LOGO: true,
        HIDE_INVITE_MORE_HEADER: true,
        DEFAULT_LOGO_URL: '',
        DEFAULT_WELCOME_PAGE_LOGO_URL: '',
        JITSI_WATERMARK_LINK: '',

        // TILE_VIEW_MAX_COLUMNS: 1,
        DEFAULT_BACKGROUND: jitsiBackground,
        TOOLBAR_ALWAYS_VISIBLE: true,
        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        ENFORCE_NOTIFICATION_AUTO_DISMISS_TIMEOUT: 0,
        TOOLBAR_BUTTONS: [
          'camera',
          // 'chat',
          'microphone'
          // 'tileview'
        ]
      },
      configOverwrite: {
        disableDeepLinking: true,
        backgroundAlpha: 0,
        disableSimulcast: false,
        enableWelcomePage: false,
        prejoinPageEnabled: false,
        hideDisplayName: false,
        hideParticipantsStats: true,
        connectionIndicators: {
          autoHide: true,
          autoHideTimeout: 5000,
          disabled: true,
          disableDetails: false,
          inactiveDisabled: false
        },
        gravatar: {
          // Defaults to Gravatar.
          baseUrl: 'https://www.libravatar.org/gravatarproxy/',
          // True if Gravatar should be disabled.
          disabled: false
        },
        // participantsPane: {
        // Hides the moderator settings tab.
        disableModeratorIndicator: true,
        // Hides the more actions button.
        disableLocalVideoFlip: true,
        // Hides the mute all button.
        disableRemoteMute: true,
        // disableTileView: true,

        toolbarButtons: [
          'camera',
          // 'chat',
          // 'tileview',
          'microphone'
          // 'tileview'
        ],
        // disableTileView: false,
        startWithAudioMuted: currentUser.value.userRole === 2,
        startWithVideoMuted: currentUser.value.userRole === 2,
        faceLandmarks: {
          // Enables sharing your face coordinates. Used for centering faces within a video.
          enableFaceCentering: true,

          // Enables detecting face expressions and sharing data with other participants
          enableFaceExpressionsDetection: true,

          // Enables displaying face expressions in speaker stats
          enableDisplayFaceExpressions: true,
          // Enable rtc stats for face landmarks
          enableRTCStats: true,

          // Minimum required face movement percentage threshold for sending new face centering coordinates data.
          faceCenteringThreshold: 10,

          // Milliseconds for processing a new image capture in order to detect face coordinates if they exist.
          captureInterval: 1000
        }
      }
    } as any

    const openRoom = () => {
      if (window.JitsiMeetExternalAPI) {
        console.log('currentUser', currentUser)
        options.roomName = currentUser.value.teamUuid
        userName.value = currentUser.value.displayName
        startConference()
      } else alert('Jitsi Meet API script not loaded')
    }
    const startConference = () => {
      try {
        console.log('trying to start a new conferance')
        api.value = new window.JitsiMeetExternalAPI(domain, options)
        console.log('api.value', api.value)
        api.value.addEventListener('videoConferenceJoined', (localUser) => {
          try {
            console.log('executing setTileView')
            api.value.executeCommand('setTileView', { enabled: true })
          } catch (e) {
            console.error('Failed to set tile view', e)
          }

          if (currentUser.value.userRole === 2) {
            api.value.executeCommand('displayName', 'Obs')
            api.value.executeCommand('email', 'sorin.puncea@teamlearning.ai')
          } else {
            api.value.executeCommand('displayName', userName.value)
            api.value.executeCommand('email', currentUser.value.employee.email)
          }
          socket.value.emit('ACTIVITY_JOINED', {
            eventType: 'ACTIVITY_JOINED',
            payload: { joinId: localUser.id }
          })
        })
        api.value.addEventListener('faceLandmarkDetected', onFaceLandMarkDetected)
        api.value.addEventListener('videoMuteStatusChanged', videoMuteStatusChangedEventListener)
      } catch (e) {
        console.error('Failed to load Jitsi API', e)
      }
    }
    watch(() => details.account, (account, oldAccount) => {
      console.log('inside account watch', account)
    })
    watch(currentUser, (n, o) => {
      console.log('currentUser in watch', n)
    })

    const isCameraOn = ref(true)
    emitter.on('handle-camera', (jitsiVisible) => {
      if (jitsiVisible && !isCameraOn.value) {
        api.value.executeCommand('toggleVideo')
      }
      if (!jitsiVisible && isCameraOn.value) {
        api.value.executeCommand('toggleVideo')
      }
    })

    emitter.on('handle-audio', () => {
      api.value.executeCommand('toggleAudio')
    })
    const videoMuteStatusChangedEventListener = (event) => {
      isCameraOn.value = !event.muted
    }
    const onFaceLandMarkDetected = (event) => {
      // todo this should gather statistics about the face and send them somehow to the api
    }
    onMounted(() => {
      // jitsiIframe!.onload = function () {
      //   alert('myframe is loaded')
      // }
      if (screen.width > 575.98) {
        showVideo.value = true
      }
      // window.addEventListener('resize', () => { screen.width > 575.98 && (this.windowHeight !== window.innerHeight || this.windowWidth !== window.innerWidth) ? this.showVideo = true : this.showVideo = false })
      // window.addEventListener('resize', () => { this.showVideo = screen.width > 575.98 && !this.showVideo })
      options.parentNode = meetContainer.value
      openRoom()
    })
    onUnmounted(() => {
      if (api.value) {
        api.value.removeEventListener('faceLandmarkDetected', onFaceLandMarkDetected)
        api.value.removeEventListener('videoMuteStatusChanged', videoMuteStatusChangedEventListener)
      }
    })

    return {
      showVideo,
      meetContainer,
      jitsiIframeLoading,
      mobileViewMode
    }
  }
})
