
import { computed, defineComponent, onBeforeUpdate, onMounted, ref } from 'vue'
import { Actions } from '@/store/enums/StoreEnums'
import { useStore } from 'vuex'
import { getApiKey } from '@/core/helpers/tinymce.ts'
import BuilderWidget from '@/views/session/BuilderWidget.vue'
import Editor from '@tinymce/tinymce-vue'
import { TabsPaneContext } from 'element-plus'
import { StatisticDataInterface } from '@/store/modules/ActiveUserModule'

export default defineComponent({
  name: 'user-debriefing',
  props: {
  },
  components: {
    BuilderWidget,
    editor: Editor
  },
  setup () {
    const store = useStore()

    const socket = computed(() => store.getters.getSocket)
    const currentAccount = computed(() => store.getters.currentActiveUser)
    const insights = computed(() => store.getters.getActivitySessionsInsights)
    const generateInsightsLoading = ref(false)
    const insightsLoaded = ref(false)
    const activeName = ref([]) as any
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const teamScore = ref(0) as any
    const solvePercentageUpdated = ref(0) as any
    const mobileViewMode = computed(() => store.getters.getMobileViewMode)

    const debriefingScoreStyle = ref({ })
    const questionScoreStyle = (number) => { return { background: number < 50 ? '#ff8686' : (number < 75 ? '#ffc764' : '#69BC92') } }
    const progressColors = [
      { color: '#DE506E', percentage: 50 },
      { color: '#F4B12E', percentage: 75 },
      { color: '#69BC92', percentage: 100 }
    ]
    onBeforeUpdate(() => {
      for (let s = 0; s < insights.value.length; s++) {
        activeName.value[s] = 'teamAnswer' + insights.value[s].originalWidget.widgetUuid
      }
      console.log(activeName.value)
    })

    const getAveragePercentage = (insights) => {
      const { length } = insights
      return parseInt(insights.reduce((insight, val) => {
        return insight + (parseInt(val.solvePercentage) / length)
      }, 0))
    }

    const onGenerateInsights = () => {
      generateInsightsLoading.value = true
      setTimeout(() => {
        generateInsightsLoading.value = false
        insightsLoaded.value = true
      }, 1500)
    }

    const editorSettings = {
      inline: true,
      menubar: false,
      toolbar: false
    }
    const handleTabClick = (pane: TabsPaneContext, ev: Event) => {
      if (pane.paneName?.includes('teamAnswer')) { // teamAnswer tab is active by default skip it from statistics
        return false
      }
      const newStatisticData = { type: 'debriefingTabsOpened', meta: { widgetUuid: pane.instance.attrs['widget-uuid'], tabType: pane.paneName } } as unknown as StatisticDataInterface

      socket.value.emit('ADD_STATISTIC_DATA', {
        eventType: 'ADD_STATISTIC_DATA',
        payload: newStatisticData
      })
    }

    onMounted(() => {
      const insightsPayload = {
        sessionUuid: currentAccount.value.sessionUuid,
        teamUuid: currentAccount.value.teamUuid
      } as any
      store.dispatch(Actions.API_GET_ACTIVITY_SESSION_INSIGHTS, insightsPayload).then((response) => {
        teamScore.value = getAveragePercentage(response.payload.insights)
        solvePercentageUpdated.value = ((getAveragePercentage(response.payload.insights) / activityTemplate.value.targetScore) * 100).toFixed(0)
        debriefingScoreStyle.value = { background: solvePercentageUpdated.value < 50 ? '#ff8686' : (solvePercentageUpdated.value < 75 ? '#ffc764' : '#69BC92') }
      }).catch((response) => {
      })
    })
    return {
      solvePercentageUpdated,
      progressColors,
      debriefingScoreStyle,
      generateInsightsLoading,
      insights,
      insightsLoaded,
      activeName,
      activityTemplate,
      questionScoreStyle,
      onGenerateInsights,
      getApiKey,
      editorSettings,
      handleTabClick,
      teamScore,
      mobileViewMode
    }
  }
})
