
import { computed, defineComponent, inject, nextTick, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
// import BuilderBottomMenu from '@/views/session/BuilderBottomMenu.vue'
import BuilderWorkspace from '@/views/session/BuilderWorkspace.vue'
// import BuilderSectionMenu from '@/views/session/BuilderSectionMenu.vue'
// import BuilderSectionMenu from '@/views/session/BuilderSectionMenu.vue'
import BuilderMobileTopMenu from '@/views/session/BuilderMobileTopMenu.vue'
import BuilderMobileBottomMenu from '@/views/session/BuilderMobileBottomMenu.vue'
// import BuilderWidgetMenu from '@/views/session/BuilderWidgetMenu.vue'
// import BuilderWidgetMenuNew from '@/views/session/BuilderWidgetMenuNew.vue'
// import BuilderJitsiMenu from '@/views/session/BuilderJitsiMenu.vue'
// import HtmlClass from '@/core/services/LayoutService'
import KTLoader from '@/components/Loader.vue'
import { Actions, Mutations, MutationsActions } from '@/store/enums/StoreEnums'
import { MenuComponent } from '@/assets/ts/components'
// import { reinitializeComponents } from '@/core/plugins/keenthemes'
import { removeModalBackdrop } from '@/core/helpers/dom'
import { loaderEnabled, loaderLogo } from '@/core/helpers/config'
import { uuid } from 'vue-uuid'
import VueGridLayout from 'vue3-grid-layout'
import { generateWidget, sectionColorClassMap } from '@/core/helpers/template/widget'
import { Widget } from '@/core/helpers/template/TemplateInterface'
import { ElNotification } from 'element-plus/es'
import { StatisticDataInterface } from '@/store/modules/ActiveUserModule'
import { buildTemplateFileUri } from '@/core/helpers/activity-template'
import { getParticipantMetadata } from '@/core/helpers/activity-team-module'
import StyledTextCard from '@/views/session/Widget/TextCardStyled.vue'
import QuestionTeamReadiness from '@/views/session/Widget/QuestionTeamReadiness.vue'
import { getTemplateImagesBySection } from '@/core/helpers/template/template'
import router from '@/router'

export default defineComponent({
  name: 'Layout',
  components: {
    KTLoader,
    // BuilderBottomMenu,
    BuilderWorkspace,
    // BuilderSectionMenu,
    // BuilderSectionMenu,
    BuilderMobileTopMenu,
    BuilderMobileBottomMenu,
    // BuilderWidgetMenu,
    // BuilderWidgetMenuNew
    // BuilderJitsiMenu,
    StyledTextCard,
    QuestionTeamReadiness
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const socket = computed(() => store.getters.getSocket)
    const emitter = inject('emitter') as any
    const team = computed(() => store.getters.getActivityTeam)
    const activitySession = computed(() => store.getters.getActivitySession)
    const teamStarted = computed(() => store.getters.getTeamStarted)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const currentAccount = computed(() => store.getters.currentActiveUser)
    const mobileViewMode = computed(() => store.getters.getMobileViewMode)
    const showLinkToWidgetModal = ref(false)
    const linkToWidget = ref()
    const usedHotspot = ref()
    const usedWidgetUuid = ref()
    const modalDefaultWidth = {
      'text-card-styled': '400px',
      'question-team-readiness': '785px'
    }
    const cursorColorMap = [
      {
        class: 'uc1',
        color: '#F4B12E'
      },
      {
        class: 'uc2',
        color: '#459EF0'
      },
      {
        class: 'uc3',
        color: '#F5564E'
      },
      {
        class: 'uc4',
        color: '#41BF8F'
      },
      {
        class: 'uc5',
        color: '#9776FF'
      },
      {
        class: 'uc6',
        color: '#FFFFFF'
      },
      {
        class: 'uc7',
        color: '#FB6FBB'
      },
      {
        class: 'uc8',
        color: '#FF33E1'
      }
    ]

    const handlePersistWidgetQuestionTeamReadiness = (payload) => {
      usedHotspot.value.modalWidget = payload.widget
      const payloadEditHotspot = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        widgetUuid: usedWidgetUuid.value,
        hotspot: usedHotspot.value
      }
      socket.value.emit('IMAGE_360_EDIT_HOTSPOT', {
        eventType: 'IMAGE_360_EDIT_HOTSPOT',
        payload: payloadEditHotspot
      })
    }
    emitter.on('exit-activity', (value) => {
      wasExitPressed.value = value
    })
    const hasParticipantAnsweredConfidence = (activeUserUuid) => {
      return store.getters.hasParticipantAnsweredConfidence(activeUserUuid)
    }

    const teamEnded = computed(() => store.getters.getTeamEnded)

    const isActivityEnded = () => {
      return (teamEnded.value && teamEnded.value.timestamp !== null)
    }

    const wasExitPressed = ref(false)

    const confidenceLevels = [
      {
        uuid: '66e79daa-f012-469d-b753-12c109a6f58c',
        label: 1
      },
      {
        uuid: '72d700ab-4051-4d66-a7d9-4022ced9bf30',
        label: 2
      },
      {
        uuid: 'a71f6d53-f8db-49be-9200-99a9a41f12c5',
        label: 3
      },
      {
        uuid: 'c7cb675e-de6c-42ac-808a-3244c51fad7a',
        label: 4
      },
      {
        uuid: '1e082ab7-6a14-4c1e-ac95-d559c38e5fad',
        label: 5
      },
      {
        uuid: 'bf11a004-11fa-44a4-8eef-798c3efa4eb5',
        label: 6
      },
      {
        uuid: '020946b8-1d74-457a-8c2a-131905f6ea73',
        label: 7
      },
      {
        uuid: '6add88f1-21ab-4c81-a5ff-509c0bc8e887',
        label: 8
      },
      {
        uuid: '195be391-bcae-4a3e-876a-efac3cdd6166',
        label: 9
      },
      {
        uuid: '788e80ed-1bf8-4991-b223-0496a8664496',
        label: 10
      }
    ]

    const selectedConfidenceLevel = ref()
    const selectedConfidence = ref()

    const onSolutionChanged = (level) => {
      selectedConfidence.value = level
      selectedConfidenceLevel.value = level.uuid
    }

    const onSolutionSubmitted = (level) => {
      socket.value.emit('SURVEY_CONFIDENCE_ANSWERED', {
        eventType: 'SURVEY_CONFIDENCE_ANSWERED',
        payload: { answer: selectedConfidence.value }
      })
      const newStatisticData = {
        type: 'confidenceAnswer',
        meta: { confidenceAnswerNumber: selectedConfidence.value.label }
      } as unknown as StatisticDataInterface

      socket.value.emit('ADD_STATISTIC_DATA', {
        eventType: 'ADD_STATISTIC_DATA',
        payload: newStatisticData
      })
    }
    const onMouseMoveEvent = (event) => {
      // setTimeout(() => {
      //   console.log('moving mouse: ', event)
      // }, 500)
    }

    // const details = reactive({ account: computed(() => store.getters.currentActiveUser) })
    // watch(() => details.account, (account, oldAccount) => {
    //   console.log('details.account in CuriositySession', account)
    //   if (account.activeUserUuid) {
    //     store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSION, currentAccount.value.sessionUuid).then((response) => {
    //       store.dispatch(MutationsActions.API_GET_MUTATE_TEMPLATE, response.payload.activityUuid)
    //     })
    //   }
    // })
    const syncTimer = () => {
      let diffInSeconds = 0
      if (team.value.startedAt !== null) {
        const startedAtDate = new Date(team.value.startedAt) // UTC formatted
        const now = new Date(new Date().toUTCString())

        const diffFromNow = ((now.getTime() - startedAtDate.getTime()) / 1000)
        // diffInSeconds = Math.round(activitySession.value.duration - diffFromNow)
        diffInSeconds = Math.round((parseInt(activityTemplate.value.challengeDuration)) - diffFromNow)

        store.dispatch(Actions.SET_START_ACTIVITY_COUNTDOWN, diffInSeconds)
      } else {
        if (typeof teamStarted.value.date !== 'undefined') {
          const startedAtDate = new Date(teamStarted.value.date)
          const now = new Date(new Date().toUTCString())

          const diffFromNow = ((now.getTime() - startedAtDate.getTime()) / 1000)

          // diffInSeconds = Math.round(activitySession.value.duration - diffFromNow)
          diffInSeconds = Math.round((parseInt(activityTemplate.value.challengeDuration)) - diffFromNow)
          // store.dispatch(Actions.SET_START_ACTIVITY_COUNTDOWN, diffInSeconds)
        } else {
          // this team did not start, calculate how much time until starting
          const startDate = new Date(activitySession.value.startDate) // UTC formatted
          const now = (new Date(new Date().toUTCString()))
          diffInSeconds = Math.round((startDate.getTime() - now.getTime()) / 1000)
          diffInSeconds = diffInSeconds + parseInt(activityTemplate.value.introDuration)
        }
      }
      store.dispatch(Actions.SET_START_ACTIVITY_COUNTDOWN, diffInSeconds)
    }

    // store.dispatch(MutationsActions.API_GET_MUTATE_TEMPLATE, 'a94fb814-95bd-4e3a-9372-f2bc4b31fd76')
    const sections = computed(() => store.getters.getSections)
    const activeSection = computed(() => store.getters.getActiveSection)
    const activeWidget = computed(() => store.getters.getActiveWidget)
    const activeSectionWidgetsLayout = computed(() => store.getters.getActiveSectionWidgetsLayout)
    const activeSectionIndex = computed(() => store.getters.getActiveSectionIndex)
    const activeWidgetIndex = computed(() => store.getters.getActiveWidgetIndex)

    const builderWorkspace = ref(null) as any
    const mousePosition = { x: 0, y: 0 }
    const dragPosition = { x: 0, y: 0, w: 0, h: 0, color: '' }
    let mouseInWorkspace = false as boolean
    let workspaceRect = {} as DOMRect
    let gridLayout = {} as VueGridLayout.GridLayout

    const drag = (widgetType) => {
      const dummyWidgetIndex = activeSectionWidgetsLayout.value.findIndex(item => item.i === 'dummy')

      // first, add a dummy widget far outside the grid
      if (dummyWidgetIndex === -1 && mouseInWorkspace) {
        const widget: Widget | null = generateWidget(widgetType, 480 * 2, 270 * 2)
        if (widget) {
          // todo remove colors for all widgets and keep it to flashcard for now
          dragPosition.color = widget.specific?.color
          widget.widgetUuid = 'dummy'
          const payload = {
            widget: widget
          }
          store.commit(Mutations.SET_TEMPLATE_ADD_WIDGET, payload)
        }
      }

      // if the dummy widget exists start dragging it for position preview
      if (dummyWidgetIndex !== -1) {
        const gridItem = builderWorkspace.value.$refs.setItemRef[dummyWidgetIndex]
        const placeholderCenterX = parseInt(gridItem.style.width.split('px')[0]) * 0.5
        const placeholderCenterY = parseInt(gridItem.style.height.split('px')[0]) * 0.5
        const x = mousePosition.x - workspaceRect.left - placeholderCenterX - (workspaceRect.x / 480)
        const y = mousePosition.y - workspaceRect.top - placeholderCenterY - (workspaceRect.y / 270)
        const newPosition = gridItem.calcXY(y, x)

        const dummyWidget = activeSectionWidgetsLayout.value[dummyWidgetIndex]

        if (mouseInWorkspace) {
          gridLayout.dragEvent(['dragstart', 'dummy', newPosition.x, newPosition.y, dummyWidget.h, dummyWidget.w])
          dragPosition.x = dummyWidget.x
          dragPosition.y = dummyWidget.y
          dragPosition.w = dummyWidget.w
          dragPosition.h = dummyWidget.h
        } else {
          gridLayout.dragEvent(['dragend', 'dummy', newPosition.x, newPosition.y, dummyWidget.h, dummyWidget.w])
          store.commit(Mutations.SET_TEMPLATE_REMOVE_WIDGET_BY_UUID, 'dummy')
        }
      }
    }

    const dragend = (widgetType) => {
      if (mouseInWorkspace) {
        // first remove the dummy widget
        gridLayout.dragEvent(['dragend', 'dummy', dragPosition.x, dragPosition.y, dragPosition.h, dragPosition.w])
        store.commit(Mutations.SET_TEMPLATE_REMOVE_WIDGET_BY_UUID, 'dummy')

        // inform use if widget could not be placed in grid
        if (dragPosition.x > gridLayout.$props.colNum || dragPosition.y > gridLayout.$props.maxRows) {
          ElNotification.warning({
            message: 'The widget is <b>too big</b>, and it can not be placed there.',
            dangerouslyUseHTMLString: true,
            customClass: 'houston-notification'
          })
          return false
        }

        // if position looks good proceed to add the real widget
        const widgetUuid = uuid.v4()
        const widget: Widget | null = generateWidget(widgetType, dragPosition.x, dragPosition.y)
        if (widget) {
          // apply the color back from dummy widget
          widget.specific.color = dragPosition.color
          const socketPayload = {
            templateUuid: activityTemplate.value.activityTemplateUuid,
            locator: {
              activeSectionIndex: activeSectionIndex.value
            },
            widget: widget
          }
          socket.value.emit('ADD_WIDGET', {
            eventType: 'ADD_WIDGET',
            payload: socketPayload
          })

          gridLayout.dragEvent(['dragend', widgetUuid, dragPosition.x, dragPosition.y, dragPosition.h, dragPosition.w])
        } else {
          // if widget was not generated it is most likely not implemented
          ElNotification.warning({
            message: 'That widget is <b>not</b> available yet, and it can not be used to build templates.',
            dangerouslyUseHTMLString: true,
            customClass: 'houston-notification'
          })
        }
        // const widgetIndex = activeSectionWidgetsLayout.value.findIndex(item => item.i === widgetUuid)
        // builderWorkspace.value.$refs.setItemRef[widgetIndex].$refs.item.style.display = 'block'
      }
    }

    const openWidgetSettings = (widgetUuid) => {
      const widgetIndex = computed(() => store.getters.getWidgetIndexByUuid(widgetUuid)).value
      store.commit(Mutations.SET_TEMPLATE_ACTIVE_WIDGET_INDEX, widgetIndex)
    }

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading')

    // initialize html element classes
    // HtmlClass.init()

    watch(activityTemplate, (newVal) => {
      for (let s = 1; s < newVal.templateJson.sections.length; s++) {
        const imagesForPreload = getTemplateImagesBySection(newVal.templateJson.sections[s], currentAccount.value.company)
        const tempImg = [] as any
        for (let x = 0; x < imagesForPreload.length; x++) {
          tempImg[x] = new Image()
          tempImg[x].src = imagesForPreload[x]
        }
      }
    })

    onMounted(() => {
      console.log('on mounted in builder layour', currentAccount)
      if (currentAccount.value) {
        // the user needs to customize the character first if needed
        if (currentAccount.value.preIntro === 1) {
          router.push({ name: 'customize-character' })
        }
      }
      emitter.on('show-link-to-widget-modal', (payload) => {
        console.log('payload link to widget', payload)
        showLinkToWidgetModal.value = true
        linkToWidget.value = payload.hotspot.modalWidget
        usedHotspot.value = payload.hotspot
        usedWidgetUuid.value = payload.widgetUuid
      })
      if (!isActivityEnded) {
        gridLayout = builderWorkspace.value.$refs.gridLayout
        workspaceRect = document.getElementById('builder-workspace')!.getBoundingClientRect()

        window.addEventListener('resize', function () {
          workspaceRect = document.getElementById('builder-workspace')!.getBoundingClientRect()
        }, false)

        document.addEventListener('dragover', function (e) {
          mousePosition.x = e.clientX
          mousePosition.y = e.clientY
          mouseInWorkspace = ((mousePosition.x > workspaceRect.left) && (mousePosition.x < workspaceRect.right)) &&
              ((mousePosition.y > workspaceRect.top) && (mousePosition.y < workspaceRect.bottom))
        }, false)
      }

      // check if current user is authenticated
      // if (!store.getters.isAccountAuthenticated) {
      //   router.push({ name: 'sign-in' })
      // }

      nextTick(() => {
        // reinitializeComponents()
      })
      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading')
      }, 500)

      setInterval(() => {
        syncTimer()
      }, 1000)
      const dateNow = new Date()
      watch(currentAccount, (newVal, oldVal) => {
        if (currentAccount.value.preIntro === 1) {
          router.push({ name: 'customize-character' })
        }
        store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSION, currentAccount.value.sessionUuid).then((response) => {
          const payload = {} as any
          payload.templateUuid = response.payload.activityUuid
          payload.teamUuid = currentAccount.value.teamUuid
          const _response = response
          store.dispatch(MutationsActions.API_GET_MUTATE_TEMPLATE_BY_TEAM, payload).then(() => {
            store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEAM, payload).then((response) => {
              // this team already started, calculate how much time remaining until finish
              console.log('activityTemplate value', activityTemplate.value)

              if (response.payload.startedAt !== null) {
                const startedAtDate = new Date(response.payload.startedAt)
                const now = new Date(new Date().toUTCString())

                const diffFromNow = ((now.getTime() - startedAtDate.getTime()) / 1000) + ((now.getTime() - dateNow.getTime()) / 1000)

                // const diffInSeconds = Math.round(_response.payload.duration - diffFromNow)
                const diffInSeconds = Math.round((parseInt(activityTemplate.value.challengeDuration)) - diffFromNow)

                store.dispatch(Actions.SET_START_ACTIVITY_COUNTDOWN, diffInSeconds)
              } else {
                if (typeof teamStarted.value.date !== 'undefined') {
                  const startedAtDate = new Date(teamStarted.value.date)
                  const now = new Date(new Date().toUTCString())

                  const diffFromNow = ((now.getTime() - startedAtDate.getTime()) / 1000) + ((now.getTime() - dateNow.getTime()) / 1000)

                  // const diffInSeconds = Math.round(_response.payload.duration - diffFromNow)
                  const diffInSeconds = Math.round((parseInt(activityTemplate.value.challengeDuration) + parseInt(activityTemplate.value.introDuration)) - diffFromNow)
                  store.dispatch(Actions.SET_START_ACTIVITY_COUNTDOWN, (diffInSeconds + parseInt(activityTemplate.value.introDuration)))
                } else {
                  // this team did not start, calculate how much time until starting
                  const startDate = new Date(_response.payload.startDate)
                  // const now = new Date(response.timestamp * 1000)
                  const now = new Date(new Date().toUTCString())

                  const diffInSeconds = ((startDate.getTime() - now.getTime()) / 1000) + ((now.getTime() - dateNow.getTime()) / 1000)

                  store.dispatch(Actions.SET_START_ACTIVITY_COUNTDOWN, diffInSeconds + parseInt(activityTemplate.value.introDuration))
                }
              }
            })
            sections.value.forEach((section, sectionIndex) => {
              if (sectionIndex === activeSectionIndex.value) {
                // needed for showing colors on the users
                store.dispatch(Actions.LOCK_RESOURCE, 'section-' + section.sectionUuid + '-user-' + currentAccount.value.activeUserUuid)
              }
            })
          })
        })
      })
      if (currentAccount.value) {
        store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSION, currentAccount.value.sessionUuid).then((response) => {
          const payload = {} as any
          payload.templateUuid = response.payload.activityUuid
          payload.teamUuid = currentAccount.value.teamUuid
          const _response = response
          store.dispatch(MutationsActions.API_GET_MUTATE_TEMPLATE_BY_TEAM, payload).then(() => {
            store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEAM, payload).then((response) => {
              // this team already started, calculate how much time remaining until finish
              console.log('activityTemplate value', activityTemplate.value)

              if (response.payload.startedAt !== null) {
                const startedAtDate = new Date(response.payload.startedAt)
                const now = new Date(new Date().toUTCString())

                const diffFromNow = ((now.getTime() - startedAtDate.getTime()) / 1000) + ((now.getTime() - dateNow.getTime()) / 1000)

                // const diffInSeconds = Math.round(_response.payload.duration - diffFromNow)
                const diffInSeconds = Math.round((parseInt(activityTemplate.value.challengeDuration)) - diffFromNow)

                store.dispatch(Actions.SET_START_ACTIVITY_COUNTDOWN, diffInSeconds)
              } else {
                if (typeof teamStarted.value.date !== 'undefined') {
                  const startedAtDate = new Date(teamStarted.value.date)
                  const now = new Date(new Date().toUTCString())

                  const diffFromNow = ((now.getTime() - startedAtDate.getTime()) / 1000) + ((now.getTime() - dateNow.getTime()) / 1000)

                  // const diffInSeconds = Math.round(_response.payload.duration - diffFromNow)
                  const diffInSeconds = Math.round((parseInt(activityTemplate.value.challengeDuration) + parseInt(activityTemplate.value.introDuration)) - diffFromNow)

                  store.dispatch(Actions.SET_START_ACTIVITY_COUNTDOWN, (diffInSeconds + parseInt(activityTemplate.value.introDuration)))
                } else {
                  // this team did not start, calculate how much time until starting
                  const startDate = new Date(_response.payload.startDate)
                  // const now = new Date(response.timestamp * 1000)
                  const now = new Date(new Date().toUTCString())

                  const diffInSeconds = ((startDate.getTime() - now.getTime()) / 1000) + ((now.getTime() - dateNow.getTime()) / 1000)

                  store.dispatch(Actions.SET_START_ACTIVITY_COUNTDOWN, diffInSeconds + parseInt(activityTemplate.value.introDuration))
                }
              }
            })
            sections.value.forEach((section, sectionIndex) => {
              if (sectionIndex === activeSectionIndex.value) {
                // needed for showing colors on the users
                store.dispatch(Actions.LOCK_RESOURCE, 'section-' + section.sectionUuid + '-user-' + currentAccount.value.activeUserUuid)
              }
            })
          })
        })
      }
    })

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined)

        // check if current user is authenticated
        // if (!store.getters.isAccountAuthenticated) {
        //   useRouter().push({ name: 'sign-in' })
        // }

        removeModalBackdrop()
        nextTick(() => {
          // reinitializeComponents()
        })
      }
    )
    const onTitleChanged = (newTitle) => {
      activityTemplate.value.title = newTitle
    }
    const sectionBackgroundStyle = computed(() => {
      const style = {}

      if (activeSection.value !== undefined && activeSection.value.backgroundImage !== undefined && activeSection.value.backgroundImage !== null) {
        const backgroundImage = buildTemplateFileUri(activeSection.value.backgroundImage.attachmentUuid, currentAccount.value.employee.company ? currentAccount.value.employee.company : currentAccount.value.company, activeSection.value.backgroundImage)
        style['background-image'] = `url(${backgroundImage})`
        style['background-repeat'] = 'no-repeat'
        style['background-size'] = 'cover'
        style['background-position'] = 'initial'
      }

      return style
    })

    return {
      loaderEnabled,
      cursorColorMap,
      loaderLogo,
      sections,
      activeSection,
      activeWidget,
      activeSectionIndex,
      activeWidgetIndex,
      dragend,
      drag,
      openWidgetSettings,
      builderWorkspace,
      activityTemplate,
      onTitleChanged,
      onMouseMoveEvent,
      teamEnded,
      onSolutionChanged,
      confidenceLevels,
      selectedConfidenceLevel,
      hasParticipantAnsweredConfidence,
      isActivityEnded,
      currentAccount,
      onSolutionSubmitted,
      wasExitPressed,
      mobileViewMode,
      sectionColorClassMap,
      sectionBackgroundStyle,
      getParticipantMetadata,
      showLinkToWidgetModal,
      linkToWidget,
      usedHotspot,
      usedWidgetUuid,
      modalDefaultWidth,
      handlePersistWidgetQuestionTeamReadiness
    }
  }
})
