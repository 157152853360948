
import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { getParticipantMetadata } from '@/core/helpers/activity-team-module'
import { StatisticDataInterface } from '@/store/modules/ActiveUserModule'
import { MenuComponent } from '@/assets/ts/components'
import { ElNotification } from 'element-plus/es'
import { userColorMap } from '@/core/helpers/template/template'
import { useI18n } from 'vue-i18n'
import UserBubble from '@/components/Builder/UserBubble/UserBubble.vue'
import SessionStorage from '@/core/services/SessionStorage'

export default defineComponent({
  name: 'builder-section-menu',
  components: { UserBubble },
  inject: ['t'],
  setup () {
    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const sections = computed(() => store.getters.getSections)
    const activeSectionIndex = computed(() => store.getters.getActiveSectionIndex)
    const currentAccount = computed(() => store.getters.currentActiveUser)
    const teamEnded = computed(() => store.getters.getTeamEnded)
    const { t } = useI18n()
    const isActivityEnded = () => {
      return (teamEnded.value && teamEnded.value.timestamp !== null)
    }
    const isActivityStarted = () => {
      return (teamStarted.value && teamStarted.value.timestamp !== null)
    }
    const onEndActivityClicked = () => {
      emitter.emit('confirm-end-modal', true)
    }
    const teamStarted = computed(() => store.getters.getTeamStarted)
    const startCountdown = ref(0) as any
    const startActivityCountdown = computed(() => store.getters.getStartActivityCountdown)
    const hasUnsolvedActionWidgets = ref(false)

    let countDown = 0
    watch(startActivityCountdown, (n, o) => {
      if (o !== null) {
        clearTimeout(countDown)
      }
      const parsedN = parseInt(n)
      if (!isNaN(parsedN) && parsedN >= 0) {
        startCountdown.value = parsedN
        if (startCountdown.value <= 0) {
          startCountdown.value = 0
          // Handle the countdown reaching 0 here
          return
        }
        countDownTimer(parsedN)
      } else {
        // Handle invalid or NaN input (e.g., show an error message)
      }
    })
    const countDownTimer = (value) => {
      if (startCountdown.value <= 0) {
        // Handle the countdown reaching 0 here (e.g., show a message)
        return
      }
      countDown = setTimeout(() => {
        startCountdown.value = parseInt(startCountdown.value) - 1
        countDownTimer(startCountdown.value)
      }, 1000)
    }
    watch(() => activityTemplate, (currentValue, oldValue) => {
      if (currentValue.value !== 'undefined') {
        hasUnsolvedActionWidgets.value = false
        currentValue.value.templateJson.sections.every((section) => {
          return section.widgets.every((widget) => {
            if (widget.category === 'action') {
              switch (widget.type) {
                case 'drag-and-drop-group':
                case 'drag-and-drop-order':
                  return widget.specific.solution.every((solution) => {
                    if (solution.itemList.length === 0) {
                      hasUnsolvedActionWidgets.value = true
                      return false
                    }
                    return true
                  })
                case 'question-single-choice':
                  if ((typeof widget.specific.solution === 'string' && widget.specific.solution.length === 0) || widget.specific.solution === null) {
                    hasUnsolvedActionWidgets.value = true
                    return false
                  }
                  return true
                case 'question-team-readiness':
                  if ((typeof widget.specific.solution === 'string' && widget.specific.solution.length === 0) || widget.specific.solution === null) {
                    hasUnsolvedActionWidgets.value = true
                    return false
                  }
                  return true
                case 'question-multiple-choice':
                  if (widget.specific.solution.length === 0) {
                    hasUnsolvedActionWidgets.value = true
                    return false
                  }
                  return true
                case 'question-short-answer':
                  if ((typeof widget.specific.solution.answer === 'string' && widget.specific.solution.answer.length === 0) || widget.specific.solution.answer === null) {
                    hasUnsolvedActionWidgets.value = true
                    return false
                  }
                  return true
                default:
                  return true
              }
            }
            return true
          })
        })
      }
    },
    { deep: true }
    )
    const convertHMS = (value) => {
      const sec = parseInt(value, 10) // convert value to number if it's string
      let hours = Math.floor(sec / 3600) as any // get hours
      let minutes = Math.floor((sec - (hours * 3600)) / 60) as any// get minutes
      let seconds = sec - (hours * 3600) - (minutes * 60) as any //  get seconds
      // add 0 if value < 10; Example: 2 => 02
      if (hours < 10) { hours = '0' + hours }
      if (minutes < 10) { minutes = '0' + minutes }
      if (seconds < 10) { seconds = '0' + seconds }
      return minutes + ':' + seconds // Return is HH : MM : SS
    }

    const sectionSliderStyle = ref({ left: '4px' })

    watch(activeSectionIndex, (newVal, oldVal) => {
      const newSectionIndex = newVal !== -1 ? newVal : sections.value.length
      const oldSectionIndex = oldVal !== -1 ? oldVal : sections.value.length
      const movingToRight = newSectionIndex > oldSectionIndex
      recalculateActiveSectionSliderPosition(newSectionIndex, movingToRight)
      const lastSectionClicked = SessionStorage.getItem('lastSectionClicked') as any
      if (parseInt(lastSectionClicked) !== parseInt(newVal)) {
        SessionStorage.saveItem('lastSectionClicked', newVal)
        SessionStorage.saveItem('lastSectionOpenedAt', new Date())
      }
    })

    onMounted(() => {
      if (activeSectionIndex.value === -1) {
        console.log('Debriefing is selected')
        const sectionIndex = sections.value.length
        recalculateActiveSectionSliderPosition(sectionIndex, true)
      }
      SessionStorage.saveItem('lastSectionClicked', 0)
      SessionStorage.saveItem('lastSectionOpenedAt', new Date())
    })

    const recalculateActiveSectionSliderPosition = (currentSectionIndex, toRight) => {
      const goAheadABit = toRight ? 4 : -4
      const calculateLeft = (0 + (currentSectionIndex * 180) + (currentSectionIndex * 10) + goAheadABit) + 'px'
      sectionSliderStyle.value = { left: calculateLeft }
      setTimeout(() => {
        const calculateLeft = (0 + (currentSectionIndex * 180)) + (currentSectionIndex * 10) + 'px'
        sectionSliderStyle.value = { left: calculateLeft }
      }, 400)
    }

    // const sectionUserResources = (sectionUuid) => {
    //   return computed(() => store.getters.getSectionUserResources(sectionUuid)).value
    // }
    const sectionUserResources = (sectionUuid) => {
      return computed(() => store.getters.getSectionUserResources(sectionUuid))
        .value
        .filter(userResource => {
          const participantMetadata = getParticipantMetadata(userResource.clientId)
          return participantMetadata.userRole !== 2
        })
    }

    const handleSectionClick = (clickedSection) => {
      if (currentAccount.value.userRole !== 2 && ((clickedSection !== -1 && !clickedSection.settings.enabled) || (clickedSection === -1 && !isActivityEnded()))) {
        return
      }
      if (currentAccount.value.userRole !== 2 && (clickedSection !== -1 && clickedSection.settings.enabled && clickedSection.settings.locked)) {
        return
      }
      const getSectionIsLockedByResource = computed(() => store.getters.getSectionIsLockedByResource(clickedSection.sectionUuid)).value
      if (currentAccount.value.userRole !== 2 && (clickedSection !== -1 && getSectionIsLockedByResource)) {
        return
      }

      // unlock the other sections
      sections.value.forEach((section) => {
        if (clickedSection.sectionUuid !== section.sectionUuid) {
          store.dispatch(Actions.UNLOCK_RESOURCE, 'section-' + section.sectionUuid + '-user-' + currentAccount.value.activeUserUuid)
        }
      })
      store.dispatch(Actions.UNLOCK_RESOURCE, 'section-' + 'debriefing' + '-user-' + currentAccount.value.activeUserUuid)// this is also unlocked to cope with briefing not being a real section
      if (clickedSection === -1) {
        store.dispatch(Actions.LOCK_RESOURCE, 'section-' + 'debriefing' + '-user-' + currentAccount.value.activeUserUuid)
      } else {
        store.dispatch(Actions.LOCK_RESOURCE, 'section-' + clickedSection.sectionUuid + '-user-' + currentAccount.value.activeUserUuid)
      }
    }
    const emitter = inject('emitter') as any

    const sectionChange = (sectionIndex, isDebriefing = false, section) => {
      // coach can navigate through every section. exception is debriefing which is available after the activity is ended
      if (currentAccount.value.userRole === 4 || currentAccount.value.userRole === 2) {
        if (!isActivityEnded() && sectionIndex === -1) { // but he is not allowed to tap into debriefing until activity is ended
          return
        }
        store.commit(Mutations.SET_TEMPLATE_ACTIVE_SECTION_INDEX, sectionIndex)
        setTimeout(() => { // reinitialize sections options menu
          MenuComponent.reinitialization()
        }, 100)
        return
      }

      if (section && !section.settings.enabled && section.settings.locked) {
        const payload = {} as any
        payload.sectionToUnlock = section
        payload.sectionIndexToUnlock = sectionIndex
        emitter.emit('unlock-section', payload)
        return
      }

      //  users can navigate through other sections after the section is enabled
      const getSectionIsLockedByResource = isDebriefing ? false : computed(() => store.getters.getSectionIsLockedByResource(section.sectionUuid)).value
      if ((section && !section.settings.enabled) || (sectionIndex === -1 && !isActivityEnded()) || getSectionIsLockedByResource) {
        let message

        if (sectionIndex === -1) {
          message = t('section.results.locked.end.activity')
        }

        if (!isActivityStarted()) {
          message = t('section.locked.start.activity').replace('%section_placeholder%', section.title)
        }

        if (getSectionIsLockedByResource) {
          message = t('section.locked.by.resource')
        }

        ElNotification.warning({
          message: message,
          dangerouslyUseHTMLString: true,
          customClass: 'teamlearn-notification'
        })
        return
      }
      const lastSectionClicked = SessionStorage.getItem('lastSectionClicked') as any
      if (sectionIndex !== -1) { // do not care about debriefing section
        const loggedInAt = SessionStorage.getItem('loggedInAt')
        const lastSectionOpenedAt = SessionStorage.getItem('lastSectionOpenedAt')
        let comparedWith = lastSectionOpenedAt as any
        if (!lastSectionOpenedAt) {
          comparedWith = loggedInAt
        }

        const t1 = new Date(comparedWith)
        const t2 = new Date() // now

        const diffInSeconds = ((t2.getTime() - t1.getTime()) / 1000) as any

        const previousSection = JSON.parse(JSON.stringify(sections.value)).find((currentSectionIndex, section) => section === parseInt(lastSectionClicked))
        let newStatisticData = {
          type: 'sectionsOpened',
          meta: { sectionUuid: section.sectionUuid }
        } as unknown as StatisticDataInterface
        if (previousSection) {
          newStatisticData = {
            type: 'sectionsOpened',
            meta: { sectionUuid: section.sectionUuid, previousSection: { sectionUuid: previousSection.sectionUuid, activeSeconds: parseInt(diffInSeconds) } }
          } as unknown as StatisticDataInterface
        }

        socket.value.emit('ADD_STATISTIC_DATA', {
          eventType: 'ADD_STATISTIC_DATA',
          payload: newStatisticData
        })
      }

      if (isDebriefing) {
        store.commit(Mutations.SET_TEMPLATE_ACTIVE_SECTION_INDEX, -1)
      } else {
        store.commit(Mutations.SET_TEMPLATE_ACTIVE_SECTION_INDEX, sectionIndex)
      }
      setTimeout(() => { // reinitialize sections options menu
        MenuComponent.reinitialization()
      }, 100)
    }

    const displayUnlockAnimation = (section, sectionIndex) => {
      return computed(() => {
        let isSectionClicked = false
        let clickedSections = SessionStorage.getItem('clickedSection-template-' + activityTemplate.value.activityTemplateUuid)
        if (clickedSections !== null) {
          clickedSections = JSON.parse(clickedSections)
          // @ts-ignore
          isSectionClicked = clickedSections.some(item => item.sectionIndex === sectionIndex)
        }
        return section.settings?.enabled && !isSectionClicked && sectionIndex !== 0
      }).value
    }

    const getFullName = (userResource) => {
      let fullName = getParticipantMetadata(userResource.clientId).fullName
      if (currentAccount.value.activeUserUuid === userResource.clientId) {
        fullName = fullName + ' (me)'
      }
      return fullName
    }

    const getEmail = (userResource) => {
      return getParticipantMetadata(userResource.clientId).email
    }

    const getUserColor = (userResource) => {
      if (getParticipantMetadata(userResource.clientId).preIntro) {
        return getParticipantMetadata(userResource.clientId).preIntro.color
      }
      return userColorMap[getParticipantMetadata(userResource.clientId).userColorLabel]
    }

    const displaySectionLock = (section) => {
      const getSectionIsLockedByResource = computed(() => store.getters.getSectionIsLockedByResource(section.sectionUuid))
      return (section.settings && !section.settings.enabled) || getSectionIsLockedByResource.value
    }

    const displaySectionPasswordLock = (section) => {
      return section.settings && section.settings.enabled && section.settings.locked
    }

    return {
      sections,
      activeSectionIndex,
      activityTemplate,
      currentAccount,
      sectionSliderStyle,
      teamStarted,
      startCountdown,
      handleSectionClick,
      sectionChange,
      sectionUserResources,
      getParticipantMetadata,
      isActivityEnded,
      getFullName,
      getEmail,
      getUserColor,
      displaySectionLock,
      displaySectionPasswordLock,
      isActivityStarted,
      convertHMS,
      displayUnlockAnimation,
      hasUnsolvedActionWidgets,
      onEndActivityClicked
    }
  }
})
