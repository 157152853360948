
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { getApiKey } from '@/core/helpers/tinymce'
import { QuestionSingleChoice, QuestionTeamReadiness } from '@/core/helpers/template/WidgetInterface'
import ContentEditable from '@/components/Builder/ContentEditable/ContentEditable.vue'
// import { Mutations } from '@/store/enums/StoreEnums'
// import { buildWidgetLocator } from '@/core/helpers/template/template'

export default defineComponent({
  name: 'question-team-readiness',
  emits: ['open-widget-settings', 'persist-widget'],
  props: {
    widget: { type: Object as () => QuestionTeamReadiness, required: true },
    parentWidgetUuid: { type: String, required: false },
    readonly: { type: Boolean, default: false }
  },
  components: {
    ContentEditable
  },
  setup (props, { emit }) {
    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    // const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const currentWidget = ref(props.widget as QuestionSingleChoice) as any
    const parentWidgetUuid = ref(props.parentWidgetUuid) as any
    const question = ref(currentWidget.value.specific.question)
    const currentAccount = computed(() => store.getters.currentActiveUser)
    const mobileViewMode = computed(() => store.getters.getMobileViewMode)
    const totalConnectedParticipantsCount = computed(() => store.getters.totalConnectedParticipantsCount)
    const checkReadiness = computed(() => store.getters.checkReadiness)
    const checkReadinessAcceptedBy = computed(() => store.getters.checkReadinessAcceptedBy(currentWidget.value.widgetUuid))
    const checkReadinessDeclinedBy = computed(() => store.getters.checkReadinessDeclinedBy(currentWidget.value.widgetUuid))
    const checkReadinessRequestedBy = computed(() => store.getters.checkReadinessRequestedBy(currentWidget.value.widgetUuid))
    const checkReadinessVoteInProgress = computed(() => store.getters.checkReadinessVoteInProgress(currentWidget.value.widgetUuid))
    const pendingModalVisible = ref(false)
    const confirmModalVisible = ref(false)
    const confirmStartModalVisible = ref(false)
    // neede because it somehow does not change the solution if using just the store (commit)
    socket.value.on('IMAGE_360_HOTSPOT_EDITED', (eventData) => {
      if (eventData.hotspot.modalWidget.widgetUuid !== props.widget.widgetUuid) {
        return
      }
      currentWidget.value = eventData.hotspot.modalWidget
    })
    socket.value.on('WIDGET_EDITED', (eventData) => {
      if (eventData.content.widget.widgetUuid !== props.widget.widgetUuid) {
        return
      }
      currentWidget.value = eventData.content.widget
    })
    socket.value.on('WIDGET_TEAM_READINESS_CHECKED', (eventData) => {
      if (eventData.content.widget.widgetUuid !== props.widget.widgetUuid) {
        return
      }
      currentWidget.value = eventData.content.widget
    })
    const persistWidget = () => {
      if (props.readonly || currentWidget.value.specific.solved) return
      console.log('Persisting widget...')

      emit('persist-widget', { widget: currentWidget.value })
    }

    const onSolutionChanged = (answerUuid) => {
      if (props.readonly || currentWidget.value.specific.solved || checkReadinessVoteInProgress.value) return
      currentWidget.value.specific.solution = answerUuid
      persistWidget()
    }

    const calculateWidgetPoints = () => {
      const availableTries = currentWidget.value.specific.answersList.length - 1
      const pointsPerAnswer = Math.ceil(100 / availableTries)
      const solutionTries = currentWidget.value.specific.solved ? currentWidget.value.specific.solutionTries - 1 : currentWidget.value.specific.solutionTries
      let remainingPoints = 100 - (pointsPerAnswer * solutionTries)
      remainingPoints = remainingPoints < 0 ? 0 : remainingPoints
      return remainingPoints
    }

    const onBeginReadinessCheck = () => {
      if (currentWidget.value.specific.solution === null) {
        return
      }
      confirmModalVisible.value = true
    }

    const onConfirmBeginReadinessCheck = () => {
      confirmModalVisible.value = false
      socket.value.emit('REQUEST_CHECK_READINESS', {
        eventType: 'REQUEST_CHECK_READINESS',
        payload: { resourceUuid: currentWidget.value.widgetUuid, parentWidgetUuid: parentWidgetUuid.value }
      })
    }

    const onDeclineReadinessCheck = () => {
      confirmStartModalVisible.value = false
      confirmModalVisible.value = false
      pendingModalVisible.value = false
      socket.value.emit('DECLINE_CHECK_READINESS', {
        eventType: 'DECLINE_CHECK_READINESS',
        payload: { resourceUuid: currentWidget.value.widgetUuid }
      })
    }

    const onAcceptReadinessCheck = () => {
      socket.value.emit('ACCEPT_CHECK_READINESS', {
        eventType: 'ACCEPT_CHECK_READINESS',
        payload: { resourceUuid: currentWidget.value.widgetUuid, parentWidgetUuid: props.parentWidgetUuid }
      })
      confirmModalVisible.value = false
      pendingModalVisible.value = true
    }

    watch(checkReadinessAcceptedBy, (n, o) => {
      if (n.length === o.length) return
      if (n.length >= totalConnectedParticipantsCount.value) {
        confirmStartModalVisible.value = false
        confirmModalVisible.value = false
        pendingModalVisible.value = false
      } else {
        const foundItself = n.some(account => {
          return account.activeUserUuid === currentAccount.value.activeUserUuid
        })

        if (foundItself) {
          confirmStartModalVisible.value = false
          pendingModalVisible.value = true
        } else {
          confirmStartModalVisible.value = true
          pendingModalVisible.value = false
        }

        if (n.length === 0) {
          pendingModalVisible.value = false
          confirmStartModalVisible.value = false
        }
      }
    })

    const showDeclinedBanner = computed(() => {
      return checkReadinessDeclinedBy.value.activeUserUuid !== undefined && !(confirmModalVisible.value || pendingModalVisible.value || confirmStartModalVisible.value)
    })

    const showAnswerIsWrong = computed(() => {
      return (currentWidget.value.specific.solutionTries > 0) && !currentWidget.value.specific.solved
    })

    const showAnswerIsRight = computed(() => {
      return (currentWidget.value.specific.solutionTries > 0) && currentWidget.value.specific.solved
    })

    const showDefaultBanner = computed(() => {
      return !showDeclinedBanner.value && !showAnswerIsWrong.value && !showAnswerIsRight.value
    })

    watch(props, () => {
      console.log('watching currentWidget', props)
      currentWidget.value = props.widget
      parentWidgetUuid.value = props.parentWidgetUuid
    }, { deep: true })

    onMounted(() => {
      if (checkReadinessVoteInProgress.value) {
        const foundItself = checkReadinessAcceptedBy.value.some(account => {
          return account.activeUserUuid === currentAccount.value.activeUserUuid
        })

        if (foundItself) {
          confirmStartModalVisible.value = false
          pendingModalVisible.value = true
        } else {
          confirmStartModalVisible.value = true
          pendingModalVisible.value = false
        }

        if (checkReadinessAcceptedBy.value.length === 0) {
          pendingModalVisible.value = false
          confirmStartModalVisible.value = false
        }
      }
    })

    return {
      currentWidget,
      question,
      getApiKey,
      persistWidget,
      onSolutionChanged,
      onBeginReadinessCheck,
      calculateWidgetPoints,
      mobileViewMode,
      checkReadinessAcceptedBy,
      totalConnectedParticipantsCount,
      pendingModalVisible,
      confirmModalVisible,
      confirmStartModalVisible,
      checkReadinessRequestedBy,
      onConfirmBeginReadinessCheck,
      onDeclineReadinessCheck,
      onAcceptReadinessCheck,
      checkReadinessVoteInProgress,
      currentAccount,
      checkReadiness,
      checkReadinessDeclinedBy,
      showDeclinedBanner,
      showAnswerIsWrong,
      showAnswerIsRight,
      showDefaultBanner
    }
  }
})
