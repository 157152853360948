
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { ImageCard } from '@/core/helpers/template/WidgetInterface.ts'
import { buildTemplateFileUrl, buildTemplateFileUri } from '@/core/helpers/activity-template'

export default defineComponent({
  name: 'image-card',
  props: {
    widget: { type: Object as () => ImageCard, required: true }
  },
  setup () {
    const store = useStore()
    const currentAccount = computed(() => store.getters.currentActiveUser)

    return {
      currentAccount,
      buildTemplateFileUrl,
      buildTemplateFileUri
    }
  }
})
