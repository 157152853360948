<template>
    <div>

        <select v-model="selectedLocale" @change="changeLocale" style="border-width: inherit; outline: none; font-weight: 600; background: #1a1c30;color: white;padding-left: 10px;">
            <option v-for="locale in locales" :value="locale" :selected="selectedLocale === locale" :key="locale">{{ locale.toUpperCase() }}
            </option>
        </select>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { onMounted, watch, ref } from 'vue'

export default {
  setup () {
    const { locale, availableLocales } = useI18n({ useScope: 'global' })

    const locales = availableLocales
    const selectedLocale = ref(locale.value)

    const changeLocale = (event) => {
      locale.value = event.target.value
    }

    watch(locale, (t, b) => {
      console.log('locale changed', t, b)
    })
    onMounted(() => {
      // selectedLocale.value = locale.value
      console.log('available locales', availableLocales, locale, selectedLocale)
      selectedLocale.value = locale.value
    })

    return {
      locales,
      selectedLocale,
      changeLocale
    }
  }
}
</script>
