
import { computed, defineComponent, inject, ref } from 'vue'
import { useStore } from 'vuex'
import LanguageSelectorMobile from '@/views/session/LanguageSelectorMobile.vue'
// import JitsiMeetComposition from '@/views/jitsi/JitsiMeetComposition.vue'

export default defineComponent({
  name: 'builder-mobile-bottom-menu',
  components: {
    LanguageSelectorMobile
    // JitsiMeetComposition
  },
  setup () {
    const activityTemplate = computed(() => useStore().getters.getActivityTemplate)
    const jitsiVisible = ref(false)
    const emitter = inject('emitter') as any
    const handleCamera = () => {
      emitter.emit('handle-camera', !jitsiVisible.value)
    }
    const handleJitsiVisible = () => {
      emitter.emit('handle-jitsi-visible', !jitsiVisible.value)
    }

    return {
      activityTemplate,
      jitsiVisible,
      handleCamera,
      handleJitsiVisible
    }
  }
})
