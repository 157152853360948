
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { FileUpload } from '@/core/helpers/template/WidgetInterface.ts'
import { buildWidgetLocator } from '@/core/helpers/template/template'
import { buildTemplateFileUrl, buildTemplateFileUriForDownload } from '@/core/helpers/activity-template'

export default defineComponent({
  name: 'image-card',
  props: {
    widget: { type: Object as () => FileUpload, required: true },
    readonly: { type: Boolean }
  },
  setup (props) {
    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const solvingMode = computed(() => store.getters.getSolvingMode)
    const currentWidget = ref(props.widget as FileUpload) as any
    const fileMapping: { [key: string]: any } = {
      ppt: {
        icon: 'la-file-powerpoint'
      },
      pptx: {
        icon: 'la-file-powerpoint'
      },
      pdf: {
        icon: 'la-file-pdf'
      },
      doc: {
        icon: 'la-file-word'
      },
      docx: {
        icon: 'la-file-word'
      },
      xls: {
        icon: 'la-file-excel'
      },
      xlsx: {
        icon: 'la-file-excel'
      }
    }

    const uploadedFilesForWidget = computed(() => store.getters.getUploadedFilesForFileUploadWidget(props.widget.widgetUuid))
    // const currentAccount = computed(() => store.getters.currentAccount)
    const currentAccount = computed(() => store.getters.currentActiveUser)

    // const currentCompany = computed(() => store.getters.getCompany)

    console.log('uploadedFilesForWidget', uploadedFilesForWidget.value)
    const persistWidget = () => {
      console.log('Persisting widget...')
      const locatorInstance = buildWidgetLocator(activityTemplate, currentWidget.value.widgetUuid)
      const currentWidgetInstance = currentWidget.value
      const socketPayload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        locator: locatorInstance,
        widget: currentWidgetInstance
      }
      socket.value.emit('EDIT_WIDGET', {
        eventType: 'EDIT_WIDGET',
        payload: socketPayload
      })
    }

    const onFileUploaded = (event) => {
      console.log('name :', event.target.files[0].name)
      console.log('type :', event.target.files[0].type)
      console.log('size :', event.target.files[0].size)
      console.log('last modified :', event.target.files[0].lastModified)
      console.log('file.value :', event.target.files[0])

      const data = new FormData()
      data.append('file', event.target.files[0])
    }

    const onFileRemoved = (fileUuid) => {
      console.log('Removing file:', fileUuid)
    }

    const onFileDownload = (attachmentUuid, companyLabel) => {
      window.open(buildTemplateFileUriForDownload(attachmentUuid, currentAccount.value.employee.company ? currentAccount.value.employee.company : currentAccount.value.company), '_blank')
      window.focus()
    }

    return {
      solvingMode,
      activityTemplate,
      uploadedFilesForWidget,
      fileMapping,
      currentAccount,
      onFileRemoved,
      onFileDownload,
      persistWidget,
      onFileUploaded,
      buildTemplateFileUrl
    }
  }
})
