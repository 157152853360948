import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4eb5b8df"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-editable-wrapper" }
const _hoisted_2 = ["contentEditable", "data-placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "contentRef",
      class: _normalizeClass([{
            'edit-mode no-drag': _ctx.isContentEditable,
            'content-editable-highlight': $props.highlight,
            'show-placeholder': _ctx.showPlaceholder,
            'disabled': $props.disabled
          }, "content-editable"]),
      contentEditable: _ctx.isContentEditable,
      "data-placeholder": $props.placeholder,
      onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isContentEditable = false)),
      onDblclick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleDoubleClick($event))),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleClick($event))),
      onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.contentUpdated($event))),
      onKeydown: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleKeyDown($event)))
    }, null, 42, _hoisted_2)
  ]))
}