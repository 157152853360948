import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "widget-body" }
const _hoisted_2 = { class: "widget-body-content" }
const _hoisted_3 = {
  ref: "content",
  class: "code-content no-drag"
}
const _hoisted_4 = { class: "toolbar no-drag" }
const _hoisted_5 = {
  class: "badge houston-badge-steel",
  type: "button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_codemirror = _resolveComponent("codemirror")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_codemirror, {
          modelValue: _ctx.currentWidget.specific.code,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentWidget.specific.code) = $event)),
          placeholder: "Code goes here...",
          style: { height: '400px' },
          autofocus: false,
          "indent-with-tab": true,
          "tab-size": 2,
          extensions: _ctx.extensions,
          disabled: true,
          onReady: _ctx.handleReady
        }, null, 8, ["modelValue", "extensions", "onReady"])
      ], 512),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.currentWidget.specific.language), 1)
      ])
    ])
  ]))
}