
import { computed, defineComponent, onMounted } from 'vue'
import { WidgetPosition } from '@/core/helpers/template/TemplateInterface'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import BuilderWidgetHotspot from '@/views/session/BuilderWidgetHotspot.vue'
import Code from '@/views/session/Widget/Code.vue'
import TextCardStyled from '@/views/session/Widget/TextCardStyled.vue'
import Shape from '@/views/session/Widget/Shape.vue'
import FileUpload from '@/views/session/Widget/FileUpload.vue'
import Flashcard from '@/views/session/Widget/Flashcard.vue'
import Heading from '@/views/session/Widget/Heading.vue'
import ImageCard from '@/views/session/Widget/ImageCard.vue'
import VideoEmbedded from '@/views/session/Widget/VideoEmbedded.vue'
import VideoEmbeddedStyled from '@/views/session/Widget/VideoEmbeddedStyled.vue'
import QuestionShortAnswer from '@/views/session/Widget/QuestionShortAnswer.vue'
import QuestionSingleChoice from '@/views/session/Widget/QuestionSingleChoice.vue'
import QuestionMultipleChoice from '@/views/session/Widget/QuestionMultipleChoice.vue'
import QuestionTeamReadiness from '@/views/session/Widget/QuestionTeamReadiness.vue'
import DragAndDropGroup from '@/views/session/Widget/DragAndDropGroup.vue'
import DragAndDropOrder from '@/views/session/Widget/DragAndDropOrder.vue'
import WidgetFlip from '@/views/session/Widget/WidgetFlip.vue'
import IconCard from '@/views/session/Widget/IconCard.vue'
import ReflectionPostIt from '@/views/session/Widget/ReflectionWidget/ReflectionPostIt.vue'
import QuestionShortAnswerAiScored from '@/views/session/Widget/QuestionShortAnswerAiScored.vue'
import store from '@/store'
import { colorClassMap, hotspotPositionClassMap } from '@/core/helpers/template/widget'
import {
  getQuestionShortAnswerSolutionHistory
} from '@/core/helpers/template/widgetSolutionHistory'
import { getParticipantMetadata } from '@/core/helpers/activity-team-module'
import IframeEmbedded from '@/views/session/Widget/Iframe/IframeEmbedded.vue'
import ImageView360 from '@/views/session/Widget/ImageView360/ImageView360.vue'
import { buildWidgetLocator, userColorMap } from '@/core/helpers/template/template'
import UserBubble from '@/components/Builder/UserBubble/UserBubble.vue'
export default defineComponent({
  name: 'builder-widget',
  emits: ['open-widget-settings'],
  props: {
    widgetUuid: { type: String, required: true },
    widgetPosition: { type: Object as () => WidgetPosition, required: false },
    ignoreDiscovery: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    fromOriginalTemplate: { type: Boolean, default: false }
  },
  components: {
    UserBubble,
    ImageView360,
    IframeEmbedded,
    BuilderWidgetHotspot,
    IconCard,
    Code,
    TextCardStyled,
    Shape,
    FileUpload,
    Flashcard,
    Heading,
    ImageCard,
    VideoEmbedded,
    VideoEmbeddedStyled,
    QuestionShortAnswer,
    QuestionSingleChoice,
    QuestionMultipleChoice,
    DragAndDropGroup,
    DragAndDropOrder,
    WidgetFlip,
    QuestionShortAnswerAiScored,
    QuestionTeamReadiness,
    ReflectionPostIt
  },
  setup (props) {
    const widget = computed(() => store.getters.getWidgetByUuid(props.widgetUuid, props.fromOriginalTemplate))
    const solvingMode = computed(() => store.getters.getSolvingMode)
    const getExpandedHotspotWidgets = computed(() => store.getters.getExpandedHotspotWidgets)
    const widgetsMobilePosition = computed(() => store.getters.getActiveSectionWidgetsMobilePosition)
    const mobileViewMode = computed(() => store.getters.getMobileViewMode)
    const widgetIndex = computed(() => store.getters.getWidgetIndexByUuid(props.widgetUuid))
    const activeSectionIndex = computed(() => store.getters.getActiveSectionIndex)
    const socket = computed(() => store.getters.getSocket)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)

    const currentComponent = computed(() => {
      // Split the widget type into words, capitalize the first letter of each word, and join them together to get component name
      return widget.value.type.split('-').map(word => word[0].toUpperCase() + word.slice(1)).join('')
    })

    const borderedStyle = (widgetUuid) => {
      const isResourceLocked = computed(() =>
        store.getters.isResourceLocked(widgetUuid)
      ).value

      let color = 'grey'
      if (isResourceLocked) {
        if (getParticipantMetadata(getLockedResource(widgetUuid).clientId).preIntro) {
          color = getParticipantMetadata(getLockedResource(widgetUuid).clientId).preIntro.color
        } else {
          const labelMapping = {
            uc1: '#F4B12E',
            uc2: '#459EF0',
            uc3: '#F5564E',
            uc4: '#41BF8F',
            uc5: '#9776FF',
            uc6: '#FFFFFF',
            uc7: '#FB6FBB'
          }
          color = labelMapping[getParticipantMetadata(getLockedResource(widgetUuid).clientId).userColorLabel]
        }
      }

      return isResourceLocked ? '1px solid ' + color : '0px solid grey'
    }

    const removableLocks = [] as any

    onMounted(() => {
    //   store
      console.log('mounted')
    })
    const focusWidget = () => {
      console.log('focuse widget')
      // store.commit(Mutations.SET_TEMPLATE_ACTIVE_WIDGET_INDEX, props.widgetIndex)
    }

    const onMouseDownHandler = (event, resourceUuid) => {
      console.log('on mouse down handler')
      if (event.button !== 0) {
        // right click not allowed
        return
      }
      store.dispatch(Actions.LOCK_RESOURCE, resourceUuid).then(() => {
        removableLocks.push(resourceUuid)
        // fail-safe unlock mechanism after couple of seconds to prevent locking of a resource indefinitely
        setTimeout(() => {
          if (removableLocks.includes(resourceUuid)) {
            store.dispatch(Actions.UNLOCK_RESOURCE, resourceUuid)
          }
        }, 15000) // 15 seconds timeout
      })
    }

    const onMouseUpHandler = (event, resourceUuid) => {
      console.log('on mouse up handler')
      // eslint-disable-next-line no-unreachable
      if (event.button !== 0) {
        // right click not allowed
        return
      }
      store.dispatch(Actions.UNLOCK_RESOURCE, resourceUuid)
    }

    /**
       * Checks if the unique resource id is locked or not
       * @param resourceUuid The unique resource string
       */
    const isResourceLocked = (resourceUuid): boolean => {
      return computed(() => store.getters.isResourceLocked(resourceUuid)).value
    }
    /**
       * Retrieves the locked resource only if its indeed locked, otherwise it returns undefined
       * @param resourceUuid The unique resource string
       */
    const getLockedResource = (resourceUuid): any => {
      return computed(() => store.getters.getLockedResource(resourceUuid)).value
    }

    const getUserColor = (userUuid) => {
      if (getParticipantMetadata(userUuid).preIntro) {
        return getParticipantMetadata(userUuid).preIntro.color
      }
      return userColorMap[getParticipantMetadata(userUuid).userColorLabel]
    }

    const getFullName = (userUuid) => {
      let fullName = getParticipantMetadata(userUuid).fullName
      if (currentAccount.value.activeUserUuid === userUuid) {
        fullName = fullName + ' (me)'
      }
      return fullName
    }

    const getEmail = (userUuid) => {
      return getParticipantMetadata(userUuid).email
    }

    const focusOutWidget = () => {
      // todo: think of a way to do focus out. commented because it interferes with tiny mce that does focusout before saving the widget
      // store.commit(Mutations.SET_TEMPLATE_ACTIVE_WIDGET_INDEX, -1)
    }
    const handlePersistWidget = (payload) => {
      const locatorInstance = buildWidgetLocator(activityTemplate, payload.widget.widgetUuid)
      if (!locatorInstance) return
      const currentWidgetInstance = payload.widget
      const socketPayload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        teamUuid: currentAccount.value.teamUuid,
        locator: locatorInstance,
        widget: currentWidgetInstance
      }
      socket.value.emit('EDIT_WIDGET', {
        eventType: 'EDIT_WIDGET',
        payload: socketPayload
      })
    }
    const currentAccount = computed(() => store.getters.currentActiveUser)

    const solutionHistory = computed(() => {
      return getQuestionShortAnswerSolutionHistory(widget.value).filter(userResource => {
        const participantMetadata = getParticipantMetadata(userResource.userUuid)
        return participantMetadata.userRole !== 2
      })
    })

    const onHotspotExpanded = (widgetUuid) => {
      if (getExpandedHotspotWidgets.value.includes(widgetUuid)) {
        store.commit(Mutations.REMOVE_HOTSPOT_EXPANDED_WIDGETS, widgetUuid)
      } else {
        store.commit(Mutations.ADD_HOTSPOT_EXPANDED_WIDGETS, widgetUuid)
      }
    }

    return {
      solvingMode,
      colorClassMap,
      hotspotPositionClassMap,
      onHotspotExpanded,
      focusWidget,
      focusOutWidget,
      getParticipantMetadata,
      solutionHistory,
      currentAccount,
      widget,
      widgetIndex,
      widgetsMobilePosition,
      mobileViewMode,
      activeSectionIndex,
      handlePersistWidget,
      currentComponent,
      getUserColor,
      getFullName,
      getEmail,
      isResourceLocked,
      getLockedResource,
      onMouseDownHandler,
      onMouseUpHandler,
      borderedStyle

    }
  }
})
