
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { ImageCard } from '@/core/helpers/template/WidgetInterface.ts'
import { buildTemplateFileUri } from '@/core/helpers/activity-template'

export default defineComponent({
  name: 'icon-card',
  props: {
    widget: { type: Object as () => ImageCard, required: true },
    readonly: { type: Boolean, default: false },
    isLandscape: { type: Boolean, default: false }
  },
  setup (props) {
    const store = useStore()
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const currentAccount = computed(() => store.getters.currentActiveUser)

    return {
      activityTemplate,
      currentAccount,
      buildTemplateFileUri
    }
  }
})
