
import { computed, defineComponent, inject, onBeforeUpdate, onMounted, onUnmounted, onUpdated, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { Section } from '@/core/helpers/template/TemplateInterface'
import BuilderWidget from '@/views/session/BuilderWidget.vue'
import VueGridLayout from 'vue3-grid-layout'
// import { Actions } from '@/store/enums/StoreEnums'
// import { useRouter } from 'vue-router'
import UserDebriefing from '@/views/session/UserDebriefing.vue'
import UserDebriefingV2 from '@/views/session/UserDebriefingV2/UserDebriefingV2.vue'
import { buildWidgetLocator } from '@/core/helpers/template/template'
import { Mutations, Actions } from '@/store/enums/StoreEnums'
import { useMobileDetection } from 'vue3-mobile-detection'
import { getParticipantMetadata } from '@/core/helpers/activity-team-module'
import { MenuComponent } from '@/assets/ts/components'
import { ElNotification } from 'element-plus/es'
import { StatisticDataInterface } from '@/store/modules/ActiveUserModule'
import { useI18n } from 'vue-i18n'
import { buildTemplateFileUri } from '@/core/helpers/activity-template'
import BuilderSectionMenu from '@/views/session/BuilderSectionMenu.vue'
import BuilderJitsiMenu from '@/views/session/BuilderJitsiMenu.vue'
import BuilderControlsMenu from '@/views/session/BuilderControlsMenu.vue'
import BuilderLeftMenu from '@/views/session/BuilderLeftMenu.vue'
import { throttle } from 'lodash'

export default defineComponent({
  name: 'builder-workspace',
  emits: ['open-widget-settings', 'widget-delete', 'workspace-scale-changed'],
  props: {
    section: { type: Object as () => Section | unknown }
  },
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    BuilderWidget,
    UserDebriefing,
    UserDebriefingV2,
    BuilderSectionMenu,
    BuilderJitsiMenu,
    BuilderControlsMenu,
    BuilderLeftMenu
  },
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const socket = computed(() => store.getters.getSocket)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const activeSectionWidgets = computed(() => store.getters.getActiveSectionWidgets)
    const activeSectionWidgetsLayout = computed(() => store.getters.getActiveSectionWidgetsLayout)
    const activeSectionIndex = computed(() => store.getters.getActiveSectionIndex)
    const activeSection = computed(() => store.getters.getActiveSection)
    const sections = computed(() => store.getters.getSections)
    const team = computed(() => store.getters.getActivityTeam)

    const mobileViewMode = computed(() => store.getters.getMobileViewMode)
    const connectedUsers = computed(() => store.getters.getConnectedParticipants)
    const currentAccount = computed(() => store.getters.currentActiveUser)
    const mousePositions = computed(() => store.getters.getMousePositions)
    const showCollaboratorsMousePositions = ref(true)
    const emitter = inject('emitter') as any

    emitter.on('show-collaborators-mouse-positions', (value) => {
      showCollaboratorsMousePositions.value = value
    })

    const getFillColorForParticipant = (activeUserUuid) => {
      if (getParticipantMetadata(activeUserUuid).preIntro) {
        return getParticipantMetadata(activeUserUuid).preIntro.color
      }
      return 'none'
    }
    const removableLocks = [] as any

    const resizeWidgetEvent = (i, newH, newW, newHPx, newWPx) => {
      console.log('i', i)
      store.dispatch(Actions.LOCK_RESOURCE, i).then(() => {
        removableLocks.push(i)
        // fail-safe unlock mechanism after couple of seconds to prevent locking of a resource indefinitely
        setTimeout(() => {
          if (removableLocks.includes(i)) {
            store.dispatch(Actions.UNLOCK_RESOURCE, i)
          }
        }, 15000) // 15 seconds timeout
      })
    }
    /**
       * Checks if the unique resource id is locked or not
       * @param resourceUuid The unique resource string
       */
    const isResourceLocked = (resourceUuid): boolean => {
      return computed(() => store.getters.isResourceLocked(resourceUuid)).value
    }
    const sectionUserResources = (sectionUuid) => {
      return computed(() => store.getters.getSectionUserResources(sectionUuid)).value
    }
    const handleSectionClick = (clickedSection) => {
      if ((clickedSection !== -1 && !clickedSection.settings.enabled) || (clickedSection === -1 && !isActivityEnded()) || (clickedSection.settings && clickedSection.settings.locked)) {
        return
      }
      // unlock the other sections
      sections.value.forEach((section) => {
        if (clickedSection.sectionUuid !== section.sectionUuid) {
          store.dispatch(Actions.UNLOCK_RESOURCE, 'section-' + section.sectionUuid + '-user-' + currentAccount.value.activeUserUuid)
        }
      })
      store.dispatch(Actions.UNLOCK_RESOURCE, 'section-' + 'debriefing' + '-user-' + currentAccount.value.activeUserUuid)// this is also unlocked to cope with briefing not being a real section
      if (clickedSection === -1) {
        store.dispatch(Actions.LOCK_RESOURCE, 'section-' + 'debriefing' + '-user-' + currentAccount.value.activeUserUuid)
      } else {
        store.dispatch(Actions.LOCK_RESOURCE, 'section-' + clickedSection.sectionUuid + '-user-' + currentAccount.value.activeUserUuid)
      }
    }
    let workspaceRect = {} as DOMRect
    const gridLayout = ref()
    const builderWorkspace = ref()
    const builderWorkspaceWrapper = ref()
    const zoom = ref(1)
    const scale = ref(1)
    const builderRowHeight = ref()
    const builderColNum = 480
    const builderRowNum = 270
    const builderItemMarginX = 0
    const builderItemMarginY = 0
    const colorClassMap: { [key: string]: string } = {
      transparent: 'widget-transparent',
      white: 'widget-white',
      orange: 'widget-orange',
      blue: 'widget-blue',
      red: 'widget-red',
      green: 'widget-green',
      purple: 'widget-purple',
      gray: 'widget-gray',
      dark: 'widget-dark'
    }

    const teamEnded = computed(() => store.getters.getTeamEnded)
    const isActivityEnded = () => {
      return (teamEnded.value && teamEnded.value.timestamp !== null)
    }

    // store grid items refs: used in builder layout when dragging new widget
    let itemRefs = [] as any
    const setItemRef = el => {
      if (el) {
        itemRefs.push(el)
      }
    }
    onBeforeUpdate(() => {
      itemRefs = []
    })
    let position = { x: 0, y: 0 }

    const onMouseMoveHandler = throttle((event) => {
      const rect = workspaceRect
      const x = (event.clientX - rect.left) / scale.value
      const y = (event.clientY - rect.top) / scale.value
      position = { x: x, y: y }
      const data = { activeUserUuid: currentAccount.value.activeUserUuid, activeSectionIndex: activeSectionIndex.value, position }
      store.dispatch(Actions.MOVE_MOUSE, data)
    }, 100, { trailing: false }) // trailing false discards the other messages to have 1 message every 1/10 of a second

    watch(connectedUsers, (value, oldValue) => {
      const addedUsers = value && oldValue ? value.filter(user => !oldValue.some(oldUser => oldUser.userUuid === user.userUuid)) : []
      const removedUsers = oldValue && value ? oldValue.filter(user => !value.some(newUser => newUser.userUuid === user.userUuid)) : []

      addedUsers.forEach(user => {
        console.log('user: ', user)

        // observer role
        if (user.userRole !== 2) {
          ElNotification({
            customClass: 'teamlearn-notification',
            title: 'New user connected',
            dangerouslyUseHTMLString: true,
            message: `<strong>${typeof user.displayName !== 'undefined' ? user.displayName : user.fullName}</strong> just joined the session`,
            duration: 6000
          })
        }
      })

      removedUsers.forEach(user => {
        // observer role
        if (user.userRole !== 2) {
          ElNotification({
            customClass: 'teamlearn-notification',
            title: 'User disconnected',
            dangerouslyUseHTMLString: true,
            message: `<strong>${typeof user.displayName !== 'undefined' ? user.displayName : user.fullName}</strong> just left the session`,
            duration: 6000
          })
        }
      })
    })

    watch(activeSectionIndex, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        const data = { activeUserUuid: currentAccount.value.activeUserUuid, activeSectionIndex: newVal, position: position }
        store.dispatch(Actions.MOVE_MOUSE, data)
      }
    })

    const widgetMoved = (widgetUuid) => {
      const locatorInstance = buildWidgetLocator(activityTemplate, widgetUuid)
      const widget = computed(() => store.getters.getWidgetByUuid(widgetUuid))
      console.log('team', team.value)
      if (widget) {
        const socketPayload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          teamUuid: team.value.sessionTeamUuid,
          locator: {
            activeSectionIndex: locatorInstance.activeSectionIndex,
            activeWidgetIndex: locatorInstance.activeWidgetIndex
          },
          widgetPosition: widget.value.position
        }

        socket.value.emit('EDIT_LAYOUT_SECTION', {
          eventType: 'EDIT_LAYOUT_SECTION',
          payload: socketPayload
        })
      }
    }

    const widgetResized = (widgetUuid) => {
      const locatorInstance = buildWidgetLocator(activityTemplate, widgetUuid)
      const widget = computed(() => store.getters.getWidgetByUuid(widgetUuid))
      if (widget) {
        const socketPayload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          teamUuid: team.value.sessionTeamUuid,
          locator: {
            activeSectionIndex: locatorInstance.activeSectionIndex,
            activeWidgetIndex: locatorInstance.activeWidgetIndex
          },
          widgetPosition: widget.value.position
        }

        socket.value.emit('EDIT_LAYOUT_SECTION', {
          eventType: 'EDIT_LAYOUT_SECTION',
          payload: socketPayload
        })
      }
      // this is obsolete for now: was calling the resize event to scale the text of the widget, and was conflicting with the general builder scaling event
      // setTimeout(() => {
      //   window.dispatchEvent(new Event('resize'))
      // }, 5)
      store.dispatch(Actions.UNLOCK_RESOURCE, widgetUuid)
    }

    // change row height when resizing window
    const rowHeightListener = () => {
      builderRowHeight.value = (parseInt(window.getComputedStyle(builderWorkspace.value).height) - (builderRowNum * builderItemMarginY)) / builderRowNum
    }
    function extractScale (transformMatrix) {
      const matrixToArray = transformMatrix.split(/\s*[(),]\s*/)
      return parseFloat(matrixToArray[1])
    }

    const isScalingInProgress = ref(false)
    let persistTimeout = 0 as any
    const builderScaleListener = () => {
      isScalingInProgress.value = true
      let resizingCount = 0
      const MAX_TRIES = 500
      const SCALE_STEP = 0.001
      const workspace = builderWorkspaceWrapper.value
      const needsDownscaling = (workspace.scrollHeight > workspace.offsetHeight) || (workspace.scrollWidth > workspace.offsetWidth)
      const needsUpScaling = (workspace.scrollHeight === workspace.offsetHeight) && (workspace.scrollWidth === workspace.offsetWidth)

      if (needsDownscaling) {
        // console.log('Downscaling builder...')
        while (((workspace.scrollHeight > workspace.offsetHeight) || (workspace.scrollWidth > workspace.offsetWidth)) && resizingCount < MAX_TRIES) {
          const scaleFactor = extractScale(window.getComputedStyle(builderWorkspace.value).transform)
          if (!scaleFactor) break
          const newScaleFactor = scaleFactor - SCALE_STEP
          builderWorkspace.value.style.transform = 'translate(-50%, -50%) scale(' + newScaleFactor + ')'
          scale.value = newScaleFactor
          resizingCount++
        }
      }

      if (needsUpScaling) {
        // console.log('Up-scaling builder...')
        while ((workspace.scrollHeight === workspace.offsetHeight) && (workspace.scrollWidth === workspace.offsetWidth) && resizingCount < MAX_TRIES) {
          const scaleFactor = extractScale(window.getComputedStyle(builderWorkspace.value).transform)
          if (!scaleFactor) break
          const newScaleFactor = scaleFactor + SCALE_STEP
          builderWorkspace.value.style.transform = 'translate(-50%, -50%) scale(' + newScaleFactor + ')'
          scale.value = newScaleFactor
          resizingCount++

          if ((workspace.scrollHeight > workspace.offsetHeight) || (workspace.scrollWidth > workspace.offsetWidth)) { // go back one step
            console.log('Up-scaling generated scroll. Downscaling...')
            const scaleFactor = extractScale(window.getComputedStyle(builderWorkspace.value).transform)
            if (!scaleFactor) break
            const newScaleFactor = scaleFactor - SCALE_STEP
            builderWorkspace.value.style.transform = 'translate(-50%, -50%) scale(' + newScaleFactor + ')'
            scale.value = newScaleFactor
            break
          }
        }
      }

      clearTimeout(persistTimeout)
      persistTimeout = setTimeout(() => {
        isScalingInProgress.value = false
      }, 1000)
    }

    function layoutReady () {
      window.dispatchEvent(new Event('resize'))
    }

    const getExpandedHotspotWidgets = computed(() => store.getters.getExpandedHotspotWidgets)

    const hotspotPositionClassMap: { [key: string]: string } = {
      'top-left': 'hotspot-top-left',
      'top-center': 'hotspot-top-center',
      'top-right': 'hotspot-top-right',
      'center-left': 'hotspot-center-left',
      'center-right': 'hotspot-center-right',
      'bottom-left': 'hotspot-bottom-left',
      'bottom-center': 'hotspot-bottom-center',
      'bottom-right': 'hotspot-bottom-right'
    }

    const sectionChange = (sectionIndex, isDebriefing = false, section) => {
      // coach can navigate through every section. exception is debriefing which is available after the activity is ended
      if (currentAccount.value.userRole === 4) {
        if (!isActivityEnded() && sectionIndex === -1) { // but he is not allowed to tap into debriefing until activity is ended
          return
        }
        store.commit(Mutations.SET_TEMPLATE_ACTIVE_SECTION_INDEX, sectionIndex)
        setTimeout(() => { // reinitialize sections options menu
          MenuComponent.reinitialization()
        }, 100)
        return
      }

      //  users can navigate through other sections after the section is enabled
      if ((section && !section.settings.enabled) || (sectionIndex === -1 && !isActivityEnded())) {
        let message

        switch (sectionIndex) {
          case -1:
            message = t('section.results.locked.end.activity')
            break
          default:
            message = t('section.locked.start.activity').replace('%section_placeholder%', section.title)
            break
        }

        ElNotification.warning({
          message: message,
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
        return
      }

      if (section && section.settings.enabled && section.settings.locked) {
        const payload = {} as any
        payload.sectionToUnlock = section
        payload.sectionIndexToUnlock = sectionIndex
        emitter.emit('unlock-section', payload)
        return
      }

      if (sectionIndex !== -1) { // do not care about debriefing section
        const newStatisticData = { type: 'sectionsOpened', meta: { sectionUuid: section.sectionUuid } } as unknown as StatisticDataInterface

        socket.value.emit('ADD_STATISTIC_DATA', {
          eventType: 'ADD_STATISTIC_DATA',
          payload: newStatisticData
        })
      }

      if (isDebriefing) {
        store.commit(Mutations.SET_TEMPLATE_ACTIVE_SECTION_INDEX, -1)
      } else {
        store.commit(Mutations.SET_TEMPLATE_ACTIVE_SECTION_INDEX, sectionIndex)
      }
      setTimeout(() => { // reinitialize sections options menu
        MenuComponent.reinitialization()
      }, 100)
    }

    const scrollInPosition = (event, sectionIndex) => {
      if (event.target.className.match(/collapsed.*/gi) === null && sectionIndex !== 0) {
        setTimeout(() => {
          const scrollToPx = sectionIndex === -1 ? sections.value.length * 75 : 75 * sectionIndex
          builderWorkspace.value.scrollTo({
            top: scrollToPx
          })
        }, 100)
      }
    }

    const isSectionLocked = (sectionIndex, section, isDebriefing = false) => {
      if (currentAccount.value.userRole === 4) {
        if (!isActivityEnded() && sectionIndex === -1) { // but he is not allowed to tap into debriefing until activity is ended
          return true
        }
        return false
      }

      //  users can navigate through other sections after the section is enabled
      if ((section.settings && !section.settings.enabled) || (sectionIndex === -1 && !isActivityEnded())) {
        return true
      }

      if (section && section.settings && section.settings.enabled && section.settings.locked) {
        return true
      }

      return false
    }

    const detectMobile = () => {
      console.log('Detecting mobile...')
      const { isMobile } = useMobileDetection()
      store.commit(Mutations.SET_ACTIVITY_TEMPLATE_MOBILE_VIEW_MODE, isMobile())
      console.log('Setting mobile mode to: ', isMobile())
    }

    const teamStarted = computed(() => store.getters.getTeamStarted)
    watch(teamStarted, (n, o) => {
      if (typeof o === 'object' && 'timestamp' in o && n.timestamp !== null && n.reason !== null && n.timestamp !== o.timestamp) {
        const templateSection = store.getters.getTemplateSectionByIndex(1) as unknown as Section
        const getSectionIsLockedByResource = computed(() => store.getters.getSectionIsLockedByResource(templateSection.sectionUuid)).value
        if (templateSection.settings.locked || getSectionIsLockedByResource) return // if the first section is locked, do not open it
        const sectionButtonId = 'section_button_' + templateSection.sectionUuid
        document.getElementById(sectionButtonId)?.click()
        store.commit(Mutations.SET_TEMPLATE_ACTIVE_SECTION_INDEX, 1) // first section after intro will be selected automatically
        handleSectionClick(templateSection)
        MenuComponent.reinitialization()
        store.dispatch(Actions.SET_START_ACTIVITY_COUNTDOWN, activityTemplate.value.challengeDuration)
      }
    })

    onMounted(() => {
      if (!isActivityEnded()) { // click debriefing when activity is ended
        document.getElementById('section_button_debriefing')?.click() // click debriefing
      }
      builderScaleListener()
      detectMobile()
      // const data = { openedAt: new Date().toISOString() }
      // const payload = { data: data, templateUuid: templateUuid }
      // // update the template with opened at datetime
      // store.dispatch(Actions.API_UPDATE_ACTIVITY_TEMPLATE, payload)

      rowHeightListener()
      window.addEventListener('resize', rowHeightListener)
      window.addEventListener('resize', builderScaleListener)
      window.addEventListener('resize', detectMobile)
    })

    onUpdated(() => {
      workspaceRect = document.getElementById('builder-workspace')!.getBoundingClientRect()
      rowHeightListener()
    })

    onUnmounted(() => {
      window.removeEventListener('resize', rowHeightListener)
      window.removeEventListener('resize', builderScaleListener)
      window.removeEventListener('resize', detectMobile)
    })

    const divHeight = ref(0)
    const sectionBackgroundStyle = computed(() => {
      const style = {} as any

      if (mobileViewMode.value) { // this fix is needed to cope with the ios safari bug on mobile when bars are hidden/shown
        style.height = `${divHeight.value - 100}px`
      }

      if (activeSection.value !== undefined && activeSection.value.backgroundImage !== undefined && activeSection.value.backgroundImage !== null) {
        const backgroundImage = buildTemplateFileUri(activeSection.value.backgroundImage.attachmentUuid, currentAccount.value.employee.company ? currentAccount.value.employee.company : currentAccount.value.company, activeSection.value.backgroundImage)
        style['background-image'] = `url(${backgroundImage})`
        style['background-repeat'] = 'no-repeat'
        style['background-size'] = 'cover'
        style['background-position'] = 'initial'
        style['box-shadow'] = 'unset'
      }

      return style
    })
    const getViewportHeight = () => Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

    const updateDivHeight = () => {
      divHeight.value = getViewportHeight()
    }

    onMounted(() => {
      updateDivHeight()
      window.addEventListener('resize', updateDivHeight)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', updateDivHeight)
    })

    return {
      zoom,
      scale,
      gridLayout,
      builderWorkspace,
      builderWorkspaceWrapper,
      builderColNum,
      builderRowNum,
      builderItemMarginX,
      builderItemMarginY,
      builderRowHeight,
      activeSectionWidgets,
      activeSectionWidgetsLayout,
      colorClassMap,
      showCollaboratorsMousePositions,
      isResourceLocked,
      setItemRef,
      widgetMoved,
      resizeWidgetEvent,
      widgetResized,
      isActivityEnded,
      onMouseMoveHandler,
      getParticipantMetadata,
      getExpandedHotspotWidgets,
      hotspotPositionClassMap,
      mobileViewMode,
      sections,
      sectionChange,
      sectionUserResources,
      isSectionLocked,
      handleSectionClick,
      activeSectionIndex,
      connectedUsers,
      currentAccount,
      mousePositions,
      layoutReady,
      scrollInPosition,
      isScalingInProgress,
      sectionBackgroundStyle,
      activityTemplate,
      getFillColorForParticipant
    }
  }
})
