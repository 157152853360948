
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { getApiKey } from '@/core/helpers/tinymce'
import { DragAndDropGroup } from '@/core/helpers/template/WidgetInterface'
import { buildWidgetLocator } from '@/core/helpers/template/template.ts'
import { uuid } from 'vue-uuid'
import Draggable from 'vuedraggable'
import Editor from '@tinymce/tinymce-vue'
import {
  updateDragAndDropOrderSolutionHistory,
  getDragAndDropOrderSolutionHistory,
  generatedRandomColor,
  getNameCredentials
} from '@/core/helpers/template/widgetSolutionHistory.ts'
import { getParticipantMetadata } from '@/core/helpers/activity-team-module'

export default defineComponent({
  name: 'drag-and-drop-group',
  props: {
    widget: { type: Object as () => DragAndDropGroup, required: true },
    readonly: { type: Boolean, default: false }
  },
  components: {
    editor: Editor,
    draggable: Draggable
  },
  setup (props) {
    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    const solvingMode = computed(() => store.getters.getSolvingMode)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const currentWidget = ref(props.widget as DragAndDropGroup)
    const dragStarted = ref(false)
    const currentAccount = computed(() => store.getters.currentActiveUser)

    // let persistTimeout = 0 as any
    const persistWidget = () => {
      // clearTimeout(persistTimeout)
      // persistTimeout = setTimeout(() => {
      console.log('Persisting widget...')
      const locatorInstance = buildWidgetLocator(activityTemplate, currentWidget.value.widgetUuid)
      const currentWidgetInstance = currentWidget.value
      if (!locatorInstance) return
      const socketPayload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        teamUuid: currentAccount.value.teamUuid,
        locator: locatorInstance,
        widget: currentWidgetInstance
      }
      socket.value.emit('EDIT_WIDGET', {
        eventType: 'EDIT_WIDGET',
        payload: socketPayload
      })
      // }, 1000)
    }

    const onListItemAdded = () => {
      console.log('Adding list item...')
      currentWidget.value.specific.itemList.unshift({
        itemUuid: uuid.v4(),
        content: ''
      })
      persistWidget()
    }

    const onListItemRemoved = (itemUuid) => {
      console.log('Removing item with UUID:', itemUuid)
      // remove from itemList
      const itemKey = currentWidget.value.specific.itemList.findIndex(i => i.itemUuid === itemUuid)
      currentWidget.value.specific.itemList.splice(itemKey, 1)
      // remove from solution in case it exists
      for (const solution of currentWidget.value.specific.solution) {
        for (const solItemUuid of solution.itemList) {
          if (solItemUuid === itemUuid) {
            solution.itemList = solution.itemList.filter(solItemUuid => solItemUuid !== itemUuid)
          }
        }
      }
      persistWidget()
    }

    const onListItemDragged = () => {
      console.log('Reordering available items...')
      dragStarted.value = false
      persistWidget()
    }

    const onItemDraggedToColumn = () => {
      console.log('Dragging item to column...')
      dragStarted.value = false
      updateDragAndDropOrderSolutionHistory(currentWidget, currentAccount)
      persistWidget()
    }

    const onItemClonedToColumn = (itemList) => {
      console.log('Cloning item to column...')
      return {
        solutionItemUuid: uuid.v4(),
        itemUuid: itemList.itemUuid,
        content: itemList.content
      }
    }

    const onSolutionItemRemoved = (columnUuid, solutionItemUuid) => {
      console.log('Removing solution item with solution item uuid ', solutionItemUuid)
      const solution = currentWidget.value.specific.solution
      const solutionColumnKey = solution.findIndex(s => s.columnUuid === columnUuid)
      const solutionItemKey = solution[solutionColumnKey].itemList.findIndex(si => si.solutionItemUuid === solutionItemUuid)
      updateDragAndDropOrderSolutionHistory(currentWidget, currentAccount)
      currentWidget.value.specific.solution[solutionColumnKey].itemList.splice(solutionItemKey, 1)
      persistWidget()
    }

    const getSolutionByColumnUuid = (columnUuid) => {
      const solutionIndex = currentWidget.value.specific.solution.findIndex(s => s.columnUuid === columnUuid)
      return currentWidget.value.specific.solution[solutionIndex].itemList
    }

    const getSolutionIndexByItemUuid = (itemUuid) => {
      return currentWidget.value.specific.solution[0].itemList.findIndex(si => si.itemUuid === itemUuid)
    }

    const getSolutionAvailableItems = () => {
      console.log('Calculating solution available items...')
      const solutionExistingItemsUuids = currentWidget.value.specific.solution[0].itemList
      return currentWidget.value.specific.itemList.filter(
        item => typeof solutionExistingItemsUuids.find(si => si.itemUuid === item.itemUuid) !== 'object'
      )
    }

    const editorSettings = {
      setup: (editor) => {
        editor.on('keydown', function (e) {
          if (e.keyCode === 13) e.preventDefault() // prevent enter
        })
      },
      inline: true,
      menubar: false,
      toolbar: false,
      plugins: ['paste'],
      paste_as_text: true,
      paste_block_drop: true, // prevent pasting when dropping -> part of bug that allowed dragging to other widgets
      invalid_elements: 'br',
      paste_preprocess: function (plugin, args) { // replace multiple spaces and trim on paste
        args.content = args.content.replace(/\s\s+/g, ' ').trim()
      }
    }

    const colorClassMap: { [key: string]: string } = {
      white: 'ghost-widget-white',
      orange: 'ghost-widget-orange',
      blue: 'ghost-widget-blue',
      red: 'ghost-widget-red',
      green: 'ghost-widget-green',
      purple: 'ghost-widget-purple',
      gray: 'ghost-widget-gray'
    }

    return {
      currentWidget,
      solvingMode,
      dragStarted,
      editorSettings,
      currentAccount,
      colorClassMap,
      getApiKey,
      persistWidget,
      onListItemAdded,
      onListItemRemoved,
      onListItemDragged,
      onItemDraggedToColumn,
      onItemClonedToColumn,
      onSolutionItemRemoved,
      getSolutionByColumnUuid,
      getSolutionIndexByItemUuid,
      getSolutionAvailableItems,
      getDragAndDropOrderSolutionHistory,
      generatedRandomColor,
      getNameCredentials,
      getParticipantMetadata
    }
  }
})
