import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3cb13f30"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "widget-body",
  style: {"padding":"0 !important","overflow":"hidden !important"}
}
const _hoisted_2 = {
  class: "widget-body-content",
  style: {"overflow":"hidden !important"}
}
const _hoisted_3 = {
  key: 0,
  class: "icon-container"
}
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.widget.specific.image)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("img", {
              src: _ctx.buildTemplateFileUri(
                    _ctx.widget.specific.image.attachmentUuid,
                     _ctx.currentAccount.employee.company ? _ctx.currentAccount.employee.company : _ctx.currentAccount.company,
                    _ctx.widget.specific.image.original
                    )
            }, null, 8, _hoisted_4)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}