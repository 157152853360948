
import { defineComponent, ref } from 'vue'
import { getApiKey } from '@/core/helpers/tinymce'
import Editor from '@tinymce/tinymce-vue'
import { Heading } from '@/core/helpers/template/WidgetInterface'

export default defineComponent({
  name: 'heading',
  props: {
    widget: { type: Object as () => Heading, required: true },
    readonly: { type: Boolean, default: false }
  },
  components: {
    editor: Editor
  },
  setup (props) {
    const currentWidget = ref(props.widget as Heading) as any

    const editorAdvancedSettings = {
      plugins: ['lists', 'paste'],
      inline: true,
      menubar: false,
      toolbar: false,
      paste_as_text: true,
      paste_block_drop: true, // prevent pasting when dropping -> part of bug that allowed dragging to other widgets
      fontsize_formats: '12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 36pt 48pt 72pt',
      font_formats: 'Alegreya=alegreya,serif; Lato=lato,sans-serif; Merriweather=merriweather,serif; Montserrat=montserrat,serif; Open Sans=open sans,sans-serif; Oswald=oswald,sans-serif; Poppins=poppins,sans-serif; Raleway=raleway,sans-serif; Roboto=roboto,sans-serif;Times New Roman=times new roman,times'
    }

    return {
      currentWidget,
      editorAdvancedSettings,
      getApiKey
    }
  }
})
