import { Widget } from '@/core/helpers/template/TemplateInterface'
import {
  Code,
  DragAndDropGroup,
  DragAndDropOrder,
  FileUpload,
  Flashcard,
  Heading,
  IconCard,
  ImageCard,
  QuestionMultipleChoice,
  QuestionShortAnswer,
  QuestionSingleChoice,
  StyledTextCard,
  VideoEmbedded,
  VideoEmbeddedStyled,
  IframeEmbedded,
  ImageView360,
  QuestionTeamReadiness,
  Shape
} from '@/core/helpers/template/WidgetInterface'
import { uuid } from 'vue-uuid'

const defaultPropertiesMap: { [key: string]: any } = {
  code: {
    category: 'info',
    initWidth: 100,
    initHeight: 70,
    minWidth: 100,
    minHeight: 70,
    maxWidth: 0,
    maxHeight: 0
  },
  'text-card-styled': {
    category: 'info',
    initWidth: 60,
    initHeight: 60,
    minWidth: 10,
    minHeight: 10,
    maxWidth: 0,
    maxHeight: 0
  },
  shape: {
    category: 'info',
    initWidth: 30,
    initHeight: 30,
    minWidth: 10,
    minHeight: 10,
    maxWidth: 0,
    maxHeight: 0
  },
  flashcard: {
    category: 'info',
    initWidth: 100,
    initHeight: 60,
    minWidth: 50,
    minHeight: 30,
    maxWidth: 0,
    maxHeight: 0
  },
  'file-upload': {
    category: 'info',
    initWidth: 100,
    initHeight: 30,
    minWidth: 50,
    minHeight: 30,
    maxWidth: 0,
    maxHeight: 0
  },
  heading: {
    category: 'info',
    initWidth: 60,
    initHeight: 20,
    minWidth: 30,
    minHeight: 20,
    maxWidth: 0,
    maxHeight: 0
  },
  'video-embedded': {
    category: 'info',
    initWidth: 250,
    initHeight: 180,
    minWidth: 125,
    minHeight: 90,
    maxWidth: 0,
    maxHeight: 0
  },
  'iframe-embedded': {
    category: 'info',
    initWidth: 250,
    initHeight: 180,
    minWidth: 125,
    minHeight: 90,
    maxWidth: 0,
    maxHeight: 0
  },
  'video-embedded-styled': {
    category: 'info',
    initWidth: 250,
    initHeight: 180,
    minWidth: 125,
    minHeight: 90,
    maxWidth: 0,
    maxHeight: 0
  },
  'image-card': {
    category: 'info',
    initWidth: 120,
    initHeight: 100,
    minWidth: 15,
    minHeight: 10,
    maxWidth: 0,
    maxHeight: 0
  },
  'question-short-answer': {
    category: 'action',
    initWidth: 100,
    initHeight: 30,
    minWidth: 50,
    minHeight: 30,
    maxWidth: 0,
    maxHeight: 0
  },
  'question-short-answer-ai-scored': {
    category: 'action',
    initWidth: 100,
    initHeight: 45,
    minWidth: 50,
    minHeight: 30,
    maxWidth: 0,
    maxHeight: 0
  },
  'question-single-choice': {
    category: 'action',
    initWidth: 100,
    initHeight: 60,
    minWidth: 50,
    minHeight: 30,
    maxWidth: 0,
    maxHeight: 0
  },
  'question-team-readiness': {
    category: 'action',
    initWidth: 100,
    initHeight: 60,
    minWidth: 50,
    minHeight: 30,
    maxWidth: 0,
    maxHeight: 0
  },
  'question-multiple-choice': {
    category: 'action',
    initWidth: 100,
    initHeight: 60,
    minWidth: 50,
    minHeight: 30,
    maxWidth: 0,
    maxHeight: 0
  },
  'drag-and-drop-group': {
    category: 'action',
    initWidth: 250,
    initHeight: 180,
    minWidth: 125,
    minHeight: 90,
    maxWidth: 0,
    maxHeight: 0
  },
  'drag-and-drop-order': {
    category: 'action',
    initWidth: 250,
    initHeight: 180,
    minWidth: 125,
    minHeight: 90,
    maxWidth: 0,
    maxHeight: 0
  },
  'icon-card': {
    category: 'info',
    initWidth: 20,
    initHeight: 20,
    minWidth: 5,
    minHeight: 5,
    maxWidth: 0,
    maxHeight: 0
  },
  'image-view-360': {
    category: 'info',
    initWidth: 250,
    initHeight: 180,
    minWidth: 125,
    minHeight: 90,
    maxWidth: 0,
    maxHeight: 0
  }
}

export const generateWidget = (widgetType, x, y): Widget | null => {
  switch (widgetType) {
    case 'code': {
      return generateCode(widgetType, x, y)
    }
    case 'text-card-styled': {
      return generateStyledTextCard(widgetType, x, y)
    }
    case 'shape-circle': {
      return generateShapeCircle(widgetType, x, y)
    }
    case 'shape-triangle': {
      return generateShapeTriangle(widgetType, x, y)
    }
    case 'file-upload': {
      return generateFileUpload(widgetType, x, y)
    }
    case 'flashcard': {
      return generateFlashcard(widgetType, x, y)
    }
    case 'heading': {
      return generateHeading(widgetType, x, y)
    }
    case 'video-embedded': {
      return generateVideoEmbedded(widgetType, x, y)
    }
    case 'video-embedded-styled': {
      return generateVideoEmbeddedStyled(widgetType, x, y)
    }
    case 'image-card': {
      return generateImageCard(widgetType, x, y)
    }
    case 'question-short-answer': {
      return generateQuestionShortAnswer(widgetType, x, y)
    }
    case 'question-single-choice': {
      return generateQuestionSingleChoice(widgetType, x, y)
    }
    case 'question-team-readiness': {
      return generateQuestionTeamReadiness(widgetType, x, y)
    }
    case 'question-multiple-choice': {
      return generateQuestionMultipleChoice(widgetType, x, y)
    }
    case 'drag-and-drop-group': {
      return generateDragAndDropGroup(widgetType, x, y)
    }
    case 'drag-and-drop-order': {
      return generateDragAndDropOrder(widgetType, x, y)
    }
    case 'icon-card': {
      return generateIconCard(widgetType, x, y)
    }
    case 'question-short-answer-ai-scored': {
      return generateQuestionShortAnswer(widgetType, x, y)
    }
    case 'iframe-embedded': {
      return generateIframeEmbedded(widgetType, x, y)
    }
    case 'image-view-360': {
      return generateImageView360(widgetType, x, y)
    }
    default: {
      return null
    }
  }
}

const getCommonProperties = (widgetType, x, y): Widget => {
  const defaults = defaultPropertiesMap[widgetType]
  const widgetUuid = uuid.v4()
  return {
    widgetUuid: widgetUuid,
    type: widgetType,
    category: defaults.category,
    discovery: false,
    rounded: false,
    border: false,
    specific: {},
    minWidth: defaults.minWidth,
    minHeight: defaults.minHeight,
    maxWidth: defaults.maxWidth,
    maxHeight: defaults.maxHeight,
    mobileVisible: true,
    position: {
      i: widgetUuid,
      x: x,
      y: y,
      w: defaults.initWidth,
      h: defaults.initHeight
    }
  }
}

const generateCode = (widgetType, x, y): Code => {
  return {
    ...getCommonProperties(widgetType, x, y),
    specific: {
      code: 'console.log(\'Add code\')',
      language: 'javascript',
      color: 'dark',
      hotspot: false,
      hotspotPosition: null
    }
  }
}

const generateStyledTextCard = (widgetType, x, y): StyledTextCard => {
  return {
    ...getCommonProperties(widgetType, x, y),
    specific: {
      content: '',
      color: 'grey-light',
      hotspot: false,
      hotspotPosition: null
    }
  }
}

const generateShapeCircle = (widgetType, x, y): Shape => {
  return {
    ...getCommonProperties('shape', x, y),
    specific: {
      color: 'grey-light',
      type: 'circle'
    }
  }
}

const generateShapeTriangle = (widgetType, x, y): Shape => {
  return {
    ...getCommonProperties('shape', x, y),
    specific: {
      color: 'grey-light',
      type: 'triangle'
    }
  }
}

const generateFileUpload = (widgetType, x, y): FileUpload => {
  return {
    ...getCommonProperties(widgetType, x, y),
    specific: {
      color: 'grey-light',
      files: [],
      hotspot: false,
      hotspotPosition: null
    }
  }
}

const generateFlashcard = (widgetType, x, y): Flashcard => {
  return {
    ...getCommonProperties(widgetType, x, y),
    specific: {
      icon: 'la-bullhorn',
      title: '',
      content: '',
      color: 'yellow-light',
      hotspot: false,
      hotspotPosition: null
    }
  }
}

const generateHeading = (widgetType, x, y): Heading => {
  return {
    ...getCommonProperties(widgetType, x, y),
    specific: {
      content: '',
      color: 'grey-light'
    }
  }
}

const generateVideoEmbedded = (widgetType, x, y): VideoEmbedded => {
  return {
    ...getCommonProperties(widgetType, x, y),
    specific: {
      url: '',
      color: 'dark',
      hotspot: false,
      hotspotPosition: null
    }
  }
}

const generateIframeEmbedded = (widgetType, x, y): IframeEmbedded => {
  return {
    ...getCommonProperties(widgetType, x, y),
    specific: {
      url: '',
      color: 'dark',
      hotspot: false,
      hotspotPosition: null
    }
  }
}

const generateVideoEmbeddedStyled = (widgetType, x, y): VideoEmbeddedStyled => {
  return {
    ...getCommonProperties(widgetType, x, y),
    specific: {
      title: '',
      description: '',
      url: ''
    }
  }
}

const generateIconCard = (widgetType, x, y): IconCard => {
  return {
    ...getCommonProperties(widgetType, x, y),
    specific: {
      image: null
    }
  }
}

const generateImageCard = (widgetType, x, y): ImageCard => {
  return {
    ...getCommonProperties(widgetType, x, y),
    specific: {
      image: null,
      color: 'dark',
      hotspot: false,
      hotspotPosition: ''
    }
  }
}

const generateImageView360 = (widgetType, x, y): ImageView360 => {
  return {
    ...getCommonProperties(widgetType, x, y),
    specific: {
      scenes: [],
      color: 'white',
      hotspot: false,
      hotspotPosition: ''
    }
  }
}

const generateQuestionShortAnswer = (widgetType, x, y): QuestionShortAnswer => {
  return {
    ...getCommonProperties(widgetType, x, y),
    specific: {
      question: '',
      color: 'white',
      solution: {
        answer: ''
      },
      explanation: '',
      hotspot: false,
      hotspotPosition: null
    }
  }
}

const generateQuestionSingleChoice = (widgetType, x, y): QuestionSingleChoice => {
  return {
    ...getCommonProperties(widgetType, x, y),
    specific: {
      question: '',
      color: 'white',
      answersList: [
        {
          answerUuid: uuid.v4(),
          answer: ''
        },
        {
          answerUuid: uuid.v4(),
          answer: ''
        }
      ],
      solution: null,
      explanation: '',
      hotspot: false,
      hotspotPosition: null
    }
  }
}

const generateQuestionTeamReadiness = (widgetType, x, y): QuestionTeamReadiness => {
  return {
    ...getCommonProperties(widgetType, x, y),
    specific: {
      question: '',
      color: 'dark',
      answersList: [
        {
          answerUuid: uuid.v4(),
          answer: ''
        },
        {
          answerUuid: uuid.v4(),
          answer: ''
        }
      ],
      solution: null,
      solutionTries: 0,
      solved: false,
      explanation: '',
      hotspot: false,
      hotspotPosition: null
    }
  }
}

const generateQuestionMultipleChoice = (widgetType, x, y): QuestionMultipleChoice => {
  return {
    ...getCommonProperties(widgetType, x, y),
    specific: {
      question: '',
      color: 'white',
      answersList: [
        {
          answerUuid: uuid.v4(),
          answer: ''
        },
        {
          answerUuid: uuid.v4(),
          answer: ''
        }
      ],
      solution: [],
      explanation: '',
      hotspot: false,
      hotspotPosition: null
    }
  }
}

const generateDragAndDropGroup = (widgetType, x, y): DragAndDropGroup => {
  const columnUuid = uuid.v4()
  const columnUuid2 = uuid.v4()
  return {
    ...getCommonProperties(widgetType, x, y),
    specific: {
      title: '',
      description: '',
      color: 'white',
      itemListTitle: '',
      itemList: [{
        itemUuid: uuid.v4(),
        content: ''
      },
      {
        itemUuid: uuid.v4(),
        content: ''
      },
      {
        itemUuid: uuid.v4(),
        content: ''
      },
      {
        itemUuid: uuid.v4(),
        content: ''
      },
      {
        itemUuid: uuid.v4(),
        content: ''
      },
      {
        itemUuid: uuid.v4(),
        content: ''
      }],
      columnList: [{
        columnUuid: columnUuid,
        title: ''
      },
      {
        columnUuid: columnUuid2,
        title: ''
      }],
      solution: [{
        columnUuid: columnUuid,
        itemList: []
      },
      {
        columnUuid: columnUuid2,
        itemList: []
      }],
      explanation: ''
    }
  }
}

const generateDragAndDropOrder = (widgetType, x, y): DragAndDropOrder => {
  const columnUuid = uuid.v4()
  return {
    ...getCommonProperties(widgetType, x, y),
    specific: {
      title: '',
      description: '',
      color: 'white',
      itemListTitle: '',
      itemList: [{
        itemUuid: uuid.v4(),
        content: ''
      },
      {
        itemUuid: uuid.v4(),
        content: ''
      },
      {
        itemUuid: uuid.v4(),
        content: ''
      },
      {
        itemUuid: uuid.v4(),
        content: ''
      },
      {
        itemUuid: uuid.v4(),
        content: ''
      },
      {
        itemUuid: uuid.v4(),
        content: ''
      }],
      columnList: [{
        columnUuid: columnUuid,
        title: ''
      }],
      solution: [{
        columnUuid: columnUuid,
        itemList: []
      }],
      explanation: ''
    }
  }
}

export const hotspotPositionClassMap: { [key: string]: string } = {
  'top-left': 'hotspot-top-left',
  'top-center': 'hotspot-top-center',
  'top-right': 'hotspot-top-right',
  'center-left': 'hotspot-center-left',
  'center-right': 'hotspot-center-right',
  'bottom-left': 'hotspot-bottom-left',
  'bottom-center': 'hotspot-bottom-center',
  'bottom-right': 'hotspot-bottom-right'
}

export const colorClassMap: { [key: string]: string } = {
  'green-light': 'widget-green-light',
  'yellow-light': 'widget-yellow-light',
  'red-light': 'widget-red-light',
  'purple-light': 'widget-purple-light',
  'blue-light': 'widget-blue-light',
  green: 'widget-green',
  yellow: 'widget-yellow',
  red: 'widget-red',
  purple: 'widget-purple',
  blue: 'widget-blue',
  'turquoise-dark': 'widget-turquoise-dark',
  orange: 'widget-orange',
  'red-dark': 'widget-red-dark',
  'purple-dark': 'widget-purple-dark',
  'blue-dark': 'widget-blue-dark',
  'grey-light': 'widget-grey-light',
  'gray-medium': 'widget-gray-medium',
  gray: 'widget-gray',
  'gray-dark': 'widget-gray-dark',
  'navy-blue': 'widget-navy-blue',
  dark: 'widget-dark',
  white: 'widget-white',
  transparent: 'widget-transparent'
}

export const colorMap: { [key: string]: string } = {
  'green-light': '#BFEDD2',
  'yellow-light': '#FBEEB8',
  'red-light': '#F8CAC6',
  'purple-light': '#ECCAFA',
  'blue-light': '#C2E0F4',
  green: '#2DC26B',
  yellow: '#F1C40F',
  red: '#E03E2D',
  purple: '#B96AD9',
  blue: '#3598DB',
  'turquoise-dark': '#169179',
  orange: '#E67E23',
  'red-dark': '#BA372A',
  'purple-dark': '#843FA1',
  'blue-dark': '#236FA1',
  'grey-light': '#ECF0F1',
  'gray-medium': '#CED4D9',
  gray: '#95A5A6',
  'gray-dark': '#7E8C8D',
  'navy-blue': '#34495E',
  dark: '#1A1C30',
  white: '#FFFFFF',
  transparent: 'transparent'
}

export const sectionColorClassMap: { [key: string]: string } = {
  // 'green-light': 'section-green-light',
  // 'yellow-light': 'section-yellow-light',
  // 'red-light': 'section-red-light',
  // 'purple-light': 'section-purple-light',
  // 'blue-light': 'section-blue-light',
  green: 'section-green',
  yellow: 'section-yellow',
  red: 'section-red',
  purple: 'section-purple',
  blue: 'section-blue',
  'turquoise-dark': 'section-turquoise-dark',
  orange: 'section-orange',
  'red-dark': 'section-red-dark',
  'purple-dark': 'section-purple-dark',
  'blue-dark': 'section-blue-dark',
  // 'grey-light': 'section-grey-light',
  'gray-medium': 'section-gray-medium',
  gray: 'section-gray',
  'gray-dark': 'section-gray-dark',
  'navy-blue': 'section-navy-blue',
  dark: 'section-dark',
  Default: 'section-white'
  // transparent: 'section-transparent'
}

export const sectionColorMap: { [key: string]: string } = {
  // 'green-light': '#BFEDD2',
  // 'yellow-light': '#FBEEB8',
  // 'red-light': '#F8CAC6',
  // 'purple-light': '#ECCAFA',
  // 'blue-light': '#C2E0F4',
  green: '#2DC26B',
  yellow: '#F1C40F',
  red: '#E03E2D',
  purple: '#B96AD9',
  blue: '#3598DB',
  'turquoise-dark': '#169179',
  orange: '#E67E23',
  'red-dark': '#BA372A',
  'purple-dark': '#843FA1',
  'blue-dark': '#236FA1',
  // 'grey-light': '#ECF0F1',
  'gray-medium': '#CED4D9',
  gray: '#95A5A6',
  'gray-dark': '#7E8C8D',
  'navy-blue': '#34495E',
  dark: '#1A1C30',
  white: '#FFFFFF'
  // transparent: 'transparent'
}

export const computeWidgetColorsByType = (currentWidget) => {
  if (!currentWidget.value) return []

  const builderColors = [] as any

  if (currentWidget.value.type !== 'code' && currentWidget.value.type !== 'drag-and-drop-group' && currentWidget.value.type !== 'drag-and-drop-order' && currentWidget.value.type !== 'question-team-readiness') {
    builderColors.push('green-light', 'yellow-light', 'red-light', 'purple-light', 'blue-light')
  }

  if (currentWidget.value.type === 'text-card-styled' ||
        currentWidget.value.type === 'shape' ||
        currentWidget.value.type === 'heading' ||
        currentWidget.value.type === 'video-embedded' ||
        currentWidget.value.type === 'image-card'
  ) {
    builderColors.push('green', 'yellow', 'red', 'purple', 'blue', 'turquoise-dark', 'orange', 'red-dark', 'purple-dark', 'blue-dark')
  }

  if (currentWidget.value.type !== 'code' && currentWidget.value.type !== 'drag-and-drop-group' && currentWidget.value.type !== 'drag-and-drop-order' && currentWidget.value.type !== 'question-team-readiness') {
    builderColors.push('grey-light')
  }

  if (currentWidget.value.type === 'text-card-styled' ||
        currentWidget.value.type === 'shape' ||
        currentWidget.value.type === 'heading' ||
        currentWidget.value.type === 'video-embedded' ||
        currentWidget.value.type === 'image-card'
  ) {
    builderColors.push('gray-medium', 'gray', 'gray-dark', 'navy-blue')
  }

  if (currentWidget.value.type === 'code' ||
        currentWidget.value.type === 'heading' ||
        currentWidget.value.type === 'text-card-styled' ||
        currentWidget.value.type === 'shape' ||
        currentWidget.value.type === 'video-embedded' ||
        currentWidget.value.type === 'image-card' ||
        currentWidget.value.type === 'question-team-readiness'
  ) {
    builderColors.push('dark')
  }

  if (currentWidget.value.type !== 'question-team-readiness' && currentWidget.value.type !== 'shape') {
    builderColors.push('white', 'transparent')
  } else {
    builderColors.push('white')
  }

  return builderColors
}
