import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5116cb45"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bubble-wrapper" }
const _hoisted_2 = { class: "d-flex flex-column align-items-center" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_tooltip, {
      effect: "light",
      placement: "bottom",
      "open-delay": 200
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, [
            _renderSlot(_ctx.$slots, "name", {}, undefined, true)
          ]),
          _createElementVNode("span", null, [
            _renderSlot(_ctx.$slots, "nickname", {}, undefined, true)
          ]),
          _createElementVNode("span", null, [
            _renderSlot(_ctx.$slots, "email", {}, undefined, true)
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("span", {
          class: "menu-tooltip-content bubble",
          style: _normalizeStyle({ backgroundColor: $props.color })
        }, [
          ($props.hasAvatar)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                style: {"height":"17px","border-radius":"50%"},
                src: $props.image,
                alt: ""
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true)
        ], 4)
      ]),
      _: 3
    })
  ]))
}