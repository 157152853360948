
import { computed, inject, onMounted, reactive, ref, watch } from 'vue'
import LanguageSelector from '@/views/session/LanguageSelector.vue'
import store from '@/store'
import { Actions, Mutations } from '@/store/enums/StoreEnums'

export default {
  name: 'builder-controls-menu',
  props: {
    template: { type: Object, required: true }
  },
  components: {
    LanguageSelector
  },
  setup (props) {
    const showCollaboratorsMousePositions = ref(true)
    const emitter = inject('emitter') as any
    const currentAccount = computed(() => store.getters.currentActiveUser)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const isActivityStarted = () => {
      return (teamStarted.value && teamStarted.value.timestamp !== null)
    }
    const teamEnded = computed(() => store.getters.getTeamEnded)
    const isActivityEnded = () => {
      return (teamEnded.value && teamEnded.value.timestamp !== null)
    }
    const teamStarted = computed(() => store.getters.getTeamStarted)
    const onStartActivityClicked = () => {
      emitter.emit('confirm-start-modal', true)
    }
    const onEndActivityClicked = () => {
      emitter.emit('confirm-end-modal', true)
    }
    const onExitActivityClicked = () => {
      emitter.emit('exit-activity', true)
    }
    const onShowCollaboratorsMousePositionsClicked = () => {
      showCollaboratorsMousePositions.value = !showCollaboratorsMousePositions.value
      emitter.emit('show-collaborators-mouse-positions', showCollaboratorsMousePositions.value)
    }
    const startCountdown = ref(0) as any
    const startActivityCountdown = computed(() => store.getters.getStartActivityCountdown)

    let countDown = 0
    watch(startActivityCountdown, (n, o) => {
      if (o !== null) {
        clearTimeout(countDown)
      }
      const parsedN = parseInt(n)
      if (!isNaN(parsedN) && parsedN >= 0) {
        startCountdown.value = parsedN
        if (startCountdown.value <= 0) {
          startCountdown.value = 0
          // Handle the countdown reaching 0 here
          return
        }
        countDownTimer(parsedN)
      } else {
        console.log('parsedN', parsedN)
        // Handle invalid or NaN input (e.g., show an error message)
      }
    })
    const countDownTimer = (value) => {
      if (startCountdown.value <= 0) {
        // Handle the countdown reaching 0 here (e.g., show a message)
        return
      }
      countDown = setTimeout(() => {
        startCountdown.value = parseInt(startCountdown.value) - 1
        countDownTimer(startCountdown.value)
      }, 1000)
    }
    const convertHMS = (value) => {
      const sec = parseInt(value, 10) // convert value to number if it's string
      let hours = Math.floor(sec / 3600) as any // get hours
      let minutes = Math.floor((sec - (hours * 3600)) / 60) as any// get minutes
      let seconds = sec - (hours * 3600) - (minutes * 60) as any //  get seconds
      // add 0 if value < 10; Example: 2 => 02
      if (hours < 10) { hours = '0' + hours }
      if (minutes < 10) { minutes = '0' + minutes }
      if (seconds < 10) { seconds = '0' + seconds }
      return minutes + ':' + seconds // Return is HH : MM : SS
    }
    const activeSectionIndex = computed(() => store.getters.getActiveSectionIndex)
    const sections = computed(() => store.getters.getSections)

    const isPreviousSectionDisabled = computed(() => {
      if (activeSectionIndex.value > 0 || activeSectionIndex.value === -1) {
        return false
      }
      return true
    })

    const isNextSectionDisabled = computed(() => {
      const availableSections = store.getters.getSections
      let areThereAnySectionsAvailable = false
      availableSections.forEach((section, index) => {
        if (index !== 0) {
          if (section.settings) {
            if (section.settings.enabled && !section.settings.locked) {
              areThereAnySectionsAvailable = true
            }
          }
        }
      })
      let areThereAnyUnsolvedWidgets = false
      if (activeSectionIndex.value !== -1) {
        sections.value[activeSectionIndex.value].widgets.forEach((widget) => {
          if (widget.type === 'question-team-readiness' && widget.specific.solved === false) {
            areThereAnyUnsolvedWidgets = true
          }

          if (widget.type === 'drag-and-drop-group') {
            widget.specific.solution.forEach((solution) => {
              // console.log('solution', solution)
              if (solution.itemList.length === 0) {
                // console.log('at least one is empty')
                areThereAnyUnsolvedWidgets = true
              }
            })
          }

          if (widget.type === 'image-view-360' && widget.specific.scenes.length > 0) {
            widget.specific.scenes.forEach((scene) => {
              if (scene.hotspots.length > 0) {
                scene.hotspots.forEach((hotspot) => {
                  if (hotspot.modalWidget && hotspot.modalWidget.type === 'question-team-readiness' && hotspot.modalWidget.specific.solved === false) {
                    areThereAnyUnsolvedWidgets = true
                  }
                })
              }
            })
          }
        })
      }
      const nextSections = reactive([]) as any
      // Check settings of the next indexes
      // Check settings of the next indexes until the end of the array
      for (let i = activeSectionIndex.value + 1; i < availableSections.length; i++) {
        const section = availableSections[i]
        nextSections.push(section)
      }

      let areThereAnyNextSectionsAvailable = false

      nextSections.forEach((nextSection, index) => {
        if (nextSection.settings) {
          if (nextSection.settings.enabled && !nextSection.settings.locked) {
            areThereAnyNextSectionsAvailable = true
            areThereAnySectionsAvailable = true
          }
        }
      })

      if (!areThereAnyNextSectionsAvailable) {
        areThereAnySectionsAvailable = false
      }
      if (areThereAnyUnsolvedWidgets) {
        areThereAnySectionsAvailable = false
        nextSections.forEach((nextSection) => {
          // nextSection.settings.enabled = false
        })
      }

      if (activeSectionIndex.value === -1) {
        return true
      }

      if (isActivityEnded() && activeSectionIndex.value === sections.value.length - 1) {
        areThereAnySectionsAvailable = true
      }

      return !areThereAnySectionsAvailable
    })
    const nextSection = computed(() => {
      return sections.value[activeSectionIndex.value + 1]
    }) as any

    onMounted(() => {
      // nextSection.value = sections.value[activeSectionIndex.value + 1]
    })
    const nextSectionClick = function () {
      let sectionToUnlock = reactive([]) as any
      let sectionToUnlockIndex = ref() as any
      sections.value.forEach((section) => {
        if (section.settings) {
          if (section.settings.enabled && !section.settings.locked) {
            // areThereAnySectionsAvailable = true
          }
        }
      })

      // const nextSection = sections.value[activeSectionIndex.value + 1]

      if (nextSection.value && nextSection.value.settings && !nextSection.value.settings.enabled && nextSection.value.settings.locked === true) {
        sectionToUnlockIndex = activeSectionIndex.value + 1
        sectionToUnlock = nextSection.value
      }
      if (typeof sectionToUnlockIndex === 'number' && sectionToUnlock) {
        const payload = {} as any
        payload.sectionToUnlock = sectionToUnlock
        payload.sectionIndexToUnlock = sectionToUnlockIndex
        emitter.emit('unlock-section', payload)
        return
      }
      if (activeSectionIndex.value !== -1 && activeSectionIndex.value < sections.value.length) {
        if (isNextSectionDisabled.value) {
          return
        }
        if (activeSectionIndex.value + 1 === sections.value.length) {
          store.commit(Mutations.SET_TEMPLATE_ACTIVE_SECTION_INDEX, -1)
        } else {
          store.commit(Mutations.SET_TEMPLATE_ACTIVE_SECTION_INDEX, activeSectionIndex.value + 1)
        }
      }
      if (activeSectionIndex.value === sections.value.length) {
        sections.value.forEach((section) => {
          // if (nextSection.value.sectionUuid !== section.sectionUuid) {
          store.dispatch(Actions.UNLOCK_RESOURCE, 'section-' + section.sectionUuid + '-user-' + currentAccount.value.activeUserUuid)
          // }
        })
        store.dispatch(Actions.UNLOCK_RESOURCE, 'section-' + 'debriefing' + '-user-' + currentAccount.value.activeUserUuid)// this is also unlocked to cope with briefing not being a real section
        store.dispatch(Actions.LOCK_RESOURCE, 'section-' + 'debriefing' + '-user-' + currentAccount.value.activeUserUuid)

        if (isNextSectionDisabled.value) {
          return
        }
        store.commit(Mutations.SET_TEMPLATE_ACTIVE_SECTION_INDEX, -1)
      }
      sections.value.forEach((section) => {
        store.dispatch(Actions.UNLOCK_RESOURCE, 'section-' + section.sectionUuid + '-user-' + currentAccount.value.activeUserUuid)
      })
      if (activeSectionIndex.value !== -1) {
        const currentSection = sections.value[activeSectionIndex.value]
        // unlock the other sections
        store.dispatch(Actions.LOCK_RESOURCE, 'section-' + currentSection.sectionUuid + '-user-' + currentAccount.value.activeUserUuid)
      } else {
        store.dispatch(Actions.UNLOCK_RESOURCE, 'section-' + 'debriefing' + '-user-' + currentAccount.value.activeUserUuid)// this is also unlocked to cope with briefing not being a real section
        store.dispatch(Actions.LOCK_RESOURCE, 'section-' + 'debriefing' + '-user-' + currentAccount.value.activeUserUuid)
      }
    }

    const previousSectionClick = () => {
      if (activeSectionIndex.value !== 0 && activeSectionIndex.value > 0) {
        store.commit(Mutations.SET_TEMPLATE_ACTIVE_SECTION_INDEX, activeSectionIndex.value - 1)
      }
      if (activeSectionIndex.value === -1) {
        store.commit(Mutations.SET_TEMPLATE_ACTIVE_SECTION_INDEX, sections.value.length - 1)
      }
      const currentSection = sections.value[activeSectionIndex.value]
      // unlock the other sections
      sections.value.forEach((section) => {
        // if (nextSection.value.sectionUuid !== section.sectionUuid) {
        store.dispatch(Actions.UNLOCK_RESOURCE, 'section-' + section.sectionUuid + '-user-' + currentAccount.value.activeUserUuid)
        // }
      })
      store.dispatch(Actions.UNLOCK_RESOURCE, 'section-' + 'debriefing' + '-user-' + currentAccount.value.activeUserUuid)// this is also unlocked to cope with briefing not being a real section
      if (currentSection.value === -1) {
        store.dispatch(Actions.LOCK_RESOURCE, 'section-' + 'debriefing' + '-user-' + currentAccount.value.activeUserUuid)
      } else {
        store.dispatch(Actions.LOCK_RESOURCE, 'section-' + currentSection.sectionUuid + '-user-' + currentAccount.value.activeUserUuid)
      }
    }

    const areAllParticipantsConnected = computed(() => store.getters.areAllParticipantsConnected)
    const hasUnsolvedActionWidgets = ref(false)
    watch(() => activityTemplate, (currentValue, oldValue) => {
      if (currentValue.value !== 'undefined') {
        hasUnsolvedActionWidgets.value = false
        currentValue.value.templateJson.sections.every((section) => {
          return section.widgets.every((widget) => {
            if (widget.category === 'action') {
              switch (widget.type) {
                case 'drag-and-drop-group':
                case 'drag-and-drop-order':
                  return widget.specific.solution.every((solution) => {
                    if (solution.itemList.length === 0) {
                      hasUnsolvedActionWidgets.value = true
                      return false
                    }
                    return true
                  })
                case 'question-single-choice':
                  if ((typeof widget.specific.solution === 'string' && widget.specific.solution.length === 0) || widget.specific.solution === null) {
                    hasUnsolvedActionWidgets.value = true
                    return false
                  }
                  return true
                case 'question-team-readiness':
                  if ((typeof widget.specific.solution === 'string' && widget.specific.solution.length === 0) || widget.specific.solution === null) {
                    hasUnsolvedActionWidgets.value = true
                    return false
                  }
                  return true
                case 'question-multiple-choice':
                  if (widget.specific.solution.length === 0) {
                    hasUnsolvedActionWidgets.value = true
                    return false
                  }
                  return true
                case 'question-short-answer':
                  if ((typeof widget.specific.solution.answer === 'string' && widget.specific.solution.answer.length === 0) || widget.specific.solution.answer === null) {
                    hasUnsolvedActionWidgets.value = true
                    return false
                  }
                  return true
                default:
                  return true
              }
            }
            return true
          })
        })
      }
    },
    { deep: true }
    )

    const cameraVisible = ref(true)
    const handleCamera = () => {
      cameraVisible.value = !cameraVisible.value
      emitter.emit('handle-camera', cameraVisible.value)
    }

    const audioEnabled = ref(true)
    const handleAudio = () => {
      audioEnabled.value = !audioEnabled.value
      emitter.emit('handle-audio', audioEnabled.value)
    }

    return {
      showCollaboratorsMousePositions,
      isActivityStarted,
      onStartActivityClicked,
      areAllParticipantsConnected,
      currentAccount,
      isActivityEnded,
      hasUnsolvedActionWidgets,
      onEndActivityClicked,
      onExitActivityClicked,
      handleCamera,
      handleAudio,
      cameraVisible,
      audioEnabled,
      onShowCollaboratorsMousePositionsClicked,
      isPreviousSectionDisabled,
      isNextSectionDisabled,
      nextSectionClick,
      previousSectionClick,
      nextSection,
      convertHMS,
      startCountdown
    }
  }
}
