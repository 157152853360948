
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { getApiKey } from '@/core/helpers/tinymce'
import { ReflectionPostIt } from '@/core/helpers/template/WidgetInterface'
// import { buildWidgetLocator } from '@/core/helpers/template/template.ts'
import Editor from '@tinymce/tinymce-vue'
// import { getTinymce } from '@tinymce/tinymce-vue/lib/es2015/main/ts/TinyMCE'

import { useStore } from 'vuex'
import { buildWidgetLocator } from '@/core/helpers/template/template'
import {
  updateReflectionPostItHistory
} from '@/core/helpers/template/widgetSolutionHistory.ts'
import vClickOutside from 'click-outside-vue3'

export default defineComponent({
  name: 'reflection-post-it',
  emits: ['open-widget-settings', 'persist-widget'],
  props: {
    widget: { type: Object as () => ReflectionPostIt, required: true },
    readonly: { type: Boolean, default: false }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    editor: Editor
  },
  setup (props) {
    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const solvingMode = computed(() => store.getters.getSolvingMode)
    const widgetFound = computed(() => store.getters.getWidgetByUuid(props.widget.widgetUuid))
    const currentAccount = computed(() => store.getters.currentActiveUser)
    const editorRef = ref(null) as any
    const isDisabled = ref(true)

    // const currentWidget = ref(props.widget as ReflectionPostIt) as any
    const dynamicTextValue = computed(() => props.widget.specific.content)
    const textValue = ref('')
    watch(dynamicTextValue, () => {
      console.log('needs to be updated', dynamicTextValue.value)
      // textValue.value = dynamicTextValue.value
    })
    onMounted(() => {
      textValue.value = dynamicTextValue.value
    })
    let persistTimeout = 0 as any
    const persistWidget = () => {
      if (props.readonly) {
        return
      }
      clearTimeout(persistTimeout)
      persistTimeout = setTimeout(() => {
        console.log('Persisting widget...')
        const currentWidgetInstance = widgetFound.value

        const locatorInstance = buildWidgetLocator(activityTemplate, currentWidgetInstance.widgetUuid)
        currentWidgetInstance.specific.content = textValue.value
        updateReflectionPostItHistory(widgetFound, currentAccount, textValue.value)
        const socketPayload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          widget: currentWidgetInstance,
          teamUuid: currentAccount.value.teamUuid,
          locator: locatorInstance
        }
        if (!locatorInstance) return
        socket.value.emit('EDIT_WIDGET', {
          eventType: 'EDIT_WIDGET',
          payload: socketPayload
        })
      }, 0)
    }

    socket.value.on('WIDGET_EDITED', (eventData) => {
      if (eventData.content.widget.widgetUuid !== props.widget.widgetUuid) {
        return
      }
      if (eventData.sender && eventData.sender.active_user_uuid === currentAccount.value.activeUserUuid) {
        return
      }
      textValue.value = eventData.content.widget.specific.content
    })
    const editorSettings = {
      setup: (editor) => {
        editor.ui.registry.addContextToolbar('textselection', {
          predicate: () => !editor.selection.isCollapsed(),
          items: 'alignleft aligncenter alignright alignjustify forecolor backcolor numlist bullist link hr | bold italic underline strikethrough fontselect fontsizeselect outdent indent',
          position: 'selection',
          scope: 'node'
        })
        editor.on('init', function () {
          // move cursor to end of text
          editor.selection.select(editor.getBody(), true)
          editor.selection.collapse(false)
        })
        editor.on('NodeChange', () => { // fixes an issue with editor not focusing when it becomes enabled
          if (!editor.getContainer().classList.contains('mce-disabled')) {
            editor.focus()
          }
        })
        editor.on('Focus', () => { // move cursor at the end when focusing
          editor.selection.select(editor.getBody(), true)
          editor.selection.collapse(false)
        })
        editor.on('FormatApply FormatRemove', function () { // change also for deleting all content
          persistWidget()
        })
        editor.on('ExecCommand', function (e) {
          const eventsToPersist = ['InsertOrderedList', 'InsertUnorderedList', 'indent', 'outdent']
          if (eventsToPersist.includes(e.command)) {
            persistWidget()
          }
        })
        editor.on('keydown', function (e) { // indent/outdent using tab and shift keys
          if (e.key === 'Enter' || e.key === 'Backspace' || e.key === 'Delete') {
            persistWidget()
          }
          if ((e.altKey) && // Check if combination key
                  (e.code === 'KeyS')) {
            console.log('preventing default')
            e.preventDefault()
            return
          }
          if (e.keyCode === 9) {
            e.preventDefault()
            if (e.shiftKey) {
              editor.execCommand('outdent')
              persistWidget()
            } else {
              editor.execCommand('indent')
              persistWidget()
            }
          }
        })
      },
      inline: true,
      menubar: false,
      toolbar: false,
      plugins: ['paste'],
      paste_as_text: true,
      paste_block_drop: true, // prevent pasting when dropping -> part of bug that allowed dragging to other widgets
      invalid_elements: 'br',
      paste_preprocess: function (plugin, args) { // replace multiple spaces and trim on paste
        args.content = args.content.replace(/\s\s+/g, ' ').trim()
      }
    }
    const vcoConfig = {
      handler: () => {
        isDisabled.value = true
      },
      middleware: (e) => {
        return (e.target && typeof e.target.className === 'string' && !e.target.className.match(/tox/))
      },
      events: ['dblclick', 'click'],
      isActive: true,
      detectIFrame: true,
      capture: false
    }
    return {
      widgetFound,
      editorSettings,
      solvingMode,
      getApiKey,
      persistWidget,
      isDisabled,
      vcoConfig,
      textValue,
      editorRef
    }
  }
})
